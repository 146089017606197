import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import { Select, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem, Grid } from "@material-ui/core";
import Actualizar from "../Prompts/Actualizar";
import ConfirmationDialog from "../Prompts/Estado";
import DraggableDialog from "../Prompts/Confirmar";
import FormDialog from "../Prompts/Comentario";
import { makeStyles } from "@material-ui/core";
import "./Estilos.css";
import Subir from "../Prompts/Subir";
import TransitionsModal from "../VisualizadorPdf/ModalVisualizador";
import { GetRegistro } from "../../Registro_Data/metodos";
import { Iconos, DescargarArchivo } from "../Papeleria/Metodos";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GetApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    backgroundColor: "#2577DF",
    //"rgba(0,0,0,0.8)"
    fontWeight: "800 !important",
    fontFamily: "Poppins !important",
    color: "white !important",
  },
  cellProps: {
    fontFamily: "Poppins !important",
  },
}));

const GridTable = (props) => {
  const {
    SetDataSetUsuario,
    FotoPerfil,
    Nombre,
    DPI,
    bandera,
    status,
    datos,
    PapeleriaTipos,
    INFORNATEDATA,
    DataPapeleria,
    TiposPapeleria,
    ID_Usuario,
    Email,
    Estados,
    Commentarios,
    Tipos,
    Porcentaje,
    Reclutador,
    INFORNET,
    MUESTRAS,
    HUELLAS,
    CalcularPorcentajeAprobado,
    DatosPrueba,
    setEstados,
    setComentarios,
    SetDataPapeleria,
    Registros,
    setRegistros,
    RegistroCarga,
    Mensajes,
    SetMensajes,
    BuscarIdentificador,
    ID_Candidato
  } = props;
  const [responsive, setResponsive] = useState("scrollMaxHeight");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [Data, setData] = useState([]);
  const mediaQuery = window.matchMedia("(max-width: 700px)");
  const mediaQuery2 = window.matchMedia("(min-width: 701px)");
  const classes = useStyles();

  const columns = [
    {
      name: "Documento",
      label: "Documento",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => {
          return { style: { fontFamily: "Poppins" } };
        },
        setCellHeaderProps: () => {
          return { className: classes.centeredHeader };
        },
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        setCellProps: (value) => {
          return value === "Aceptado" ||
            value === "No Aplica" ||
            value === "NoAplica"
            ? //INICIO IF ACEPTADO
              {
                style: {
                  textDecorationColor: "#2DAA18",
                  textDecoration: "underline",
                  color: "#2DAA18",
                  fontFamily: "Poppins",
                },
              }
            : //FIN IF ACEPTADO
            value === "Subido"
            ? {
                style: {
                  textDecorationColor: "rgb(261, 198, 0)",
                  textDecoration: "underline",
                  color: "rgb(261, 198, 0)",
                  fontFamily: "Poppins",
                },
              }
            : value === "Pendiente"
            ? {
                style: {
                  textDecorationColor: "#A9A9A9",
                  textDecoration: "underline",
                  color: "#A9A9A9",
                  fontFamily: "Poppins",
                },
              }
            : {
                style: {
                  textDecorationColor: "#E86E71",
                  textDecoration: "underline",
                  color: "#E86E71",
                  fontFamily: "Poppins",
                },
              };
        },
        setCellHeaderProps: () => {
          return { className: classes.centeredHeader };
        },
      },
    },
    {
      name: "Cargar",
      label: "Cargar",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
    {
      name: "Descargar",
      label: "Descargar",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
    {
      name: "Visualizar",
      label: "Visualizar",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
    {
      name: "Comentario",
      label: "Comentario",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
    {
      name: "Estado",
      label: "Estado",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
    {
      name: "Actualizar",
      label: "Actualizar",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
    {
      name: "RegistroRevision",
      label: "Registro Revisión",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => {
          return { style: { fontFamily: "Poppins" } };
        },
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
    {
      name: "RegistroCargar",
      label: "Registro Carga Archivo",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => {
          return { style: { fontFamily: "Poppins" } };
        },
        setCellHeaderProps: () => ({ className: classes.centeredHeader }),
      },
    },
  ];
  const options = {
    filter: true,
    filterType: "multiselect",
    responsive: responsive,
    fixedHeader: true,
    tableBodyHeight,
    tableBodyMaxHeight,
    print: false,
    selectableRows: false,
    //filterType: 'multiselect',
    download: false,
    rowsPerPage: 25,
    setRowProps: (data, dataIndex, rowIndex) => {
      if (dataIndex % 2 === 0) {
        return {
          style: {
            background: "rgba(1, 77, 102, 0.1)",
            //"#EAEDED"
          },
        };
      }
    },
    onRowClick: (rowData) => SetTipo(rowData[0]),
    textLabels: {
      pagination: {
        next: "Siguiente página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de", // 1-10 of 30
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tablas",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Limpiar",
      },
      viewColumns: {
        title: "Mostrar columnas",
      },
      selectedRows: {
        text: "rows(s) deleted",
        delete: "Delete",
      },
      body: {
        noMatch: "No hay candidatos",
      },
    },

    setFilterChipProps: (colIndex, colName, data) => {
      //console.log(colIndex, colName, data);
      return {
        variant: "outlined",
        className: "testClass123",
      };
    },
  };

  const SetTipo = (tipo) => {
    localStorage.setItem(
      `tipo`,
      JSON.stringify({
        tipo: tipo,
      })
    );
  };

  useEffect(() => {
    let myArray = [];
    if (datos !== undefined) {
      if (datos.length > 0) {
        for (let i = 0; i < datos.length; i++) {
          let tmp = {
            Documento: datos[i].Documento,
            Status: datos[i].Status,
            Cargar: (
              <Subir
                tipo={datos[i].Documento}
                DataPapeleria={DataPapeleria}
                ID_Usuario={ID_Usuario}
                TiposPapeleria={TiposPapeleria}
                SetDataPapeleria={SetDataPapeleria}
                Documento={DPI}
                datos={datos}
                SetDataSetUsuario={SetDataSetUsuario}
                setEstados={setEstados}
                Estados={Estados}
                Registros={Registros}
                setRegistros={setRegistros}
                CalcularPorcentajeAprobado={CalcularPorcentajeAprobado}
              />
            ),
            Descargar: (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">Descargar Archivo</Tooltip>
                }
              >
                <span className="d-inline-block">
                  <Button
                    onClick={() =>
                      DescargarArchivo(datos[i].Documento, DataPapeleria)
                    }
                  >
                    <GetApp style={{ color: "black" }} />
                  </Button>
                </span>
              </OverlayTrigger>
            ),
            Visualizar: (
              <TransitionsModal
                DataPapeleria={DataPapeleria}
                tipo={datos[i].Documento}
                status={datos[i].Status}
              />
            ),
            Comentario: (
              <FormDialog
                tipo={datos[i].Documento}
                Commentarios={Commentarios}
                setComentarios={setComentarios}
                Mensajes={Mensajes}
                SetMensajes={SetMensajes}
              />
            ),
            Estado: (
              <ConfirmationDialog
                tipo={datos[i].Documento}
                //Estados={Estados}
                datos={datos}
                SetDataSetUsuario={SetDataSetUsuario}
                setEstados={setEstados}
              />
            ),
            Actualizar: (
              <Actualizar
                tipo={datos[i].Documento}
                SetDataSetUsuario={SetDataSetUsuario}
                datos={datos}
                DataPapeleria={DataPapeleria}
                TiposPapeleria={TiposPapeleria}
                ID_Usuario={ID_Usuario}
                Email={Email}
                Estados={Estados}
                Commentarios={Commentarios}
                Porcentaje={Porcentaje}
                Reclutador={Reclutador}
                CalcularPorcentajeAprobado={CalcularPorcentajeAprobado}
                DatosPrueba={DatosPrueba}
                setEstados={setEstados}
                setComentarios={setComentarios}
                Registros={Registros}
                setRegistros={setRegistros}
                Mensajes={Mensajes}
                SetMensajes={SetMensajes}
                BuscarIdentificador={BuscarIdentificador}
                DPI={DPI}
              />
            ),
            RegistroRevision: GetRegistro(Registros, datos[i].Documento),
            RegistroCargar: GetRegistro(RegistroCarga, datos[i].Documento),
          };
          myArray.push(tmp);
        }
      }

      setData(myArray);
      if (mediaQuery.matches) {
        setResponsive("standard");
      }

      if (mediaQuery2.matches) {
        setResponsive("scrollMaxHeight");
      }
    }
  }, [datos]);

  return (
    <div>
      <hr />
      {bandera ? (
        <Grid
          xl={10}
          xs={10}
          sm={10}
          lg={10}
          md={10}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <React.Fragment>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  fontWeigth: "800",
                  color: "black",
                  fontFamily: "Poppins",
                }}
              >
                Opción Responsiva
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={responsive}
                style={{
                  width: "200px",
                  marginBottom: "10px",
                  marginRight: 10,
                  fontWeight: "800",
                  fontFamily: "Poppins",
                }}
                onChange={(e) => setResponsive(e.target.value)}
              >
                <MenuItem value={"vertical"}>vertical</MenuItem>
                <MenuItem value={"standard"}>standard</MenuItem>
                <MenuItem value={"simple"}>simple</MenuItem>

                <MenuItem value={"scroll"}>scroll (deprecated)</MenuItem>
                <MenuItem value={"scrollMaxHeight"}>
                  scrollMaxHeight (deprecated)
                </MenuItem>
                <MenuItem value={"stacked"}>stacked (deprecated)</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  fontWeigth: "700",
                  color: "black",
                  fontFamily: "Poppins",
                }}
              >
                Tamaño Tabla
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tableBodyHeight}
                style={{
                  width: "200px",
                  marginBottom: "10px",
                  marginRight: 10,
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
                onChange={(e) => setTableBodyHeight(e.target.value)}
              >
                <MenuItem value={""}>[blank]</MenuItem>
                <MenuItem value={"400px"}>400px</MenuItem>
                <MenuItem value={"800px"}>800px</MenuItem>
                <MenuItem value={"100%"}>100%</MenuItem>
              </Select>
            </FormControl>
            <MUIDataTable
              title={`Papelería ${Nombre} - Porcentaje Completado: ${Porcentaje} %`}
              data={Data}
              columns={columns}
              options={options}
            />
            <DraggableDialog
              DataPapeleria={DataPapeleria}
              TiposPapeleria={TiposPapeleria}
              ID_Usuario={ID_Usuario}
              Email={Email}
              Estados={Estados}
              Commentarios={Commentarios}
              Porcentaje={Porcentaje}
              Reclutador={Reclutador}
              INFORNET={INFORNET}
              PapeleriaTipos={PapeleriaTipos}
              INFORNATEDATA={INFORNATEDATA}
              DPI={DPI}
              FotoPerfil={FotoPerfil}
              Nombre={Nombre}
              HUELLAS={HUELLAS}
              MUESTRAS={MUESTRAS}
              setEstados={setEstados}
              setComentarios={setComentarios}
              Mensajes={Mensajes}
              datos={datos}
              ID_Candidato={ID_Candidato}
            />
          </React.Fragment>
        </Grid>
      ) : (
        <div className="contenedor">
          <div className="hijo">
            <p>{status}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GridTable;
