import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid'
import Button from "@material-ui/core/Button";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Fragment } from "react";
const tutorialSteps = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    preguntas: "HOLA"
  },
  {
    label: "PARAGUAY",
    preguntas: "PARAGUAY"
  },
  {
    label: "MEXICO",
    preguntas: "HOLA MEXICO"
  },
  {
    label: "GUATEMALA",
    preguntas: "HOLA GUATEMALA"
  },  {
    label: "GUATEMALA",
    preguntas: "HOLA GUATEMALA"
  },  {
    label: "GUATEMALA",
    preguntas: "HOLA GUATEMALA"
  },  {
    label: "GUATEMALA",
    preguntas: "HOLA GUATEMALA"
  },  {
    label: "GUATEMALA",
    preguntas: "HOLA GUATEMALA"
  },  {
    label: "GUATEMALA",
    preguntas: "HOLA GUATEMALA"
  },  {
    label: "GUATEMALA",
    preguntas: "HOLA GUATEMALA"
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%"
  }
}));

export default function TextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;
  const fractionPorcentaje =  maxSteps ;
  console.log(tutorialSteps)
  console.log(maxSteps)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>prevActiveStep - 1);
  };
  console.log(tutorialSteps[activeStep].label)
  return (
      <Fragment>
            <div 
              className = { classes.root } 
            >
              <Paper
                square
                elevation = { 0 }
                className = { classes.header } 
              >
                <Typography 
                  variant = 'subtitle2'>
                  {
                    tutorialSteps[activeStep].label
                  }
                </Typography>
              </Paper>
                <Typography >
                  {
                    tutorialSteps[activeStep].preguntas
                  }
               </Typography>

               <MobileStepper
                 steps = { maxSteps }
                 position = "static"
                 variant = "progress"
                 activeStep = { activeStep }
                 nextButton = {
                  <div>
                   <Button
                     size="small"
                     onClick={handleNext}
                     disabled={activeStep === maxSteps - 1}
                   >
                     {theme.direction === "rtl" ? (
                       <KeyboardArrowUpIcon />
                     ) : (
                       <KeyboardArrowDownIcon />
                     )}
                   </Button>
                  </div>
                 }
                 backButton={
                    <Button 
                      size = "small"
                      onClick = { handleBack }
                      disabled = { activeStep === 0 } 
                    >
                     {
                        theme.direction === "rtl" ? 
                      (
                        <KeyboardArrowDownIcon />
                      ) : 
                      (
                        <KeyboardArrowUpIcon />
                      )
                     }
                   </Button>
                 }
               />
             </div>
      </Fragment>    
  );
}
