import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Button as Buttons } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PatchPapeleria, requestCandidato,PatchPapeleria_CANDIDATO } from "../../../helpers/candidato.data";
import postPapeleriaCandidato from "../../../helpers/Papeleria";
import { Paper, Snackbar, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import {
  GenerarCaratula,
  OrdenarPrioridadArchivos,
  ListaArchivos
} from "./GenerarArchivoFinal";
import { jsPDF } from "jspdf";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import "./Estilo.css";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#2577DF",
    color: "white",    
    fontWeight: "500",
    marginRight: "auto",
    marginTop: "20px",
    width: "100%",
    fontFamily: "Roboto", fontWeigth: "800 !important" 
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const FinalizaProceso = (props) => {
  const {
    Nombre,
    FotoPerfil,
    DPI,
    data,
    ID_Usuario,
    TiposPapeleria,
    INFORNET,
    PapeleriaTipos,
    INFORNATEDATA,
    HUELLAS,
    MUESTRAS,
    ID_Candidato
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [banderaFile, SetbanderaFile] = React.useState(false);
  const [banderaFileFalse, SetbanderaFileFalse] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [banderaCondicion, setbanderaCondicion] = useState(false);
  const search = useLocation().search;
  const ID = new URLSearchParams(search).get("id");

  const Message = (val) => {
    if (val === "Si") {
      PostArchivos();
    } else {
      SetbanderaFileFalse(true);
      handleClose();
    }
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      // timer.current = window.setTimeout(() => {
      //   setSuccess(true);
      //   setLoading(false);
      // }, 2000);
    }
  };

  const SetTrueBanderas = () => {
    setSuccess(true);
    setLoading(false);
  };

  const Final = async() => {
    setOpen(true);
    let tmp = null;
    let fotoPerfil = FotoPerfil;
    
    if (fotoPerfil !== undefined) {
      if (!FotoPerfil[0].filename.includes("pdf")) {
        axios({
          url: FotoPerfil[0].url,
          method: "GET",
          responseType: "blob",
        }).then(async (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          tmp = pdfconvert(url, "Foto Perfil", DPI);
          let m = postPapeleriaCandidato(tmp, "Foto Perfil", DPI);
          console.log(m);
          m.then((val) => Message(val));
        });
      } else {
        await PostArchivos();
      }
    } else {
      await PostArchivos();
    }
  };

  const PostArchivos = async() => {
    
    let res = GenerarCaratula(
      TiposPapeleria,
      data,
      INFORNET,
      PapeleriaTipos,
      INFORNATEDATA,
      FotoPerfil,
      HUELLAS,
      MUESTRAS
    );
    
    let url = [{ url: res }];
    let urlFoto = JSON.parse(localStorage.getItem("urlFoto Perfil"));
    let t;
    if (urlFoto !== null) {
      localStorage.removeItem("urlFoto Perfil");
      let url2 = [{ url: urlFoto.url }];
      t = { CARATULA_CANDIDATO: url, "FOTO PERFIL": url2 };      
    } else {
      t = { CARATULA_CANDIDATO: url };
    }

    let tmp2 = [{ id: ID_Candidato[0], fields: t }];
    let tmp3 = { records: tmp2 };
    let tmp4 = JSON.stringify(tmp3);  
    console.log(tmp4);

    PatchPapeleria_CANDIDATO.patch(tmp4)
      .then(async(response) =>  {
        if (response.status === 200) {          
            await getURL();                 
        } else {
          SetbanderaFileFalse(true);
          handleClose();
        }
      })
      .catch((err) => {
        console.log('aqui truena');
        console.error(err);
        SetbanderaFileFalse(true);
        handleClose();
      });
  };


  const getURL = async() => {    
    requestCandidato
      .get(DPI)
      .then(async(response) => {
        let obj = response.data.records[0].fields; 
        let ruta2 = "FOTO PERFIL";
        let PhotoPerfil = obj[ruta2];
        let urlFile = obj.CARATULA_CANDIDATO[0].url;
        let foto = "";
        if (PhotoPerfil !== undefined) {
          foto = PhotoPerfil[0].url;
        }
       console.log(foto);
       console.log(urlFile);
       await MergeDocuments(urlFile, foto);
      })
      .catch((err) => {
        console.error(err);
        SetbanderaFileFalse(true);
        handleClose();
      });
    handleClose();
  };

  const MergeDocuments = async(urlFile, foto) => {
    //OrdenarPrioridadArchivos(data,urlFile,TiposPapeleria,foto)
    // let res = getData(
    //   OrdenarPrioridadArchivos(
    //     data,
    //     urlFile,
    //     TiposPapeleria,
    //     foto,
    //     INFORNATEDATA,
    //     INFORNET,
    //     HUELLAS,
    //     MUESTRAS
    //   )
    // );
    let res = getData(ListaArchivos(data));
    console.log(res);
    let url = [{ url: res }];
    let t = { PAPELERIA_FINAL: url };
    let Tmp2 = [{ id: ID_Usuario, fields: t }];
    let tmp3 = { records: Tmp2 };
    let tmp4 = JSON.stringify(tmp3);
    console.log(tmp4);
    PatchPapeleria.patch(tmp4)
      .then((response) => {
        if (response.status === 200) {
          SetbanderaFile(true);
          DescargarFinal(res, `Papeleria - ${Nombre} - ${DPI}.pdf`);
          handleClose();
        }
      })
      .catch((err) => {
        console.error(err);
        SetbanderaFileFalse(true);
        handleClose();
      });
  };

  const getData = (datos) => {
    let query = "";
    for (let i = 0; i < datos.length; i++) {
      if (i + 1 < datos.length) {
        query = query + "data=" + datos[i] + "&";
      } else {
        query = query + "data=" + datos[i];
      }
    }

    return (
      "https://us-central1-empleosmrb.cloudfunctions.net/api_PdfMerge/pdf-merge.pdf?" +
      query
    );
  };

  const pdfconvert = (data) => {
    let doc = new jsPDF();
    doc.setFontSize(40);
    doc.addImage(data, 15, 40, 180, 180);
    let out = doc.output();
    let url = "data:application/pdf;base64," + btoa(out);
    let file = dataURLtoFile(url, `FotoPerfil${DPI}`);
    return file;
  };
  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const DescargarFinal = (urlfile, urlName) => {
    //DelayModal()
    setbanderaCondicion(true);
    handleButtonClick();
    axios({
      url: urlfile,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", urlName);
      document.body.appendChild(link);
      SetTrueBanderas();
      link.click();
      if (ID !== null) {
     window.location.href = `/RevisionPapeleria?id=${ID}`;
      } else {
     window.location.href = "/RevisionPapeleria";
      }
    });
  };

  const handleClose1 = () => {
    setOpen(false);
    SetbanderaFile(false);
  };

  const handleClosedd = () => {
    setOpen(false);
    SetbanderaFileFalse(false);
  };

  return (
    <div>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
      </style>

      <Snackbar
        open={banderaFile}
        autoHideDuration={2000}
        onClose={handleClose1}
      >
        <Alert letiant="filled" severity="success" onClose={handleClose1}>
          Se ha generado y almacenado el archivo final exitosamente.
        </Alert>
      </Snackbar>

      <Snackbar
        open={banderaFileFalse}
        autoHideDuration={2000}
        onClose={handleClosedd}
      >
        <Alert letiant="filled" severity="error" onClose={handleClosedd}>
          Error al almacenar el archivo final, pruebe nuevamente.
        </Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress />
      </Backdrop>

      <Buttons
        letiant="contained"
        className={classes.button}
        //onClick={() => Final()}
        onClick={() => MergeDocuments()}
      >
        {"Finalizar proceso" + " "}
        
        <AssignmentIcon />
      </Buttons>

      <Backdrop className={classes.backdrop} open={banderaCondicion}>
        <div className={classes.wrapper}>
          <Fab aria-label="save" color="primary" className={buttonClassname}>
            {success ? <CheckIcon /> : <SaveIcon />}
          </Fab>
          {loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>

        <div className={classes.wrapper}>
          <Button
            letiant="contained"
            className={buttonClassname}
            disabled={loading}
          >
            Descargando Archivo
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Backdrop>
    </div>
  );
};

export default FinalizaProceso;