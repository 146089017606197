var Tipos = []
var Final = []
var ArchivosFinales = []
export function OrdenarPrioridadArchivos(data,urlFile,TiposPapeleria,FotoPerfil,A,INFORNET,HUELLAS,MUESTRAS)
{
    Final.push(urlFile) 
    if(FotoPerfil.length > 0)   {
        Final.push(FotoPerfil) 
    }
    for(let i = 0;i<data.length;i++)
    {
        for(let j = 0;j<data.length;j++)
        {
            let tmp = data[j].filename
            if(!ArchivosFinales.includes(data[j].url))
            {
                let bandera = ValidarExistencia(tmp,TiposPapeleria)
                if(bandera)
                {         
                    ArchivosFinales.push(data[j].url)
                }
            }
        }
    }  
    OrdenarArchivos(ArchivosFinales)

    if(INFORNET !== undefined)
    {
    getUrlInfornet(INFORNET)
    }
    
    if(MUESTRAS !== undefined){
        getUrlMuestras(MUESTRAS)
    }

    if(HUELLAS !== undefined){
        getUrlHuellas(HUELLAS)
    }
    console.log(Final);
    return Final;
} 

export const ListaArchivos = (data) => {    

    let newList = data.sort((a, b) => {return a.filename - b.filename;});
    let newList2 = [];
    Object.keys(newList).forEach(index => {
        newList2.push(newList[index]["url"]);
    });

    return newList2;
}

const OrdenarArchivos = (Files) => {
    var TiposPermitidos = 
    ["CURRICULUM VITAE", "CURRICULUM","COPIA DPI","DPI","NIT","RECIBO DE LUZ","PARTIDA DE NACIMIENTO","ANTECEDENTES PENALES COPIA","ANTECEDENTES PENALES ORIGINALES"
    ,"ANTECEDENTES POLICIACOS ORIGINALES","ANTECEDENTES POLICIADOS COPIA","IGSS","ULTIMO TITULO OBTENIDO","TITULO","CONSTANCIA DE CURSOS APROBADOS"
    ,"CARTAS LABORALES I","CARTAS LABORALES II","CARTAS LABORALES III","CARTAS PERSONALES I","CARTAS PERSONALES II","CARTAS PERSONALES III","TARJETA DE PULMONES","TARJETA DE SALUD"
    ]

    for(let i = 0; i < TiposPermitidos.length;i++){
        
        let tmp = TiposPermitidos[i].split(" ")
 

            for(let j = 0; j < Files.length;j++)
            {
                let value = ""
                let contador = 0; 
                
                for(let d = 0; d < tmp.length;d++)
                {
                    if(Files[j].includes(tmp[d])){
                    contador = contador + 1;
                    }   
                }

                if(contador === tmp.length){
                value = Files[j]
                if(value.length > 0){
                    if(!Final.includes(value)){                
                    Final.push(value)}}
                break;
                }
            }
    }

}


const getUrlInfornet = (INFORNET) =>{
   
    for(let i = 0; i < INFORNET.length;i++){        
        let tmpurl = INFORNET[i].url;
        if(tmpurl != null)
        {
        if(tmpurl.toLowerCase().includes("infornet"))
        {
          Final.push(tmpurl)
        }
    }
    }    
}

const getUrlHuellas = (HUELLAS) =>{

    for(let i = 0; i < HUELLAS.length;i++){        
        let tmpurl = HUELLAS[i].url;
        if(tmpurl !== null)
        {        
          Final.push(tmpurl)        
        }
    }  
}

const getUrlMuestras = (MUESTRAS) =>{
    for(let i = 0; i < MUESTRAS.length;i++){        
        let tmpurl = MUESTRAS[i].url;
        if(tmpurl !== MUESTRAS)
        {        
          Final.push(tmpurl)        
        }
    } 
}


const ValidarExistencia = (filename,Tipos) => {
    var bandera = false;
    const TiposPermitidos = 
    ["CURRICULUM VITAE", "CURRICULUM","COPIA DPI","DPI","NIT","RECIBO DE LUZ","PARTIDA DE NACIMIENTO","ANTECEDENTES PENALES COPIA","ANTECEDENTES PENALES ORIGINALES"
    ,"ANTECEDENTES POLICIACOS ORIGINALES","ANTECEDENTES POLICIADOS COPIA","IGSS","ULTIMO TITULO OBTENIDO","TITULO","CONSTANCIA DE CURSOS APROBADOS"
    ,"CARTAS LABORALES I","CARTAS LABORALES II","CARTAS LABORALES III","CARTAS PERSONALES I","CARTAS PERSONALES II","CARTAS PERSONALES III","TARJETA DE PULMONES","TARJETA DE SALUD"
    ] 
    
     for(let i = 0;i< TiposPermitidos.length;i++){
        let contador = 0;                
        let tmp = TiposPermitidos[i].split(" ")
         for(let j = 0; j < tmp.length;j++){
            if(filename.includes(tmp[j])){
                contador = contador + 1;
            }
         }
         if(contador === tmp.length){
                bandera = true
            break;
        }
     }

     
    return bandera;    
}

const MesActual = (mes) => {
    var resmes = ""
    switch(mes){
        case 0: 
        resmes = "Enero" 
        break
        case 1: 
        resmes = "Febrero" 
        break
        case 2: 
        resmes = "Marzo" 
        break
        case 3: 
        resmes = "Abril" 
        break
        case 4: 
        resmes = "Mayo" 
        break
        case 5: 
        resmes = "Junio" 
        break
        case 6: 
        resmes = "Julio" 
        break
        case 7: 
        resmes = "Agosto" 
        break
        case 8: 
        resmes = "Septiembre" 
        break
        case 9: 
        resmes = "Octubre" 
        break
        case 10: 
        resmes = "Noviembre" 
        break
        case 11: 
        resmes = "Diciembre" 
        break
        default:
        resmes = "Enero"
    }
    return resmes;
}

const ArchivoFinalCaratula = (checklists,INFORNATEDATA)  => {

    let fecha = new Date();
    let nombre = INFORNATEDATA.Nombre
    let reclutador = INFORNATEDATA.Reclutador
    var FechaHoy = MesActual(fecha.getMonth()) + " " + fecha.getFullYear(); 
    let query1 = "nombre=" + nombre + "&fecha="+FechaHoy + "&reclutador=" + reclutador+"&checklist=";
    let query2 = "";
    for(let i =0;i < checklists.length;i++)
    {
      if( (i+1) < checklists.length)
      {
      query2 += checklists[i]+"&checklist="
      }
      else
      {
        query2 += checklists[i]
      }
    }   
    //console.log("https://us-central1-empleosmrb.cloudfunctions.net/api_PdfCreate/pdf-merge?" + query1 + query2);
    return "https://us-central1-empleosmrb.cloudfunctions.net/api_PdfCreate/pdf-merge?" + query1 + query2;
  }

export function GenerarCaratula(DataPapeleria,data,INFORNET,PapeleriaTipos,INFORNATEDATA,FotoPerfil,HUELLAS,MUESTRAS){
    validarFotoPerfil(FotoPerfil)
    validarInfornet(INFORNET)
    validarHuellas(HUELLAS)
    validarMuestras(MUESTRAS)
    
    for(let i =0;i< PapeleriaTipos.length;i++)
    {
    AlmacenarArray(DataPapeleria[i],PapeleriaTipos[i],data)
    }

    let urlres = ArchivoFinalCaratula(Tipos,INFORNATEDATA)
    return urlres;
}

const validarHuellas = (huellas) =>{
    if(huellas !== undefined){
        Tipos.push("Ficha de toma de huellas dactilares 1")
        Tipos.push("Ficha de toma de huellas dactilares 3")
    }
}

const validarMuestras = (Muestras) =>{
    if(Muestras !== undefined){
        Tipos.push("Ficha de Muestra Grafotécnica 1")
        Tipos.push("Ficha de Muestra Grafotécnica 3")
    }
}

const validarFotoPerfil = (FotoPerfil) =>{
    if(FotoPerfil != undefined)
    {
    var urlfile = FotoPerfil[0].url
    if(urlfile != null)
    {
        Tipos.push("Fotografia tamaño cédula 1")
        Tipos.push("Fotografia tamaño cédula 3") 
    }
    }
}

const validarInfornet = (INFORNET) =>{
    if(INFORNET !== undefined)
    {
    for(let i = 0; i < INFORNET.length;i++){        
        let tmpurl = INFORNET[i].url;
        if(tmpurl != null)
        {
        if(tmpurl.toLowerCase().includes("infornet"))
        {
            if(!Tipos.includes("Infornet 1") || !Tipos.includes("Infornet 3"))
            {
            Tipos.push("Infornet 1")
            Tipos.push("Infornet 3")
            break;
            }
        }
    }
    }  
    }  
}

const validarData = (data,tipo) =>{
    var bandera = false    
    for(let i =0; i < data.length;i++)
    {
        let urltmp = data[i].filename
        let tmptipo = tipo.split(" ")
        if(urltmp.includes(tmptipo[0])){
            bandera = true;
            break;
        }
    }
    return bandera;
}

const AlmacenarArray = (tipo,papeleria,data) =>
{
    var tipopapaleria_ =""
    if(papeleria==="DIGITAL") {tipopapaleria_ = 3}
    else {tipopapaleria_ = 4}

    
    if(tipo === "CURRICULUM VITAE" || tipo === "CURRICULUM")
    {
        
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Curriculum 1"))
            {
        Tipos.push("Curriculum 1")
        Tipos.push("Curriculum " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Curriculum 2")
        }        
    }    
    else if(tipo === "COPIA DPI" || tipo === "DPI")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("DPI 1"))
            {
        Tipos.push("DPI 1")
        Tipos.push("DPI " + tipopapaleria_)
        }
    }
        else{
            Tipos.push("DPI 2")
        }    
    }    
    else if(tipo === "NIT")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Nit 1"))
            {
        Tipos.push("Nit 1")
        Tipos.push("Nit " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Nit 2")
        }
    }    
    else if(tipo === "RECIBO DE LUZ")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Recibo de luz 1"))
            {
        Tipos.push("Recibo de luz 1")
        Tipos.push("Recibo de luz " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Recibo de luz 2")
        }
    }    
    else if(tipo === "PARTIDA DE NACIMIENTO")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Partida de nacimiento 1"))
            {
        Tipos.push("Partida de nacimiento 1")
        Tipos.push("Partida de nacimiento " + tipopapaleria_)
        }
    }
        else{
            Tipos.push("Partida de nacimiento 2")
        }
    }    
    
    else if(tipo === "ANTECEDENTES PENALES ORIGINALES" || tipo === "ANTECEDENTES PENALES COPIA")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Carencia de antecedentes penales 1")){
        Tipos.push("Carencia de antecedentes penales 1")
        Tipos.push("Carencia de antecedentes penales " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Carencia de antecedentes penales 2")
        }
    }    
    else if(tipo === "ANTECEDENTES POLICIACOS ORIGINALES" || tipo === "ANTECEDENTES POLICIACOS COPIA")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Carencia de antecedentes policíacos 1")){
        Tipos.push("Carencia de antecedentes policíacos 1")
        Tipos.push("Carencia de antecedentes policíacos " + tipopapaleria_)}
        }
        else{
            Tipos.push("Carencia de antecedentes policíacos 2")
        }
    }
    
    else if(tipo === "IGSS")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Afiliación IGSS 1")){
        Tipos.push("Afiliación IGSS 1")
        Tipos.push("Afiliación IGSS " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Afiliación IGSS 2")
        }
    }
    
    else if(tipo === "ULTIMO TITULO OBTENIDO")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Copia de último título obtenido 1")){
        Tipos.push("Copia de último título obtenido 1")
        Tipos.push("Copia de último título obtenido " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Copia de último título obtenido 2")
        }
    }    
    else if(tipo === "TITULO")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Copia de título Diversificado 1")){
        Tipos.push("Copia de título Diversificado 1")
        Tipos.push("Copia de título Diversificado " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Copia de título Diversificado 2")
        }
    }    
    else if(tipo === "CARTAS PERSONALES I" || tipo === "CARTAS PERSONALES 1"  || tipo === "CARTAS PERSONALES II" || tipo === "CARTAS PERSONALES 2" || tipo === "CARTAS PERSONALES III" || tipo === "CARTAS PERSONALES 3")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("3 Cartas de recomendación personal 1")){
        Tipos.push("3 Cartas de recomendación personal 1")
        Tipos.push("3 Cartas de recomendación personal " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("3 Cartas de recomendación personal 2")
        }
    }   
    else if(tipo === "CARTAS LABORALES 1"|| tipo === "CARTAS LABORALES I" || tipo === "CARTAS LABORALES II" || tipo === "CARTAS LABORALES 2" || tipo === "CARTAS LABORALES III" || tipo === "CARTAS LABORALES 3")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Constancias laborales 1")){
        Tipos.push("Constancias laborales 1")
        Tipos.push("Constancias laborales " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Constancias laborales 2")
        }
    }    
    else if(tipo === "CONSTANCIA DE CURSOS APROBADOS")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            
            if(!Tipos.includes("Cierre de pensum de nivel Diversificado 1")){
        Tipos.push("Cierre de pensum de nivel Diversificado 1")
        Tipos.push("Cierre de pensum de nivel Diversificado " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Cierre de pensum de nivel Diversificado 2")
        }
    }  
    
    
    else if(tipo === "TARJETA DE PULMONES")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Tarjeta de pulmones 1")){
        Tipos.push("Tarjeta de pulmones 1")
        Tipos.push("Tarjeta de pulmones " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Tarjeta de pulmones 2")
        }
    }    
    else if(tipo === "TARJETA DE SALUD")
    {
        var bandera = validarData(data,tipo,tipopapaleria_)
        if(bandera){
            if(!Tipos.includes("Examen de Torax e Informe médico 1")){
        Tipos.push("Examen de Torax e Informe médico 1")
        Tipos.push("Examen de Torax e Informe médico " + tipopapaleria_)
            }
        }
        else{
            Tipos.push("Examen de Torax e Informe médico 2")
        }
    }          
}
