import * as constants from '../constants/table';

export default class Questions{
    constructor(props){
        this.question1=props['PREGUNTA I (from Prueba)'];
        this.question2=props['PREGUNTA 2 (from Prueba)'];
        this.question3=props['PREGUNTA 3 (from Prueba)'];
        this.question4=props['PREGUNTA 4 (from Prueba)'];
        this.question5=props['PREGUNTA 5 (from Prueba)'];
        this.question6=props['PREGUNTA 6 (from Prueba)'];
        this.question7=props['PREGUNTA 7 (from Prueba)'];
        this.question8=props['PREGUNTA 8 (from Prueba)'];
        this.question9=props['PREGUNTA 9 (from Prueba)'];
        this.question10=props['PREGUNTA 10 (from Prueba)'];
        this.question11=props['PREGUNTA 11 (from Prueba)'];
        this.question12=props['PREGUNTA 12 (from Prueba)'];
        this.question13=props['PREGUNTA 13 (from Prueba)'];
        this.question14=props['PREGUNTA 14 (from Prueba)'];
        this.question15=props['PREGUNTA 15 (from Prueba)'];
    }
    getInputs(){
        return {
            "inputs":[
                {
                    id:constants.modal.question1,
                    title:this.question1!=undefined?this.question1[0]:undefined,
                    name:constants.questionNames.question1
                },
                {
                    id:constants.modal.question2,
                    title:this.question2!=undefined?this.question2[0]:undefined,
                    name:constants.questionNames.question2
                },
                {
                    id:constants.modal.question3,
                    title:this.question3!=undefined?this.question3[0]:undefined,
                    name:constants.questionNames.question3
                },
                {
                    id:constants.modal.question4,
                    title:this.question4!=undefined?this.question4[0]:undefined,
                    name:constants.questionNames.question4
                },
                {
                    id:constants.modal.question5,
                    title:this.question5!=undefined?this.question5[0]:undefined,
                    name:constants.questionNames.question5
                },
                {
                    id:constants.modal.question6,
                    title:this.question6!=undefined?this.question6[0]:undefined,
                    name:constants.questionNames.question6
                },
                {
                    id:constants.modal.question7,
                    title:this.question7!=undefined?this.question7[0]:undefined,
                    name:constants.questionNames.question7
                },
                {
                    id:constants.modal.question8,
                    title:this.question8!=undefined?this.question8[0]:undefined,
                    name:constants.questionNames.question8
                },
                {
                    id:constants.modal.question9,
                    title:this.question9!=undefined?this.question9[0]:undefined,
                    name:constants.questionNames.question9
                },
                {
                    id:constants.modal.question10,
                    title:this.question10!=undefined?this.question10[0]:undefined,
                    name:constants.questionNames.question10
                },
                {
                    id:constants.modal.question11,
                    title:this.question11!=undefined?this.question11[0]:undefined,
                    name:constants.questionNames.question11
                },
                {
                    id:constants.modal.question12,
                    title:this.question12!=undefined?this.question12[0]:undefined,
                    name:constants.questionNames.question12
                },
                {
                    id:constants.modal.question13,
                    title:this.question13!=undefined?this.question13[0]:undefined,
                    name:constants.questionNames.question13
                },
                {
                    id:constants.modal.question14,
                    title:this.question14!=undefined?this.question14[0]:undefined,
                    name:constants.questionNames.question14
                },
                {
                    id:constants.modal.question15,
                    title:this.question15!=undefined?this.question15[0]:undefined,
                    name:constants.questionNames.question15
                },
            ]
        }
    }
}