import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core'
import {Accordion} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShowZeroBadge from '../Prompts/comentario'
import DraggableDialog from '../Prompts/Confirmar'
import Upload from '../Prompts/Upload'
import './cardEstilo.css'
import './components.css'
import Heading from '../Tour/Heading'
import Index from '../VisualizadorArchivo/index'
import {PostJwt, GetToken} from '../../../helpers/candidato.data'
import { isNull } from 'util'
import Cookies from 'universal-cookie'
import { calculaExpiracionSesion,CargarSesion } from '../Sesion/index'

const cookies = new Cookies();

const CardPapeleria = (props) => {  
    const {openTour,Documento,ID_Usuario,datos,Porcentaje,DataPapeleria,TiposPapeleria,Detalles,GuardarData,Estados,PAPELERIA_URL,
      Papeleria_Pendiente,Papeleria_Cargada,Registros,setRegistros,Mensajes} = props     
    const[ TipoActual , setTipoActual] = React.useState("")
    const [expanded, setExpanded] = React.useState(false);
    const [bandera, setBandera] = React.useState(false);    
    const [cambiarEstado, setCambiarEstado] = React.useState([]);
    const [banderaSesion, setbanderaSesion] = React.useState(true)

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      OpenTourDialog()                        
    };  

    const setTipo = (type) =>
    {
      setTipoActual(type)
  
    }

    function DescripcionPapeleria(tipo) 
    {         
      var descripcion = ""         
      for(let i = 0; i < Detalles.length;i++)
      {                  
        let tmp = Detalles[i].tipo
        if(tmp === tipo)
        {                      
          descripcion = Detalles[i].detalle                
          break;
        }        
      }
      
      return descripcion;
    }

    function OpenTourDialog(){
      if(!bandera){
        openTour()
        setBandera(true)
        }
    }

  function Iconos(status)
  {    
    if(status === "Aceptado" || status === "NoAplica" )
    {                  
      return(
        <div className="absolute" style={{backgroundColor: "#2DAA18"}}>
          <Heading  color="light" data-tut="reactour-estado">
         <p className="Texto" >Aprobado</p>
         </Heading>
         </div>
       
      )    
    }
    else if(status === "Subido"){
      return(
        <div className="absolute" style={{backgroundColor: "rgb(261, 198, 0)"}}>
          <Heading  color="light" data-tut="reactour-estado">
         <p className="Texto" >Subido</p>
         </Heading>
         </div>
       
      )
    }

    else if(status === "Enviado"){
      return(
        <div className="absolute" style={{backgroundColor: "rgb(261, 198, 0)"}}>
          <Heading  color="light" data-tut="reactour-estado">
         <p className="Texto" >Enviado</p>
         </Heading>
         </div>
       
      )
    }

    else if(status === "Pendiente" || status === "Pendiente por revisar")
    {
      return(
        <div className="absolute" style={{backgroundColor: "#A9A9A9"}}>               
            <Heading  color="light" data-tut="reactour-estado">
            <p className="Texto" >Pendiente</p>
            </Heading>                  
        </div>
      )
    }
    else{
      return(
        <div className="absolute" style={{backgroundColor: "#E86E71"}}>
         <Heading  color="light" data-tut="reactour-estado"> 
        <p className="Texto" >Rechazado</p>
        </Heading>
         </div>
      )
    }    
  }  

  useEffect(function () {
    if(datos !== null){
      if(datos.length > 0){
        let tmp = {id:Documento }        
        PostJwt.post(tmp).then(async (Response) => { 
          if(Response.status === 200){    
            
            cookies.set("_s",Response.data,{
              path: "/", 
              expires: calculaExpiracionSesion()
          })

            localStorage.setItem(`Token`,JSON.stringify({
              Token : Response.data
            }))
          }
        }).catch( err => {
          console.log(err)                  
      })
      if(banderaSesion)
      {
      setBandera(CargarSesion(datos,GuardarData,setCambiarEstado,cambiarEstado,TiposPapeleria))
      setbanderaSesion(false)
      }
      }      
      //datos,GuardarData,setCambiarEstado,cambiarEstado
    }
  }, [datos])

  const renderCard = (card,index) =>
  {   
    let tmp = index + 1;
    return(
          <Grid
            container 
            direction = 'row'
            alignItems = "center"
            alignContent = "center"      
          >
            <Grid
              item 
              xs = {12}
              md = {12}
              lg = {12}
              sm = {12}          
            >
              
              <div className= "Root" >  ​
                <Accordion expanded={expanded === `panel${tmp}`} onChange={handleChange(`panel${tmp}`)} style={{borderColor:"black"}}
                  onClick={() => setTipo(card.tipo)}                
                >
                  <AccordionSummary
                    style = { { marginTop : '10px'} }
                    expandIcon={ <ExpandMoreIcon /> }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">    
                    <div>     
  
                    <Typography className="Heading"  style={{textAlign:"left"}}>{card.tipo} </Typography>
 
                    <Typography className="secondaryHeading">                                          
                    
                    <span>{DescripcionPapeleria(card.tipo)}</span>
                    </Typography>
                    </div> 
                    
                    {Iconos(card.status,card.tipo)}
                                                
                  </AccordionSummary> 

                  <AccordionDetails> 
                  <ul className="nav"> 
                  <li>
                  <Heading color="light" data-tut="reactour-cargado">
                  <Upload status={card.status} tipo={TipoActual} Documento={Documento}                       
                  datos={datos}
                  GuardarData={GuardarData}
                  setCambiarEstado={setCambiarEstado}
                  Estados={Estados}
                  cambiarEstado={cambiarEstado}
                  tmp={tmp}
                  /> 
                  </Heading>
                  </li>
                  <li>
                          {
                        (card.status === "Aceptado" || card.status === "NoAplica")
                          ?
                          //Boton para visualizar si se tienen mensajes*/}                                  
                            null
                          :  
                          <Heading data-tut="reactour-alertas">                        
                          <ShowZeroBadge tipo={card.tipo} mensaje={card.comment}
                          Mensajes={Mensajes}
                          status={card.status}
                          />
                          </Heading>
                          }  
                    </li> 
                    <li>
                        {
                        (card.status === "Aceptado" || card.status === "NoAplica")
                          ?
                          //Boton para vista previa de archivo*/}                                  
                            null
                          :     
                          <Heading  data-tut="reactour-vista">                       
                          <Index tipo={card.tipo} Documento={Documento}
                          Data={DataPapeleria}
                          status={card.status}
                          /> 
                          </Heading>
                          }   
                      </li> 
                    </ul>                                                                                                             
                </AccordionDetails>        
                </Accordion>         
                </div>
            </Grid>           
          </Grid>    
    )
  }

  return (    
      <div>          
        {    
            Porcentaje === 100 ?          
            null :
            datos.map(renderCard)            
        }

        <br/>        
        {
        Porcentaje < 100 ?
        <Heading h="3" data-tut="reactour-enviar"> 
        <DraggableDialog
        ID_Usuario={ID_Usuario}        
        DataPapeleria={DataPapeleria}
        TiposPapeleria={TiposPapeleria} 
        Estado = {Estados}
        cambiarEstado={cambiarEstado}
        PAPELERIA_URL={PAPELERIA_URL}
        Papeleria_Pendiente={Papeleria_Pendiente}
        Papeleria_Cargada={Papeleria_Cargada}
        Registros={Registros}
        setRegistros={setRegistros}
        />    
         </Heading>            
        : null
        }        
   </div> 
  );
}

export default CardPapeleria;
