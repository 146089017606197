import React from 'react'
import { Grid, makeStyles, Typography} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import * as constants from '../../constants'
import './index.css'
const useStyles = makeStyles(theme =>({
    imageSize:{
        width: '900px',
        height: 'auto'
    },
    gridStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignContent:'center',
        alignItems:'center'
    },
    textStyle:{
        fontFamily: 'lato',
        paddingTop: '20px'
    },
    icons:{
        color: '#00CC00',
        width: '100px',
        height: 'auto'
    },
    textStyleText:{
        paddingTop:'10px',
        textAlign: 'center'
    }
}))



const SendQuestionsForm  = () => {
    const classes = useStyles()
    return (
        <div>    
            <div>
               <Grid
                    container
                    justify = 'center'
                    className = { classes.gridStyle }

               >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{minHeight:'87vh'}}
                    >
                        <Grid item xs={10} md={10} lg={8}>
                            {/* <img 
                                src = {constants.ROUTES.IMG_SEND}
                                className = 'contenedor'
                            /> */}
                            <center>
                                <CheckCircleOutlineIcon
                                    className = {classes.icons}
                                />
                            </center>
                            <center>
                                <Typography 
                                    variant = 'subtitle1'
                                    className = {classes.textStyle}
                                >
                                    GRACIAS!!
                                </Typography>
                            </center>
                                <Typography
                                    variant = 'subtitle1'
                                    className = {classes.textStyleText}
                                >
                                    LA CALIFICACIÓN SE HA ENVIADO CORRECTAMENTE
                                </Typography>

                            <center>
                                
                            </center>
                        </Grid>
                    </Grid>
                </Grid> 
            </div>
        </div>            
    )
}

export default SendQuestionsForm;