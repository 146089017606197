import React from "react";
import TransitionsModal from "../../GeneralCandidato/VisualizadorArchivo/VisualizadorEnviado/ModalVisualizadorEnviado";
import TransitionsModal2 from "../../GeneralCandidato/VisualizadorArchivo/visualizadorSubido/ModalVisualizadorSubido";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";

// npm install @react-pdf-viewer/default-layout
// npm install @react-pdf-viewer/core@2.4.1
// npm install pdfjs-dist@2.6.347

const Index = (props) => {
  const { tipo, Data, status } = props;

  function Mensaje() {
    alert("Archivo pendiente por subir.");
  }
  return (
    <div>
      {status === "Subido" ? (
        <TransitionsModal2 tipo={tipo} status={status} />
      ) : status === "Enviado" ? (
        <TransitionsModal tipo={tipo} DataPapeleria={Data} status={status} />
      ) : (
        <label style={{ cursor: "pointer" }} onClick={() => Mensaje()}>
          <IconButton aria-label="upload file" component="span">
            <FontAwesomeIcon icon={faSearch} className="IconoBuscar" />
          </IconButton>
          <span className="Titulo">Vista previa</span>
        </label>
      )}
    </div>
  );
};

export default Index;
