
export var surveyJSON = {
    "title":"SOLICITUD DE EMPLEO MRB",
    "logoWidth":300,
    "logoHeight":100,
    "logoFit": "cover",
    "logo": "https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2Fdescarga.png?alt=media&token=5bb83d53-f4ce-4dcf-9f77-36027d5122f9",
    "pages": 
    [
        {
            "name":"page1",
            "elements":
            [
                // {
                //     "type":"text",
                //     "name":"FIRST_NAME",
                //     "title":"Nombre Completo",
                //     "isRequired": true,                    
                // }
                // ,{
                //     "type":"text",
                //     "name":"DATE",
                //     "title":"Fecha de Nacimiento",
                //     "inputType":"date",
                //     "isRequired": true,
                // },
                // {
                //     "type":"radiogroup",
                //     "name":"TYPE_DOCUMENT",
                //     "isRequired": true, 
                //     "title":"Tipo de Documento Identificación",
                //     "choices":
                //     [
                //         {
                //             "value":"DPI",
                //             "text":"DPI"
                //         },
                //         {
                //             "value":"LICENCIA DE CONDUCIR",
                //             "text":"LICENCIA DE CONDUCIR"
                //         },
                //         {
                //             "value":"PASAPORTE",
                //             "text":"PASAPORTE"
                //         }
                //     ]
                // },
                // {
                //     "type":"dropdown",
                //     "name":"MARITIAL_STATUS",
                //     "title":"ESTADO CIVIL ",
                //     "isRequired": true, 
                //     "choices":
                //     [
                //         {
                //             "value":"CASADO",
                //             "text":"CASADO"
                //         },
                //         {
                //             "value":"SOLTERO",
                //             "text":"SOLTERO"
                //         }
                //     ]
                // },
                // {
                //     "type":"text",
                //     "name":"NO_IDENTIFICATION",
                //     "isRequired": true, 
                //     "title":"No. Identificación"
                // },
                // {
                //     "type":"dropdown",
                //     "name":"DEPARTAMENT",
                //     "title":"DEPARTAMENTO DE RESIDENCIA",
                //     "isRequired": true, 
                //     "choices":
                //     [
                //         {
                //             "value":"GUATEMALA","text":"GUATEMALA"
                //         },
                //         {
                //             "value":"EL PROGRESO",
                //             "text":"EL PROGRESO"
                //         },
                //         {
                //             "value":"BAJA VERAPAZ",
                //             "text":"BAJA VERAPAZ"
                //         },
                //         {
                //             "value":"CHIMALTENANGO",
                //             "text":"CHIMALTENANGO"
                //         },
                //         {
                //             "value":"ZACAPA",
                //             "text":"ZACAPA"
                //         },
                //         {
                //             "value":"JUTIAPA",
                //             "text":"JUTIAPA"
                //         },
                //         {
                //             "value":"CHIQUIMULA",
                //             "text":"CHIQUIMULA"
                //         },
                //         {
                //             "value":"JALAPA",
                //             "text":"JALAPA"
                //         },
                //         {
                //             "value":"ALTA VERAPAZ",
                //             "text":"ALTA VERAPAZ"
                //         },

                //         {
                //             "value":"ESCUINTLA",
                //             "text":"ESCUINTLA"
                //         },
                //         {
                //             "value":"HUEHUETENANGO",
                //             "text":"HUEHUETENANGO"
                //         },
                //         {
                //             "value":"IZABAL",
                //             "text":"IZABAL"
                //         },
                //         {
                //             "value":"PETEN",
                //             "text":"PETEN"
                //         },
                //         {
                //             "value":"QUETZALTENANGO",
                //             "text":"QUETZALTENANGO"
                //         },
                //         {
                //             "value":"QUICHE",
                //             "text":"QUICHE"
                //         },
                //         {
                //             "value":"RETALHULEU",
                //             "text":"RETALHULEU"
                //         },
                //         {
                //             "value":"SACATEPEQUEZ",
                //             "text":"SACATEPEQUEZ"
                //         },
                //         {
                //             "value":"SAN MARCOS",
                //             "text":"SAN MARCOS"
                //         },
                //         {
                //             "value":"SANTA ROSA",
                //             "text":"SANTA ROSA"
                //         },
                //         {
                //             "value":"SOLOLA",
                //             "text":"SOLOLA"
                //         },
                //         {
                //             "value":"SUCHITEPEQUEZ",
                //             "text":"SUCHITEPEQUEZ"
                //         },
                //         {
                //             "value":"TOTONICAPAN",
                //             "text":"TOTONICAPAN"
                //         }
                //     ]
                // },
                // {
                //     "type":"text",
                //     "name":"ADDRESS",
                //     "isRequired": true, 
                //     "title":"DIRECCIÓN"
                // },
                // {
                //     "type":"text",
                //     "name":"ZONE",
                //     "isRequired": true, 
                //     "title":"ZONA",
                // },
                // {
                //     "type":"text",
                //     "name":"TEL",
                //     "title":"TELEFONO"
                // },
                // {
                //     "type":"text",
                //     "name":"NAME_AND_EMERGENCY",
                //     "title":"NOMBRE Y TELEFONO CONTACTO DE EMERGENCIA"
                // },
                // {
                //     "type":"text",
                //     "name":"TEL_EMERGENCY",
                //     "inputType": "number",
                //     "title":"TELEFONO DE EMERGENCIA",
                // },
                // {
                //     "type":"text",
                //     "name":"ESTUDENT",
                //     "isRequired": true, 
                //     "title":"ESTUDIOS UNIVERSITARIOS "
                // },
                // {
                //     "type":"text",
                //     "name":"EMAIL",
                //     "isRequired": true, 
                //     "title":"Correo Electrónico *",
                //     "inputType":"email"
                // },
                // {
                //     "type":"dropdown",
                //     "name":"JOB_PLAZA",
                //     "title":"Plaza que Aplica",
                //     "choices":
                //     [
                //         {
                //         "value":"item1","text": "MOTORISTA",
                //         "item2":"",
                //         "item3":"",
                //         }
                //     ]
                // },
                {
                    "type":"file",
                    "name":"FOTO",
                    "isRequired": true, 
                    "title":"Foto de Perfil ",
                    "maxSize":0,
                    "acceptedTypes":"image/png, image/jpeg",
                    "storeDataAsText": true,
                    "allowMultiple": true,
                },
                // {
                //     "type":"file",
                //     "name":"CV",
                //     "isRequired": true, 
                //     "title":"CV ",
                //     "maxSize":0,
                //     "acceptedTypes": "application/pdf"
                // },
                // {
                //     "type":"text",
                //     "name":"SALARY",
                //     "isRequired": true, 
                //     "inputType": "number",
                //     "title":"Pretension Salarial *"
                // },
            ],
            "title":"Solicitud de Empleo",
            "description":"Queremos ayudarte a conseguir empleo. Ayúdanos llenando el siguiente formulario y nosotros nos encargamos del resto."
        }
    ]
}