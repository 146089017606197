import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Paper,Snackbar} from '@material-ui/core';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import {Alert,AlertTitle} from '@material-ui/lab';
import {PatchPapeleria} from '../../../helpers/candidato.data.js'
import {ArrayUrls,URLS} from './Metodos_Patch_Papeleria'
import CircularProgress from '@material-ui/core/CircularProgress';
import '../Papeleria/components.css'
import {Button as Buttons} from 'react-bootstrap'
import '../../../Spinner/loader.css'
import {ValidarToken} from './Plazas'
import {renovarSesion} from '../Sesion/index'
import {RegistroCargar} from '../../Registro_Data/metodos'
import './estilos.css'


function PaperComponent(props) {
    
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props) {  
  const {ID_Usuario,DataPapeleria,TiposPapeleria,Estado,cambiarEstado,PAPELERIA_URL,
    Registros,setRegistros} = props
  const [opensnack, setOpensnack] = React.useState(false);
  
  const [banderaFile, setbanderaFile] = useState(null)
  const [banderaFileFalse, setbanderaFileFalse] = useState(null)  
  const [Loading, setLoading] = React.useState(false);
  const [OpenAlert, setOpenAlert] = React.useState(false); 

    const handleClickOpen = () => {
      setOpen(true);
    };

      const handleCloseP = () => {
        setLoading(false);
      };    

      const handleLoading = () => {
        setLoading(!Loading);
      };  

    
    const handleSend = async() =>{   
        let cond = await ValidarToken()
        let bandera = renovarSesion()
        if(!cond.bandera && !bandera)
        {setOpen(false)
        EnviarArchivos()  } 
        else{
          setOpenAlert(true)
          localStorage.clear()
          window.location.href = "/Papeleria"
        }
    }

    function DelayMessage() {     
      setTimeout(function(){ window.location.href = "/Papeleria" }, 2000);
    }

    function Estados(){
 
      let nuevo = ""
      var nuevo_array = []

      if(Estado !== undefined)
      {
      let s = Estado.split("],");
      Object.keys(s).forEach( (obj) => 
      {
          let tmp = s[obj].replace("[","")
          let tmp2 =tmp.split(":")
          if(tmp2[1] !== undefined)
          {
          nuevo_array.push({tipo: tmp2[0], status: tmp2[1]}) 
        }
      })
      
      Object.keys(nuevo_array).forEach( (obj) => {
   
          Object.keys(cambiarEstado).forEach( (obj2) => {
       
              let tmp3 = "[" + cambiarEstado[obj2].tipo + ":" + cambiarEstado[obj2].status +"],"
              if(!nuevo.includes(tmp3))
              {
               if(nuevo_array[obj].tipo === cambiarEstado[obj2].tipo){
                   nuevo = nuevo + tmp3            
               }
               else{
                    nuevo = nuevo + tmp3            
               }
                  
              }
          })                                         
      })

      Object.keys(nuevo_array).forEach( (obj) => {          
        if(!nuevo.includes(nuevo_array[obj].tipo)){
            nuevo = nuevo +  "[" + nuevo_array[obj].tipo + ":" + nuevo_array[obj].status +"],"
        }
    })
        
    }
    else{
      Object.keys(cambiarEstado).forEach( (obj2) => {   
        let tmp3 = "[" + cambiarEstado[obj2].tipo + ":" + cambiarEstado[obj2].status +"],"
        nuevo = nuevo + tmp3
    })
    }    
      return nuevo;    
    }
  
    function EnviarArchivos()
    { 
      handleLoading()  
      let urls = ArrayUrls(DataPapeleria,TiposPapeleria)     
      let nuevos_estados = Estados()   
      //let urls_arrays = URLS(TiposPapeleria,PAPELERIA_URL)       
      let t; 
      let Registro = RegistroCargar(Registros,cambiarEstado)
      setRegistros(Registro)
      let fechaActual = new Date();
      let strTime = fechaActual.toLocaleString('en-GB', {
        timeZone: `US/Central`
      });      
        t = { 
      "PAPELERIA": urls, 
      "ESTADO_GOLDEN_PAPELERÍA" : nuevos_estados,      
      "ULTIMA_FECHA_PAPELERÍA" : strTime,
      "REGISTRO_CARGAR_PAPELERIA" : Registro
    }
              
        let tmp2 = [{id:ID_Usuario,fields : t}]
        let tmp3 = {records : tmp2}
        let tmp4 = JSON.stringify(tmp3) 
        console.log(tmp4);
                                  
        PatchPapeleria.patch(tmp4).then( response => {        
          if(response.status === 200)
          {
            localStorage.clear() 
            SetBanderasFiles(1)                    
            DelayMessage()
          }
          else{
            SetBanderasFiles(2) 
          }
        }).catch(err => 
                  {                    
                      console.error(err)
                      SetBanderasFiles(2) 
                  })
    
        //window.location.href = '/MRB/Papeleria'         
    }
 
    const handleClosed = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpensnack(false);
    };
    const [open, setOpen] = React.useState(false);

    const SetBanderasFiles = (tipo) =>
    {
      handleCloseP()
    
      if(tipo === 1)
      {
        setbanderaFile(true)
      }
      else
      {
        setbanderaFileFalse(true)
      }
    }
  
    
    const handleClose = () => {
      setOpen(false)
    };
    
    const handleClosedd = () => {
      setbanderaFile(false);
    };

    const handleClosedd1 = () => {
      setbanderaFileFalse(false);
    };
  
      

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }    
    setOpenAlert(false);
    localStorage.clear()
    window.location.href = "/Papeleria"
  };


  return (
    <div>
    
    {
      Loading ? 
      <div className="loading-backdrop">
      <CircularProgress color="primary" />
      </div>
       : null
    }
          <div  style={{display:"flex",justifyContent:"center",alignItems:"center"}} >      

          <Snackbar open={banderaFile} autoHideDuration={2000} onClose={handleClosedd}>
          <Alert variant="filled" severity="success" onClose={handleClosed} >
          Archivos subidos correctamente.
          </Alert>
          </Snackbar>          
        
          <Snackbar open={banderaFileFalse} autoHideDuration={2000} onClose={handleClosedd1}>
          <Alert variant="filled" severity="error" onClose={handleClosed} >
          Error al subir archivo, pruebe de nuevo por favor.
          </Alert>
          </Snackbar>   

       <Buttons
       variant="contained"
       className="BotonEnviar"         
       onClick={handleClickOpen}    
      ><span>Guardar</span></Buttons> </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"        
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          ¿Desea subir papelería?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            La papelería subida será revisada por nuestros reclutadores. ¿Desea subir su papelería?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSend} color="primary">
            Enviar
          </Button>          
        </DialogActions>
      </Dialog>

      <Snackbar open={OpenAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
            <Alert severity="error">
            <AlertTitle>Tu sesión ha expirado</AlertTitle>
            <strong>Por favor, vuelve a iniciar sesión.</strong>
            </Alert> 
            </Snackbar> 
                                          
    </div>
  );
}