//Paso 1: Si el usuario nunca ha subido archivo subirlo con url
//Paso 2: Si el usuario ya tiene archivo y quiere actualizarlo se sube con url el que no.
//Paso 3: Los archivos que no se toquen deben de mantenerse.

 
export function ArrayUrls(Data,Tipos)
{
    let urls = []     
    let datos = []       
    let tiposPermitidos = []   
    let IDS = []
    //datos contiene las urls de los archivos que el usuario suba
    datos = ObtenerStorage(Tipos)               
    //tipos permitidos es la letiable que servira para traer los IDS de los archivos que no se desean modificar
    tiposPermitidos = setTiposPermitidos(Tipos)
    //IDS contiene los ids de los archivos que no se modificaran
    IDS = getId(tiposPermitidos,Data)      
    let final = (FinalArray(datos,IDS))                  
    return final
}    


function FinalArray(data1,data2)
{
//data1 son las urls de los archivos que el usuario subira
//data2 son los ids de los archivos que no se modificaran        
//escenario 1 el usuario sube archivos por primera vez a airtable
if(data1.length > 0 && data2.length === 0)
{
    let urls = []
    for(let i = 0; i < data1.length;i++)
    {
        let tmp = {url : data1[i]}
        if(!validarExistencia(tmp,urls))
        {
            urls.push(tmp)
        }
    }
    console.log(urls);
    return urls;
}
//escenario 2 el usuario sube archivos y ya existen archivos subidos en airtable
else if(data1.length > 0 && data2.length > 0)
{
    let urls = []
    for(let i = 0;i < data2.length;i++)
    {
        let tmp = {id : data2[i]}
        if(!validarExistencia(tmp,urls))
        {
            urls.push(tmp)
        }        
    }
    for(let i = 0; i < data1.length;i++)
    {
        let tmp = {url : data1[i]}
        if(!validarExistencia(tmp,urls))
        {
            urls.push(tmp)
        }        
    }
    console.log(urls);
    return urls;    
}
//no se sube nada a airtable                
else
{
    return "No"
}
}

function getId(tiposPermitidos,DataPapeleria)
{
let IDS = [] 

    if(DataPapeleria !== undefined)
    {    
        for(let i = 0; i < tiposPermitidos.length;i++)
        {
            let tmp = tiposPermitidos[i].split(" ")
            let idFile = ""
            let NombreFile = ""  
            
            for(let j = 0; j <DataPapeleria.length;j++)
            {
                idFile = DataPapeleria[j].id;                
                NombreFile = DataPapeleria[j].filename;          
                if(NombreFile.includes(tmp[0]))             
                {
                    IDS.push(idFile)
                    break;
                }
            }        
        }
            
    }
return IDS;
}

function ObtenerStorage(tipos)
{
    let myarray = []               
for(let i = 0; i< tipos.length;i++) 
{
    let data = JSON.parse(localStorage.getItem(`url${tipos[i]}`))         
      if(data != null)
        {                                                            
            myarray.push(data.url)                    
        }
    }  
    return myarray;        
}

function setTiposPermitidos(tipos)
{           
    let Tipos = []     
    
    for(let i = 0; i< tipos.length;i++) 
    {
        let data = JSON.parse(localStorage.getItem(`url${tipos[i]}`))         
        let nombre = tipos[i]         
          if(data != null)
            {                                                                                                                   
            }
            else{
                Tipos.push(nombre)
            }
    }                                      
    return Tipos;                          
    }
function validarExistencia(elemento,myarray)
{
    let bandera = false;

    for(let i =0; i < myarray.length; i++){
        if(JSON.stringify(myarray[i]) === JSON.stringify(elemento)){
            bandera = true;
            break;
        }
    }
    return bandera;
}

export function URLS(tipos,PAPELERIA_URL)
{
    let tmp = []
    for(let i = 0; i < tipos.length; i++){
        let data = JSON.parse(localStorage.getItem(`url${tipos[i]}`))
        if(data !== null){
            tmp.push({"tipo": tipos[i], "url": data.url})
        }
    }
    return JSON.stringify(tmp)
}