export default class CandidatePoll{
    constructor(fields){
        this.empresa = fields === null ?"": fields['EMPRESA QUE SOLICITA - MRB'];
        this.prueba = fields === null ?"" : fields['TIPO DE PRUEBA'];
    }
    toJson(){
        return{
            "imagen": this.empresa,
            "prueba":this.prueba,
        }
    }
}