import React, { Component } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import "./styles.css";
import CardPapeleria from '../Papeleria/CardPapeleria';

class ReactTour extends Component {
  constructor(props) {
    super();
    this.state = {
      isTourOpen: false,
      isShowingMore: false
    };
  }

  disableBody = target => disableBodyScroll(target);
  enableBody = target => enableBodyScroll(target);

  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.isShowingMore
    }));
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };

  render() {
    const { isTourOpen, isShowingMore} = this.state;
    const accentColor = "#5cb7b7";

    return (
      <div>
                       
        <CardPapeleria 
        Nombre={this.props.Nombre}
        Documento={this.props.Documento}
        ID_Usuario={this.props.ID_Usuario}
        datos={this.props.datos}
        Porcentaje={this.props.Porcentaje}
        DataPapeleria={this.props.DataPapeleria}
        TiposPapeleria={this.props.TiposPapeleria}
        Detalles={this.props.Detalles}
        openTour={this.openTour}
        toggleShowMore={this.toggleShowMore}
        isShowingMore={isShowingMore}
        GuardarData={this.props.GuardarData}
        Estados={this.props.Estados}
        PAPELERIA_URL={this.props.PAPELERIA_URL}
        Papeleria_Pendiente={this.props.Papeleria_Pendiente}
        Papeleria_Cargada={this.props.Papeleria_Cargada}
        Registros={this.props.Registros}
        setRegistros={this.props.setRegistros}
        Mensajes={this.props.Mensajes}
        />

       { 
        this.props.DataPapeleria === undefined ?
       <Tour
          onRequestClose={this.closeTour}
          steps={tourConfig}
          isOpen={isTourOpen}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={accentColor}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}        
        />
        : null
        }

      </div>
    );
  }
}

const tourConfig = [
      {
        selector: '[data-tut="reactour-estado"]',
        content: () => (
          <div>
            <p>Esta opción te indicara el estatus de tu papelería.</p>
            
              <li style={{fontWeight:"600"}}
              >Enviada - que tu papelería fue recibida exitosamente.</li>
              <li 
              style={{fontWeight:"600"}}
              >Aprobada - que el analista validó que tu papelería cumplía los requerimientos.</li>
              <li
              style={{fontWeight:"600"}}
              >Rechazada - que necesita enviarse nuevamente porque no cumplio con los requerimientos solicitados.  </li>

            
          </div>
        )              
      },  
      {
        selector: '[data-tut="reactour-cargado"]',
        content: `Esta opcíon puedes subir tus archivos. Formatos aceptados pdf, jpg o png.`
      },
      {
        selector: '[data-tut="reactour-alertas"]',
        content: `En caso tu papelería fuese rechazada, en esta opción puedes verificar las observaciones del porque no fue aceptada.`
      },
      {
        selector: '[data-tut="reactour-vista"]',
        content: `Esta opción te servirá para que puedas tener una vista previa de tus archivos cargados.`
      }
      ,
      {
        selector: '[data-tut="reactour-enviar1"]',
        content: () => (
          <div>
            <p>
            Una vez que estes seguro de que tu papelería sea la correcta, puedes darle click al boton de guardar y la papelería que seleccionaste será revisada por nuestros reclutadores.
            </p>
            <br/>
            <img src="https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2FBotonEnviar.PNG?alt=media&token=9e7f637d-42b1-4e6d-be91-b76994fc3dc5" style={{width: "100%", heigth:"50%"}}/>
          </div>
        )
        
      }
    ];

export default ReactTour;
