import React,{ Fragment } from 'react';
import {Grid,Typography,createMuiTheme, ThemeProvider} from '@material-ui/core'
import * as constants from '../../../constants';

// let theme = createMuiTheme();
// theme.typography.h6 = {
//     fontSize: '1.2rem',
//     '@media (min-width:600px)': {
//         fontSize: '1rem',
//         fontFamily: 'Lato',
//         fontWeight: 700
//       },
//       [theme.breakpoints.up('md')]: {
//         fontSize: '2rem',
//         fontFamily: 'Lato Helvetica, sans-serif',
//       },
//       '@media (min-width:799px)': {
//         fontSize: '2rem',
//         fontFamily: 'Lato Helvetica, sans-serif',
//       },
//       [theme.breakpoints.up('lg')]: {
//         fontSize: '2rem',
//         fontFamily: 'Lato Helvetica, sans-serif',
//       },
//       '@media (min-width:800px)': {
//         fontSize: '1rem',
//         fontFamily: 'Lato Helvetica, sans-serif',
//       },
//       [theme.breakpoints.up('lg')]: {
//         fontSize: '1rem',
//         fontFamily: 'Lato Helvetica, sans-serif',
//       },
//       '@media (min-width:1000px)': {
//         fontSize: '2rem',
//         fontFamily: 'Lato Helvetica, sans-serif',
//       },
//       [theme.breakpoints.up('lg')]: {
//         fontSize: '2rem',
//         fontFamily: 'Lato Helvetica, sans-serif',
//       },
// }
const PagePrincipalJob = () => {
    return(
            <Fragment>
                <div >
                    <center>  
                        <img
                            src = {constants.AirtableConstants.values.ROUTES.IMG_MRB}
                            className = 'imagen1 imagen2 imagen3 imagen5 imagen6 imagen7 imagen8 imagen9 imagen10'
                        ></img>
                    </center>

                    <Grid
                        container
                        direction = 'row'
                        alignItems = 'center'
                        alignContent = 'center'
                        justify ='center'
                        className  = 'desing1 desing2 desing3 desing4 desing5 desing6 desing7 desing8 desing9'
                    >
                        <Grid
                            item
                            lg = { 12 }
                            md = { 6 }
                            xs = { 12 }
                            sm = { 12 }
                            direction="row"
                            alignItems='center'
                            style = {
                                {
                                    textAlign:'center'
                                }
                            }
                        >
                            <Typography
                                variant = 'h6'
                                style = {{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    fontFamily: 'Lato, Helvetica, sans-serif'
                                }}
                                gutterBottom
                            >  
                                BIENVENIDOS A LA PLATAFORMA DE GRUPO MRB
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction = 'row-reverse'
                        alignItems = 'center'
                        alignContent = 'center'
                        justify ='center'
                        className ='alignText alignText2 alignText3 alignText4 alignText5 alignText6 alignText7 alignText8'
                    >
                        <Grid
                            xs = { 12 }
                            lg = { 12 }
                            md = { 12 }
                            sm = { 12 } 
                            style = {{
                                marginRight: '10%',
                                border: '1px solid black',
                                textAlign: 'right',
                                backgroundColor: '#000',
                                paddingTop:'15px',
                                paddingBottom: '15px',
                                paddingRight: '30px',
                            }}
                        >
                            <Typography
                                variant = "h6"
                                style = {{
                                    color: '#F7DC6F',
                                    }}
                            >SOLICITUD DE EMPLEO</Typography>
                        </Grid>
                    </Grid>
            </div>
        </Fragment>
    )
};

export default PagePrincipalJob;

