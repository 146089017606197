import * as types from '../types/job.aplication';

export const startedFetchingAirtable = (id) => ({
    type: types.FETCHING_GET_JOB_APLICATION_STARTED,
    payload: {
        id
    }
})

export const completedFetchingAirtable = data => ({
    type: types.FETCHING_GET_JOB_APLICATION_COMPLETED,
    payload:{
        data
    }
})

export const errorFetchingAirtable = error => ({
    type: types.FETCHING_GET_JOB_APLICATION_FAILED,
    payload:{
        error
    }
})

export const startedPostAirtable = (request,file,fileDescriptor) => ({
    type: types.POST_JOB_APLICATION_STARTED,
    payload: {
        request,
        file,
        fileDescriptor
    }
})


export const completedPostAirtable = request => ({
    type: types.POST_JOB_APLICATION_COMPLETED,
    payload:{
        request
    }
})

export const errorPostData = error => ({
    type: types.POST_JOB_APLICATION_FAILED,
    payload:{
        error
    }
})

