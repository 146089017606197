import React from "react";
import {
  Navbar,
  Nav,
  DropdownButton,
  Dropdown,
  Container,
  NavDropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const LogOut = () => {
  function CerrarSesion() {
    localStorage.clear();
    cookies.remove("_s");
    window.location.href = "/Papeleria";
  }
  return (
    <div>
      <Navbar expanded="true" id="navbar">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/*  <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#link">Link</Nav.Link>*/}
          </Nav>

          <DropdownButton drop="left" title="">
            <Dropdown.Item
              onClick={() => CerrarSesion()}
              style={{ fontFamily: "Poppins" }}
            >
              Cerrar Sesión
            </Dropdown.Item>
          </DropdownButton>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default LogOut;
