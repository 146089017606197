import axios from 'axios';
import './Estilos.css'
export function Iconos(status)
{
  if(status === "Aceptado" || status === "NoAplica" )
  {                  
    return(
      <div className="absolute"  style={{backgroundColor: "#2DAA18"}}>
       <p key="Aprobado" className="Texto" >Aprobado</p>
       </div>
     
    )    
  }
  else if(status === "Subido"){
    return(
      <div className="absolute" style={{backgroundColor: "rgb(261, 198, 0)"}}>
       <p  key="Subido" className="Texto" >Subido</p>
       </div>
     
    )
  }

  else if(status === "Enviado"){
    return(
      <div className="absolute" key="Enviado" style={{backgroundColor: "rgb(261, 198, 0)"}}>
       <p key="Enviado" className="Texto" >Enviado</p>
       </div>
     
    )
  }

  else if(status === "Pendiente" || status === "Pendiente por revisar")
  {
    return(
      <div className="absolute"  style={{backgroundColor: "#A9A9A9"}}>               
          <p key="Pendiente" className="Texto" >Pendiente</p>         
      </div>
    )
  }
  else{
    return(
      <div className="absolute" style={{backgroundColor: "#E86E71"}}>
      <p  key="Rechazado" className="Texto" >Rechazado</p>
         </div>
    )
  }  
}


function Descargar(urlfile,urlName)  
{
  axios({
    url: urlfile,
    method: 'GET',
    responseType: 'blob'
  }).then((response) =>
  {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download',urlName)
    document.body.appendChild(link)
    link.click()
  })
}

 
const validarData = (arrayTmp,filename) =>{
  var bandera = false 
  var contador = 0;   
  filename = GetFileName(filename)
  for(let i =0; i < arrayTmp.length;i++)
  {
      if(filename.includes(arrayTmp[i])){
          contador ++;
      }
  }
  if(contador ===arrayTmp.length) {bandera = true;}
  return bandera;
}


export function DescargarArchivo(tipo,DataPapeleria)
  {   
    if(DataPapeleria != null)
    {
      var NombreFile = ""
      var UrlFiles = ""      
    for(let i =0; i< DataPapeleria.length;i++)
    {      
      NombreFile = (DataPapeleria[i].filename);      
      let tmp = tipo.split(" ")      
      let bandera = validarData(tmp,NombreFile)
      if(bandera)
      {
        UrlFiles = DataPapeleria[i].url;        
        break
      }
    }
    if(UrlFiles.length === 0)
    {
      alert("Archivo pendiente por subir.") 
    }
    else{        
    //Pendiente, url de archivo candidato.    
    Descargar(UrlFiles,NombreFile)
    }  
  }
  else{
    alert("Archivo pendiente por subir.")
  }
  
  }


  function GetFileName(url_link)
  {
    let tmp = url_link.split("%2F")
    let tmp2 = tmp[1].split("%7C")
    let tmp3 = tmp2[0].replace("%20"," ").replace("%20"," ")
    return tmp3
  }
  