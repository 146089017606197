import axios from "axios";
import { HOST, DATABASES, APIKEY } from "../json/AirTableGolden.json";

//METODO GET AIRTABLE POR DPI
export const request = {
  //Metodo para recibir getById
  get: function (candidato) {
    let url = `${HOST}/${DATABASES.AGENDA}?filterByFormula=AND({No. DPI o Pasaporte o Licencia (from CANDIDATOS)}='${candidato}')`;
    //let url  = `${HOST}/${DATABASES.CANDIDATE}`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${APIKEY}`,
      },
    });
  },
};

//METODO GET AIRTABLE POR DPI
export const requestCandidato = {
  //Metodo para recibir getById
  get: function (candidato) {
    let url = `${HOST}/${DATABASES.CANDIDATE}?filterByFormula=AND({No. DPI o Pasaporte o Licencia}='${candidato}')`;
    //let url  = `${HOST}/${DATABASES.CANDIDATE}`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${APIKEY}`,
      },
    });
  },
};

//Metodo get para recibir informacion de la plaza
export const GetPlaza = {
  get: function (plaza) {
    let url = `https://api.airtable.com/v0/app6HHzP3eNjSeFzo/PUESTO?filterByFormula=AND({Name}='${plaza}')`;
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${APIKEY}`,
      },
    });
  },
};

// //AIRTABLE PATCH AGENDA
export const PatchPapeleria = {
  patch: function (json) {
    let url = `${HOST}/${DATABASES.AGENDA}`;
    return axios.patch(`${url}`, json, {
      headers: {
        Authorization: `Bearer ${APIKEY}`,
        "Content-Type": "application/json",
      },
    });
  },
};

// //AIRTABLE PATCH AGENDA
export const PatchPapeleria_CANDIDATO = {
  patch: function (json) {
    let url = `${HOST}/${DATABASES.CANDIDATE}`;
    return axios.patch(`${url}`, json, {
      headers: {
        Authorization: `Bearer ${APIKEY}`,
        "Content-Type": "application/json",
      },
    });
  },
};

//API NODEMAILER
export const SendMail = {
  post: function (data) {
    return axios.post(
      `https://us-central1-empleosmrb.cloudfunctions.net/api_nodemailer/send-mail`,
      data
    );
  },
};

//API POST JWT USUARIO
export const PostJwt = {
  post: function (data) {
    return axios.post(
      `https://us-central1-empleosmrb.cloudfunctions.net/api_nodemailer/jwt`,
      data
    );
  },
};

//API POST JWT USUARIO
export const PostJwtAdmin = {
  post: function (data) {
    return axios.post(
      `https://us-central1-empleosmrb.cloudfunctions.net/api_nodemailer/jwtAdmin`,
      data
    );
  },
};

//GET VALIDATE JWT
export const GetToken = {
  get: function (data) {
    return axios.get(
      `https://us-central1-empleosmrb.cloudfunctions.net/api_nodemailer/verificar?token=${data}`
    );
  },
};
