export const FETCHING_DATA_AIRTABLE_STARTED = 'FETCHING_DATA_AIRTABLE_STARTED';
export const FETCHING_DATA_AIRTABLE_COMPLETED = 'FETCHING_DATA_AIRTABLE_COMPLETED';
export const FETCHING_DATA_AIRTABLE_FAILED = 'FETCHING_DATA_AIRTABLE_FAILED';

//SEND DATA AIRTABLE AND FIREBASE 

export const CREATE_DATA_AIRTABLE_STARTED = 'CREATE_DATA_AIRTABLE_STARTED';
export const CREATE_DATA_AIRTABLE_COMPLETED = 'CREATE_DATA_AIRTABLE_COMPLETED';
export const CREATE_DATA_AIRTABLE_FAILED = 'CREATE_DATA_AIRTABLE_FAILED'; 


export const FETCHING_DATA_FIREBASE_STARTED = 'FETCHING_DATA_FIREBASE_STARTED';
export const FETCHING_DATA_FIREBASE_COMPLETED = 'FETCHING_DATA_FIREBASE_COMPLETED';
export const FETCHING_DATA_FIREBASE_FAILED = 'FETCHING_DATA_FIREBASE_FAILED';

// UPDATE DATA FIREBASE 

export const UPDATA_DATA_FIREBASE_STARTED = 'UPDATA_DATA_FIREBASE_STARTED';
export const UPDATA_DATA_FIREBASE_COMPLETED = 'UPDATA_DATA_FIREBASE_COMPLETED';
export const UPDATA_DATA_FIREBASE_FAILED = 'UPDATA_DATA_FIREBASE_FAILED';

// UPDATE DATA AIRTABLE

export const UPDATE_AIRTABLE_QUESTION_STARTED = 'UPDATE_AIRTABLE_QUESTION_STARTED';
export const UPDATE_AIRTABLE_QUESTION_COMPLETED = 'UPDATE_AIRTABLE_QUESTION_COMPLETED';
export const UPDATE_AIRTABLE_QUESTION_FAILED = 'UPDATE_AIRTABLE_QUESTION_FAILED';


// UPDATE DATA AIRTABLE QUESTIONS

export const UPDATE_AIRTABLE_POLL_STARTED = 'UPDATE_AIRTABLE_POLL_STARTED';
export const UPDATE_AIRTABLE_POLL_COMPLETED = 'UPDATE_AIRTABLE_POLL_COMPLETED';
export const UPDATE_AIRTABLE_POLL_FAILED = 'UPDATE_AIRTABLE_POLL_FAILED';

// GET DATA AIRTABLE QUESTIONS 

export const GET_AIRTABLE_POLL_STARTED = "GET_AIRTABLE_POLL_STARTED";
export const GET_AIRTABLE_POLL_COMPLETED ="GET_AIRTABLE_POLL_COMPLETED";
export const GET_AIRTABLE_POLL_FAILED = "GET_AIRTABLE_POLL_FAILED";

// GET CANDIDATE QUESTIONS AIRTABLE 

export const FETCH_CANDIDATE_POLL_STARTED = "FETCH_CANDIDATE_POLL_STARTED"
export const FETCH_CANDIDATE_POLL_COMPLETED = "FETCH_CANDIDATE_POLL_COMPLETED"
export const FETCH_CANDIDATE_POLL_FAILED = "FETCH_CANDIDATE_POLL_FAILED"
 
