import { combineReducers } from 'redux'
import  * as types from '../types/job.aplication';

const isFetching = ( state = false, action ) => {
    switch (action.type) {
        case types.FETCHING_GET_JOB_APLICATION_STARTED:{
            return true
        }
        case types.FETCHING_GET_JOB_APLICATION_COMPLETED:{
            return false
        }            
    
        case types.FETCHING_GET_JOB_APLICATION_FAILED:{
            return false
        }
        default:{
            return state 
        }
    }
}


const data  = (state = null, action) =>{
    switch (action.type) {
        case types.FETCHING_GET_JOB_APLICATION_COMPLETED:{
            return action.payload.data
        }
        default:
            {
                return state
            }
    }
}



const error = (state = false, action )=>{
    switch (action.type) {
        case types.FETCHING_GET_JOB_APLICATION_STARTED:{
            return false
        }
         
        case types.FETCHING_GET_JOB_APLICATION_COMPLETED:{
            return false
        }

        case types.FETCHING_GET_JOB_APLICATION_FAILED:{
            return true
        }
        default:{
            return state
        }
    }
}

const postStarted = (state = false, action) =>{
    switch (action.type) {
        case types.POST_JOB_APLICATION_STARTED:{
            return true
        }
        case types.POST_JOB_APLICATION_COMPLETED:{
            return false
        }
        case types.POST_JOB_APLICATION_FAILED:{
            return false
        }
        default:{
            return state
        }
    }
}

const postCompleted = (state = null, action)=>{
    switch(action.type){
        case types.POST_JOB_APLICATION_COMPLETED:{
            return action.payload.request
        }
        default:{
            return state
        }
    }
}

const postError = (state = false, action)=>{
    switch (action.type) {
        case types.POST_JOB_APLICATION_STARTED:{
            return false
        }
            
        case types.POST_JOB_APLICATION_COMPLETED:{
            return false
        }

        case types.POST_JOB_APLICATION_FAILED:{
            return true
        }

        default:{
            return state
        }
    }
}

const job_application = combineReducers({
    isFetching,
    data,
    error,
    postError,
    postCompleted,
    postStarted
})

export default job_application

export const getIsFetchingData = state => state.isFetching;
export const getIsData = state => state.data;
export const getError = state => state.error; 

export const postDataAirtable = state => state.postCompleted;
export const postDataStarted = state => state.postStarted;
export const postDataFailed = state => state.postDataFailed;

