import React, { Fragment ,useEffect} from 'react';
import {  Grid,Button,TextField,Typography} from '@material-ui/core';
import {connect} from 'react-redux'
import * as constants from '../../constants/table';
import * as selectors from '../../logic/reducers';
import * as actions from '../../logic/actions/questions.airtable';
import * as yup from 'yup';
import {Formik} from 'formik'
import {Questions as QuestionModel}  from '../../models'
import swal from 'sweetalert';

const DataQuestions = (params) =>{
    const id = params.questionId
    useEffect(()=>{
        params.fetchCollection(id)
    },[id])

    const schema  = yup.object({
        [constants.modal.question1]: yup.string()
            .optional('INGRESE LA RESPUESTA'),
    });

    let sendInterView = params.props?.data.fields['Status Entrevista:'];
    let model = new QuestionModel(params.props?.data.fields);

    let initialValues = {};
    Object.keys(constants.modal).forEach((key)=>{
        initialValues[constants.modal[key]]=undefined;
    })

    const onClick = () => {
        swal('Las preguntas se han enviado correctamente '+" Nos contactaremos contigo","","success")
    }
    
    return (
        <Fragment>   
            {
                         
            <Formik
                // initialValues = {{
                //     questions: ''
                // }}
                initialValues={initialValues}
                onSubmit = {(values, { setSubmitting }) =>{params.fetchData(values,params.questionId)}}
                validationSchema =  { schema }
            >
                {
                    ({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        errors, 
                        values, 
                        touched
                    }) => (
            
                        <form onSubmit={ handleSubmit } noValidate>
                            {
                            
                            model.getInputs().inputs.filter((element)=>element.title!=undefined).map((input)=>{
                            return(
                                    <div>   
                                    
                                    <Grid
                                            container
                                            direction = 'row'
                                            alignItems="flex-start"
                                            justify="space-around"
                                    >
                                        <Grid
                                            item
                                            lg = { 4 }
                                            md = { 5 }
                                            xs = { 10 }
                                            sm = { 7 } 
                                        >
                                            <Typography 
                                                variant='subtitle1'
                                                style = {{ 
                                                    textAlign: 'justify',
                                                        marginTop: '50px'
                                                    }}
                                            >
                                            {" "+input.title}
                                            </Typography>    
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid
                                        container
                                        direction = 'column'
                                        alignItems = 'center'
                                        alignContent = 'center'
                                        justify = 'center'
                                    >
                                        <Grid
                                            item
                                            lg = { 12 }
                                            md = { 6 }
                                            xs = { 12 }
                                        >
                                            <TextField
                                                key = { input.name }
                                                className = 'textField textFieldGrande'
                                                type = { input.type }
                                                style = {{ padding: '20px'}}
                                                id = { input.id }
                                                multiline
                                                rowsMax = { 4 }
                                                name = { input.id }
                                                variant ='outlined'
                                                value = { values[input.id] }
                                                // error = { errors.questions && touched.questions  }
                                                onChange = { handleChange }
                                                onBlur =  { handleBlur }
                                                helperText = { (errors.questions && touched.questions ) && errors.questions }
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>                                                
                                </div>
                                        )
                                    })
                            }
                                {
                                        <Grid
                                            container
                                            direction = 'column'
                                            alignItems = 'center'
                                            alignContent = 'center'
                                            justify = 'center'
                                        >
                                            <Grid
                                                item
                                                lg = { 12 }
                                                md = { 6 }
                                                xs = { 11 }
                                            >
                                                    <Button 
                                                    type = 'submit'
                                                    variant = 'outlined' 
                                                    fullWidth
                                                    onClick = { onClick }
                                                    style={{ 
                                                        width: '62ch',
                                                        padding: '10px',
                                                        marginBottom: '20px',
                                                        marginTop: '20px',
                                                        backgroundColor: '#000',
                                                        color: '#fff'
                                                    }}
                                                > 
                                                    ENVIAR
                                                </Button>
                                            </Grid>
                                        </Grid>
                                }
                            </form>
                            )
                        }
                            
            </Formik>
            }
    </Fragment>
    )
}


export default connect(
    state=>({
        loading: selectors.patchDataStarted(state),
        completed: selectors.getIsFetchingQuestionFirebaseCompleted(state)
    }),
    dispatch =>({
        fetchData(data,id){
            dispatch(actions.startedCreateData(data,id))
        },

        fetchCollection(id){
            dispatch(actions.startedGetFirebaseQuestions(id))
        }
    })
)(DataQuestions); 