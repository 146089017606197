import { GetPlaza, GetToken } from "../../../helpers/candidato.data";

export const Plazas = async (plaza) => {
  var descripcion = [];
  console.log(plaza);
  let response = await GetPlaza.get(plaza);
  let obj = response.data.records[0].fields;
  let tipo = "DESCRIPCION (from PAPELERIA)";
  let tipo2 = "OBSERVACIONES (from PAPELERIA)";
  let Descripciones = obj[tipo];
  let Observaciones = obj[tipo2];
  var cont = 0;
  if (typeof Descripciones !== "undefined") {
    Descripciones.forEach((element) => {
      let tmp = { tipo: element, detalle: Observaciones[cont] };
      descripcion.push(tmp);
      cont++;
    });
  }

  return descripcion;
};

export const DatosPlaza = async (plaza) => {
  let response = await GetPlaza.get(plaza);
  let objt = response.data.records[0].fields;
  let ruta = "DESCRIPCION (from PAPELERIA)";
  let nombre = objt[ruta];
  return nombre;
};

export const ValidarToken = async () => {
  let Token = JSON.parse(localStorage.getItem(`Token`));
  let condicion = false;
  let ID = "";
  if (Token !== null) {
    let res = await GetToken.get(Token.Token.token);
    condicion = res.data.error;
    ID = res.data.data.id;
  }
  return { bandera: condicion, id: ID };
};
