import './navbar.css'

const Nav_bar = (props) => {
    const {condicion} = props
    return (
        <div>
            <style>
            @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
            </style>
            {!condicion ?
            <div>
                <img className="Logo"src="https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2Fpapersboxgt-logo-1.PNG?alt=media&token=dfafd01c-df27-4dbd-bed5-7a273b590f0b"/>
                <hr className="Separador"/>
                <img className = "Titulo" src="https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2Ftitulo.PNG?alt=media&token=e3c5a4da-4726-4869-90e8-46f353239783"/>
                <hr className="Resaltador"/>
                <p className="instrucciones">Por favor ingresa utilizando tu número de identificación personal (dpi, pasaporte, etc).</p>
                </div>
                : 
                <div>
                <img className="Logo"src="https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2Fpapersboxgt-logo-1.PNG?alt=media&token=dfafd01c-df27-4dbd-bed5-7a273b590f0b"/>
                <hr className="Separador"/>
                <span className="Mensaje">
                Es necesario cargar los documentos que abajo se indican, recuerda que hay fecha límite para realizarlo.
                    </span>
                <br/>
                </div>
                }
        </div>
    )
            }
            
export default Nav_bar
