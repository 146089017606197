import axios from 'axios'
import * as constants from '../constants'
import moment from 'moment';
import {firebaseStorage} from '../config/config'
import { JobAplicationData as JobModelData } from '../models'

export const postDataJob = async(request,file,fileDescriptor) => {

    const telefono = request.telefono 
    const newTel = telefono === undefined ?"":telefono.toString()
    const date = moment(request.Nacimiento).format('L')
    var storageRef = firebaseStorage.ref()
    await storageRef.child(`${request.fotoPerfil.type === 'image/png'?`imagenes/${request.fotoPerfil.name}`:`imagenes/${request.fotoPerfil.name}`}`).put(request.fotoPerfil)
    await storageRef.child(`pdf/${request.CV.name}`).put(request.CV)

    var startRef = await storageRef.child(`imagenes/${request.fotoPerfil.name}`)
    var getUrl = await startRef.getDownloadURL().then(async(url)=>{
       return await url
    }).catch((error)=>{
      console.error(error)
    })
    
    var startRefCV = await storageRef.child(`pdf/${request.CV.name}`)
    var getUrlCV = await startRefCV.getDownloadURL().then(async(url)=>{
       return await url
    }).catch((error)=>{
      console.error(error)
    })

    let body = {
      cv : getUrlCV,
      photo : getUrl,
      date : date, 
      Telefono : newTel
    }

    const modal = new JobModelData(request,body);
 
    try{

      let updateData = await axios({
        url: constants.AirtableConstants.values.post_data.url,
        method: 'post',
        headers: constants.AirtableConstants.values.post_data.headers,
        data: {"records":[modal.toJson()]}
      })
      
      console.log(body)
     return updateData.data

      }catch(error){
        console.error(error);
      }
}

