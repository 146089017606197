import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../store';
import { PersistGate } from "redux-persist/integration/react";
import {
    BrowserRouter as Router,
    Switch, 
    Route,
    Redirect
} from 'react-router-dom';
import JobAplication from '../components/jobApp';
import MrbInterview from '../components/questionary.js';
import Qualification from '../components/qualificationInterview';
import Pruebas from '../components/pruebas';
import General from '../components/GeneralReclutador/Index'
import PapeleriaCandidato from '../components/GeneralCandidato/Index'
import Prueba from '../components/GeneralCandidato/ValidarArchivos/prueba'
import SatisfactionRating from '../components/satisfactionRating';

export const { store, persistor } = configureStore();

const App = () => {
    return(
        <Provider store={store}>
            <Router>
                <PersistGate loading={null} persistor={persistor}>
                    <Switch>
                        <Redirect exact  from="/" to="/Papeleria"/>
                        <Route path="/Solicitud/Empleo" component={JobAplication} />
                        <Route path="/Entrevista/:id" component={MrbInterview}/>
                        <Route path="/MRB/Calficacion/:id" component = {Qualification}/>
                        <Route path="/pruebas" component = {Pruebas}/>
                        <Route path="/RevisionPapeleria" component = {General}/>                                               
                        <Route path="/pruebas" component = {Pruebas}/>
                        <Route path="/Solicitud/Empleo" component={JobAplication} />
                        <Route path="/Entrevista/:id" component={MrbInterview}/>
                        <Route path="/MRB/Calficacion/:id" component = {Qualification}/>
                        <Route path="RevisionPapeleria/:id" component = {General}/>
                        <Route path="/Papeleria" component = {PapeleriaCandidato}/>                        
                        <Route path="/PRUEBA" component = {Prueba}/>  
                        <Route path="/rating/:id" component = {SatisfactionRating}/>
                    </Switch>
                </PersistGate>
            </Router>
        </Provider>
    )
}

export default App 

