import * as constants from '../constants/table';

export default class Responses{
    constructor(props){
        this.question1=props['PREGUNTA I (from Prueba)'];
        this.question2=props['PREGUNTA 2 (from Prueba)'];
        this.question3=props['PREGUNTA 3 (from Prueba)'];
        this.question4=props['PREGUNTA 4 (from Prueba)'];
        this.question5=props['PREGUNTA 5 (from Prueba)'];
        this.question6=props['PREGUNTA 6 (from Prueba)'];
        this.question7=props['PREGUNTA 7 (from Prueba)'];
        this.question8=props['PREGUNTA 8 (from Prueba)'];
        this.question9=props['PREGUNTA 9 (from Prueba)'];
        this.question10=props['PREGUNTA 10 (from Prueba)'];
        this.question11=props['PREGUNTA 11 (from Prueba)'];
        this.question12=props['PREGUNTA 12 (from Prueba)'];
        this.question13=props['PREGUNTA 13 (from Prueba)'];
        this.question14=props['PREGUNTA 14 (from Prueba)'];
        this.question15=props['PREGUNTA 15 (from Prueba)'];
        this.response1=props['RESPUESTA 1'];
        this.response2=props['RESPUESTA 2'];
        this.response3=props['RESPUESTA 3'];
        this.response4=props['RESPUESTA 4'];
        this.response5=props['RESPUESTA 5'];
        this.response6=props['RESPUESTA 6'];
        this.response7=props['RESPUESTA 7'];
        this.response8=props['RESPUESTA 8'];
        this.response9=props['RESPUESTA 9'];
        this.response10=props['RESPUESTA 10'];
        this.response11=props['RESPUESTA 11'];
        this.response12=props['RESPUESTA 12'];
        this.response13=props['RESPUESTA 13'];
        this.response14=props['RESPUESTA 14'];
        this.response15=props['RESPUESTA 15'];

    }
    getInputsResponse(){
        return {
            "inputs":[
                {
                    id:constants.modal.question1,
                    title:this.question1!=undefined && this.question1!=null?this.question1[0]:"",
                    name:constants.questionNames.question1,
                    result:this.response1!=undefined?this.response1:undefined,
                },
                {
                    id:constants.modal.question2,
                    title:this.question2!=undefined?this.question2[0]:undefined,
                    name:constants.questionNames.question2,
                    result:this.response2!=undefined?this.response2:undefined,
                },
                {
                    id:constants.modal.question3,
                    title:this.question3!=undefined?this.question3[0]:undefined,
                    name:constants.questionNames.question3,
                    result:this.response3!=undefined?this.response3:undefined,
                },
                {
                    id:constants.modal.question4,
                    title:this.question4!=undefined?this.question4[0]:undefined,
                    name:constants.questionNames.question4,
                    result:this.response4!=undefined?this.response4:undefined,
                },
                {
                    id:constants.modal.question5,
                    title:this.question5!=undefined?this.question5[0]:undefined,
                    name:constants.questionNames.question5,
                    result:this.response5!=undefined?this.response5:undefined,
                },
                {
                    id:constants.modal.question6,
                    title:this.question6!=undefined?this.question6[0]:undefined,
                    name:constants.questionNames.question6,
                    result:this.response6!=undefined?this.response6:undefined,
                },
                {
                    id:constants.modal.question7,
                    title:this.question7!=undefined?this.question7:undefined,
                    name:constants.questionNames.question7,
                    result:this.response7!=undefined?this.response7:undefined,
                },
                {
                    id:constants.modal.question8,
                    title:this.question8!=undefined?this.question8[0]:undefined,
                    name:constants.questionNames.question8,
                    result:this.response8!=undefined?this.response8:undefined,
                },
                {
                    id:constants.modal.question9,
                    title:this.question9!=undefined?this.question9[0]:undefined,
                    name:constants.questionNames.question9,
                    result:this.response9!=undefined?this.response9:undefined,
                },
                {
                    id:constants.modal.question10,
                    title:this.question10!=undefined?this.question10[0]:undefined,
                    name:constants.questionNames.question10,
                    result:this.response10!=undefined?this.response10:undefined,
                },
                {
                    id:constants.modal.question11,
                    title:this.question11!=undefined?this.question11[0]:undefined,
                    name:constants.questionNames.question11,
                    result:this.response11!=undefined?this.response11:undefined,
                },
                {
                    id:constants.modal.question12,
                    title:this.question12!=undefined?this.question12[0]:undefined,
                    name:constants.questionNames.question12,
                    result:this.response12!=undefined?this.response12:undefined,
                },
                {
                    id:constants.modal.question13,
                    title:this.question13!=undefined?this.question13[0]:undefined,
                    name:constants.questionNames.question13,
                    result:this.response13!=undefined?this.response13:undefined,
                },
                {
                    id:constants.modal.question14,
                    title:this.question14!=undefined?this.question14[0]:undefined,
                    name:constants.questionNames.question14,
                    result:this.response14!=undefined?this.response14:undefined,
                },
                {
                    id:constants.modal.question15,
                    title:this.question15!=undefined?this.question15:undefined,
                    name:constants.questionNames.question15,
                    result:this.response15!=undefined?this.response15:undefined,
                },
            ],
        }
    }
}