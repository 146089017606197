export const values = {
   "post":
        {
            url: 'https://api.airtable.com/v0/app2tSIDsOpffig85/Respuestas.E_Respuestas',
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer keycnxGOFUAfvAOcc'
            }
        },
    "get":
    {        
        method: 'get',
        url: `https://api.airtable.com/v0/app2tSIDsOpffig85/Entrevistas.E_Preguntas`,
        headers: { 
            'Authorization': 'Bearer keycnxGOFUAfvAOcc'
        }
    },
    "post_data":{
        url: 'https://api.airtable.com/v0/app2tSIDsOpffig85/Solicitud%20de%20Trabajo',
        method: 'post',
        headers: {
          'Authorization': 'Bearer keycnxGOFUAfvAOcc'
        },
    },

    "ROUTES":{
        JOB_APPLICATION_PRINCIPAL: '/Solicitud/Empleo/MRB',
        IMG_MRB2: 'https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2F8adfd7f8.png?alt=media&token=24a244dc-8d9f-4939-a010-8df0c1716a14',
        IMG_MRB: 'https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2Flogo_mrb.png?alt=media&token=9e4cbba8-383c-4056-b137-cdaf6f105a76',
        IMG_FONDO: 'https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2Fphoto-1542744173-8e7e53415bb0.jfif?alt=media&token=4f8812e3-6896-420a-a734-ade7b7607853',
        IMG_SEND: 'https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/imagenes%2Fimagenes%2FWeb%201920%20%E2%80%93%201.svg?alt=media&token=2f440265-95d1-486c-a209-a5c60c601b1f',
    }
}