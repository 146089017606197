import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { AssignmentOutlined, Search } from "@material-ui/icons";
import "../navbar/Estilos.css";
import { request } from "../../../helpers/candidato.data.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GetPlaza } from "../../../helpers/candidato.data";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./Estilos_Inicio.css";
import { useLocation } from "react-router-dom";
import GridTable from "../GridTable/index";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "78vh",
  },
  paper: {
    margin: theme.spacing(6, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#2577DF",
    color: "white",
    fontFamily: "Roboto sans-serif",
    fontWeight: "500",
  },
}));

const BuscarCandidato = () => {
  const classes = useStyles();
  const [Nombre, SetNombre] = useState("");
  const [DPI, SetDpi] = useState("");
  const [Telefono, SetTelefono] = useState("");
  const [Email, SetEmail] = useState("");
  const [paso, setPaso] = useState("Buscar Candidato");
  const [bandera, SetBandera] = useState(false);
  const [loading, Setloading] = useState(false);
  const [DataPapeleria, SetDataPapeleria] = useState([]);
  const [TiposPapeleria, SetTiposPapeleria] = useState([]);
  const [ID_Usuario, setID] = useState("");
  const [Commentarios, setComentarios] = useState("");
  const [Estados, setEstados] = useState("");
  const [Asignado, setAsignado] = useState([]);
  const [BanderaFinal, setBanderaFinal] = useState(true);
  const [DataSetUsuario, SetDataSetUsuario] = useState([]);
  const [Porcentaje, SetPorcentaje] = useState(0);
  const [INFORNET, SetInfornet] = useState("");
  const [PapeleriaTipos, SetPapeleriaTipos] = useState([]);
  const [INFORNATEDATA, SetINFORNATEDATA] = useState([]);
  const [HUELLAS, SetHuellas] = useState([]);
  const [MUESTRAS, SetMuestras] = useState([]);
  const [FotoPerfil, setFotoPerfil] = useState("");
  const [DatosPrueba, setDatosPrueba] = useState([]);
  const [Registros, setRegistros] = useState("");
  const [RegistroCarga, setRegistroCarga] = useState("");
  const [Mensajes, SetMensajes] = useState("");
  const [ID_Candidato, setIDCandidato] = useState('');
  const search = useLocation().search;
  const ID = new URLSearchParams(search).get("id");

  const GuardarData = (data) => {
    SetDataSetUsuario(data);
  };

  const CalcularPorcentajeAprobado = (data) => {
    var contador = 0;
    for (var i = 0; i < data.length; i++) {
      var tmp = data[i].Status;
      if (tmp === "Aceptado" || tmp === "NoAplica" || tmp === "No Aplica") {
        contador += 1;
      }
    }

    if (contador > 0) {
      var total = Math.round((contador / data.length) * 100);
      SetPorcentaje(total);
    } else {
      SetPorcentaje(0);
    }
  };

  const Plazas = async (plaza) => {
    let response = await GetPlaza.get(plaza);
    let objt = response.data.records[0].fields;
    let ruta = "DESCRIPCION (from PAPELERIA)";
    let nombre = objt[ruta];
    return nombre;
  };

  const PlazasTipos = async (plaza) => {
    let response = await GetPlaza.get(plaza);
    let objt = response.data.records[0].fields;
    let ruta = "TIPO_PAPELERIA (from PAPELERIA)";
    let tipo = objt[ruta];
    return tipo;
  };

  const BuscarIdentificador = async (id_usuario) => {
    SetDataPapeleria([]);
    SetPorcentaje(0);

    SetBandera(false);
    Setloading(true);

    let Entrada = "";

    if (id_usuario !== undefined) {
      Entrada = id_usuario;
    } else {
      Entrada = DPI;
    }

    try {
      request
        .get(Entrada)
        .then(async (Response) => {
          if (Response.data.records.length > 0) {
            SetBandera(true);
            SetTelefono(Response.data.records[0].fields["TELEFONO (from CANDIDATOS)"]);
            SetEmail(Response.data.records[0].fields["CORREO"]);
            SetNombre(Response.data.records[0].fields["NOMBRE (from CANDIDATOS)"]);
            setID(Response.data.records[0].id);
            setPaso("");
            setIDCandidato(Response.data.records[0].fields["ID (from CANDIDATOS) 2"]);

            let nombre = Response.data.records[0].fields["NOMBRE (from CANDIDATOS)"];
            let Estado = Response.data.records[0].fields["ESTADO_GOLDEN_PAPELERÍA"];
            let Comentarios = Response.data.records[0].fields["COMENTARIOS_PAPELERIA"];
            let Papeleria = Response.data.records[0].fields["PAPELERIA"];
            let Reclutador = Response.data.records[0].fields["ASIGNADA_"];
            let Registro = Response.data.records[0].fields["REGISTRO_REVISION_PAPELERIA"];
            setRegistros(Registro);                        
            let PuestoPlaza = Response.data.records[0].fields["PLAZA QUE APLICA_"];
            let Information = await Plazas(PuestoPlaza);
            let TiposArchivos = await PlazasTipos(PuestoPlaza);
            let Correo = Response.data.records[0].fields["CORREO ELECTRONICO (from CANDIDATOS)"];
            SetPapeleriaTipos(TiposArchivos);            
            let Infornet = Response.data.records[0].fields["INFORNET Y FORMULARIO"];            
            let PhotoPerfil = Response.data.records[0].fields["FOTO PERFIL (from CANDIDATOS)"];
            setFotoPerfil(PhotoPerfil);
            let Huellas = Response.data.records[0].fields["Golden - Ficha de toma de huellas dactilares"];
            let Muestra = Response.data.records[0].fields["Golden - Ficha de Muestra Grafotécnica"];

            SetHuellas(Huellas);
            SetMuestras(Muestra);

            SetInfornet(Infornet);
            let Data2 = { Nombre: nombre, Reclutador: Reclutador };
            SetINFORNATEDATA(Data2);
            let Data = {
              reclutador: Reclutador[0],
              to: Correo,
              candidato: nombre,
              plaza: PuestoPlaza,
            };
            setAsignado(Data);

            SetDataPapeleria(Papeleria);
            SetTiposPapeleria(Information);
            setComentarios(Comentarios);
            setEstados(Estado);
            setAsignado(Data);

            let NuevosMensajes =
              Response.data.records[0].fields.COMENTARIOS_PAPELERIA;
            SetMensajes(NuevosMensajes);

            var datos = [];
            var validar = [];

            let Registro_Carga =
              Response.data.records[0].fields.REGISTRO_CARGAR_PAPELERIA;
            setRegistroCarga(Registro_Carga);

            //inicia aqui condicion que valida si el usuario ya finalizado su papelería
            if (Information != null) {
              for (let i = 0; i < Information.length; i++) {
                let tmpComentario = "";
                let tmpEstado = "";
                let tmpPapeleriaId = "";
                let tmpPapeleriaIdurl = "";
                //Obtener papelería
                if (Papeleria != null) {
                  for (let e = 0; Papeleria.length; e++) {
                    let tmp = Papeleria[0].filename;
                    if (tmp.includes(Information[i])) {
                      tmpPapeleriaId = Papeleria[0].id;
                      tmpPapeleriaIdurl = Papeleria[0].url;
                    }
                    break;
                  }
                }

                //Obtener estado de archivo tipo i
                if (Estado != null) {
                  let tmp1 = Estado.split("],");
                  for (let j = 0; j < tmp1.length; j++) {
                    let tmp2 = tmp1[j].replace("[", "").split(":");
                    if (tmp2[0] === Information[i]) {
                      tmpEstado = tmp2[1].replace(" ", "");
                      break;
                    }
                  }
                }

                //Obtener comentarios de archivo tipo i
                if (Comentarios != null) {
                  let tmp1 = Comentarios.split("],");
                  for (let j = 0; j < tmp1.length; j++) {
                    var tmp2 = tmp1[j].replace("[", "").split(":");
                    if (tmp2[0] === Information[i]) {
                      tmpComentario = tmp2[1];
                      break;
                    }
                  }
                }
                if (tmpEstado.length === 0) {
                  tmpEstado = "Pendiente";
                }
                let myarray = {
                  tipo: Information[i],
                  Status: tmpEstado,
                  comment: tmpComentario,
                  url: tmpPapeleriaIdurl,
                  idarchivo: tmpPapeleriaId,
                };
                if (tmpEstado === "NoAplica") {
                  tmpEstado = "No Aplica";
                }
                let tmp = {
                  Documento: Information[i],
                  Status: tmpEstado,
                };
                datos.push(tmp);
                validar.push(myarray);
              }
              GuardarData(datos);
              setDatosPrueba(validar);
              CalcularPorcentajeAprobado(validar);
            }
            //Finaliza aqui condicion
            else {
              //Pendiente por programar
            }
          } else {
            setPaso("Usuario no encontrado");
          }
          Setloading(false);
        })
        .catch((err) => {
          console.error(err);
          Setloading(false);
        });
    } catch (e) {}
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      BuscarIdentificador();
    }
  }

  useEffect(() => {
    SetDpi(ID);
    if (ID !== null) {
      BuscarIdentificador(ID);
    }
  }, []);

  return (
    <div>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
      </style>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <hr />
      <hr />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          label="Documento de identificación"
          // placeholder="Documento de identificación"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {" "}
                <Search
                  onClick={() => BuscarIdentificador()}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            SetDpi(e.target.value);
          }}
          className="DocumentoIdentificacion"
          onKeyDown={keyPress}
          value={DPI}
        />
      </div>

      {/* <Container component="main" maxWidth="xs">      */}
      {BanderaFinal && DataSetUsuario.length > 0 ? (
        <GridTable
          bandera={bandera}
          Nombre={Nombre}
          status={paso}
          datos={DataSetUsuario}
          DataPapeleria={DataPapeleria}
          TiposPapeleria={TiposPapeleria}
          ID_Usuario={ID_Usuario}
          Email={Email}
          Estados={Estados}
          Commentarios={Commentarios}
          Porcentaje={Porcentaje}
          Reclutador={Asignado}
          INFORNET={INFORNET}
          PapeleriaTipos={PapeleriaTipos}
          INFORNATEDATA={INFORNATEDATA}
          DPI={DPI}
          FotoPerfil={FotoPerfil}
          HUELLAS={HUELLAS}
          MUESTRAS={MUESTRAS}
          SetDataSetUsuario={SetDataSetUsuario}
          CalcularPorcentajeAprobado={CalcularPorcentajeAprobado}
          DatosPrueba={DatosPrueba}
          setEstados={setEstados}
          setComentarios={setComentarios}
          SetDataPapeleria={SetDataPapeleria}
          Registros={Registros}
          setRegistros={setRegistros}
          RegistroCarga={RegistroCarga}
          Mensajes={Mensajes}
          SetMensajes={SetMensajes}
          BuscarIdentificador={BuscarIdentificador}
          ID_Candidato={ID_Candidato}
        />
      ) : (
        <div>
          <hr />
          <p style={{ display: "flex", justifyContent: "center" }}>
            No hay datos disponibles para este usuario.
          </p>
        </div>
      )}
      {/* </Container> */}
      <hr></hr>
    </div>
  );
};

export default BuscarCandidato;
