import React, {useState} from 'react'
import SaveIcon from '@material-ui/icons/Save';
import {PatchPapeleria,SendMail} from '../../../helpers/candidato.data.js'
import {Alert} from '@material-ui/lab';
import {Snackbar,Button} from '@material-ui/core';
import '../../../Spinner/loader.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import PapeleriaCargada from './Metodos'
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import {RegistroRevision} from '../../Registro_Data/metodos'

const Actualizar = (props) => {
    const {tipo,datos,SetDataSetUsuario,TiposPapeleria,Commentarios,ID_Usuario,
        DataPapeleria,Email,Estados,Porcentaje,Reclutador,CalcularPorcentajeAprobado,DatosPrueba,        
        setEstados,setComentarios, Registros,setRegistros
        ,SetMensajes,BuscarIdentificador,DPI
    } = props

    const [banderaFile, setbanderaFile] = useState(null)
    const [banderaFileFalse, setbanderaFileFalse] = useState(null)
    const [Mensaje,setMensaje] = useState("")
    const [Loading, setLoading] = React.useState(false);
    const [bandera, setBandera] = useState(false)    
    const [open, setOpen] = React.useState(false);
    const [opensnack, setOpensnack] = React.useState(false);
    const [banderaComentario, setBanderaComentario] = React.useState(false)

    function ActualizarData(){        
        handleSend()
        ActualizarEstado()
        CalcularPorcentajeAprobado(datos)
    }

    const handleLoading = () => {
        setLoading(!Loading);
      }; 

    const handleSend = () => {            
        handleLoading()
        var tmp = "";    
        var tmp2 = "";
       
      for(let i =0; i< TiposPapeleria.length;i++)
      {              
        let estado = JSON.parse(localStorage.getItem(`Estado${TiposPapeleria[i]}`))                      
        if(estado !== null)
        {      
        tmp2 += `[${TiposPapeleria[i]}:${estado.estado}],`         
        }       
      }  
  
      let Mensajes_Actual = JSON.parse(localStorage.getItem(`Mensaje`))                      
      
      if(Mensajes_Actual !== null)
      {
        tmp = Mensajes_Actual.Comentario
      }
  
      if(Estados !=null)
      {if(tmp2.length === 0) { tmp2 = Estados}else{      
        tmp2= ValidaExistencia(tmp2,Estados)}         
      }         
  
  
      //tmp son comentarios y tmp son estados
      let t =ValidarArray(tmp,tmp2)       
      if(t === "No")
      {
      alert("No se ha detectado ningún cambio.")
      setLoading(false);
      }

      else
      {              
        let Tmp2 = [{id:ID_Usuario,fields : t}]
        let tmp3 = {records : Tmp2}
        let tmp4 = JSON.stringify(tmp3)       
        console.log(tmp4);
        PatchPapeleria.patch(tmp4).then( response => {        
          if(response.status === 200)
          {                  
            localStorage.removeItem(`Estado${tipo}`);
            localStorage.removeItem(`Mensaje`);
            setbanderaFile(true)
            handleCloseP()               
          }
          else{
            setbanderaFileFalse(true)
            handleCloseP()
            setMensaje("")
          }
        }).catch(err => 
                  {                    
                      console.error(err)
                      setbanderaFileFalse(true)
                      handleCloseP()
                  })
          
        setBandera(true)          
        //window.location.href = "/MRB/RevisionPapeleria"
      }                        
    }

    const handleCloseP = () => {
        setLoading(false);
      };    


    function ValidaExistencia (tmp,Tipos){
        var tmp2 = Tipos.split(",")
         var newValue = tmp
        for(let i = 0;i < tmp2.length;i++){
          let tmp3 =  tmp2[i].replace("[","").replace("]","")
          let tmp4 = tmp3.split(":")  
          let bandera = ValidarSplit(tmp,tmp4[0])  
          if(!bandera)        {
            newValue += tmp2[i] + ","
          }
        }
        return newValue;
      }
      
      function ValidarSplit(tmp,tipo){
        var tmp2 = tmp.split(",")
        var TiposExistentes = []
        var bandera = false
        for(let i = 0;i < tmp2.length;i++){
          let tmp3 =  tmp2[i].replace("[","").replace("]","")
          let tmp4 = tmp3.split(":")
          TiposExistentes.push(tmp4[0])    
        } 
    
        if(TiposExistentes.includes(tipo))  {
          bandera = true;
        } 
    
        return bandera;
      }
    
      function ValidarArray(tmp1,tmp2)
      {         
        let t;
        let Papeleria = PapeleriaCargada(datos,TiposPapeleria)
        let Registro = RegistroRevision(Registros,tipo)            
        if(tmp1.length>0 && tmp2.length>0)
        {          
          if(!tmp2.endsWith(",")) {tmp2 += ","}
           t = { 
            "COMENTARIOS_PAPELERIA": tmp1,           
             "ESTADO_GOLDEN_PAPELERÍA": tmp2,
             "Papelería_Cargada paperbox"  : Papeleria.cargados,
             "Papelería que falta:": Papeleria.pendientes,
             "REGISTRO_CARGAR_PAPELERIA" : Registro
            }        
            SetMensajes(tmp1)
            setEstados(tmp2)
            setRegistros(Registro)  
            setBanderaComentario(true)           
          return t                
        }
        else if(tmp1.length === 0 && tmp2.length > 0)
        {
          if(!tmp2.endsWith(",")) {tmp2 += ","}
           t = {       
            "ESTADO_GOLDEN_PAPELERÍA": tmp2  ,
            "Papelería_Cargada paperbox"  : Papeleria.cargados,
            "Papelería que falta:": Papeleria.pendientes,
            "REGISTRO_REVISION_PAPELERIA" : Registro    
            }                  
            setEstados(tmp2)
            setRegistros(Registro)       
          return t
        } 
        else if(tmp1.length > 0 && tmp2.length === 0)
        {          
          t = {       
            "COMENTARIOS_PAPELERIA": tmp1 ,
            "Papelería_Cargada paperbox"  : Papeleria.cargados,
            "Papelería que falta:": Papeleria.pendientes,
            "REGISTRO_REVISION_PAPELERIA" : Registro    
            }   
            SetMensajes(tmp1) 
            setRegistros(Registro) 
            setBanderaComentario(true)      
          return t
        }      
        else{
          return "No"
        }
      }
  
    function ActualizarEstado(){
        let data = JSON.parse(localStorage.getItem(`Estado${tipo}`))        
        if(data !== null){
            let estado = data.estado;
            
            let index= 0;
            let newData = undefined;
            
        for(let i = 0; i < datos.length;i++){          
            if(tipo === datos[i].Documento){ 
                index = i;               
                break;             
            }
        }  
        
        newData = datos[index]   
        newData["Status"] = estado
        datos[index] = newData         
        SetDataSetUsuario([...datos])
        }
        else
        {
          let index= 0;
          let newData = undefined;
          newData = datos[index]   
          newData["Status"] = newData.Status
          datos[index] = newData         
          SetDataSetUsuario([...datos])
        }
    }

    const handleClose1 = () => {
        setOpen(false);
        setbanderaFile(false);
      };

      const handleClosedd = () => {
        setOpen(false);
        setbanderaFile(false);
      };

      const handleClosed = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpensnack(false);
      };


    return (
        <div>
               {
      Loading ? 
      <div className="loading-backdrop">
      <CircularProgress color="primary" />
      </div>
       : null
    }
            <Snackbar open={banderaFile} autoHideDuration={2000} onClose={handleClose1}>
          <Alert variant="filled" severity="success" onClose={handleClose1} >
          Los cambios realizados se han subidos correctamente.
          </Alert>
          </Snackbar>          
        
          <Snackbar open={banderaFileFalse} autoHideDuration={2000} onClose={handleClosedd}>
          <Alert variant="filled" severity="error" onClose={handleClosed} >
          Error al almancenar los cambios realizados, pruebe de nuevo por favor.
          </Alert>
          </Snackbar>

          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Actualizar datos</Tooltip>}>
            <Button
             style={{margin:"auto"}}
            onClick={() =>ActualizarData()}
            >
            <SaveIcon 
            style={{color:"black"}}/>
            </Button>
          </OverlayTrigger>

        </div>
    )
}

export default Actualizar