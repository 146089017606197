'use strict'
export default class JobAplication{
    constructor(props,body){
        this.CV = props.CV;
        this.Departamento = props.Departamento === undefined ? " " : props.Departamento;
        this.Direccion = props.Direccion === undefined ? " " : props.Direccion;
        this.EstadoCivil = props.EstadoCivil;
        this.EstudiosUniversitarios  = props.EstudiosUniversitarios;
        this.NombreTelefonoEmergencia = props.NombreTelefonoEmergencia;
        this.PrestasionSalarial = props.PrestasionSalarial;
        this.TelefonoEmergencia = props.TelefonoEmergencia;
        this.TipoDeDocumento = props.TipoDeDocumento;
        this.correoElectronico = props.correoElectronico;
        this.dateNacimiento = props.dateNacimiento;
        this.fotoPerfil = props.fotoPerfil;
        this.name = props.name;
        this.numberIdentification = props.numberIdentification;
        this.plazaQueAplica = props.plazaQueAplica;
        this.telefono = props.telefono;
        this.zona = props.zona;
        this.urlCv = body.cv;
        this.urlPhoto = body.photo;
        this.date = body.date;
        this.tel = body.Telefono;
    }
    toJson(){
        return {
            "fields":{
                "Nombre Completo": this.name,
                "Fecha de Nacimiento": this.date,
                "No. Identificación": this.numberIdentification,
                "Tipo de Documento Identificación": this.TipoDeDocumento,
                "Telefóno": this.tel,
                "PUESTO QUE APLICA": this.plazaQueAplica,
                "Correo Electrónico": this.correoElectronico,
                "Pretension Salarial": this.PrestasionSalarial,
                "DEPARTAMENTO": this.Departamento,
                "TELEFONO DE EMERGENCIA": this.TelefonoEmergencia,
                "NOMBRE Y TELEFONO CONTACTO DE EMERGENCIA":this.NombreTelefonoEmergencia,
                "DIRECCION": this.Direccion,
                "ZONA": this.zona,
                "ESTUDIOS UNIVERSITARIOS": this.EstudiosUniversitarios,
                "ESTADO CIVIL": this.EstadoCivil,
                "CV":[
                    {
                      url : this.urlCv
                    }
                   ],
                   "Foto de Perfil": [
                     {
                       url: this.urlPhoto
                     }
                   ],
                }   
            }
        }
    }