import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PublishSharpIcon from '@material-ui/icons/PublishSharp';
import '../Papeleria/components.css'
import ReactTour from '../Tour/ReactTour'
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

   
export default function Prueba() {
  const classes = useStyles();
  const [isTourOpen,setOpenTour] = React.useState(false)
  const [isShowingMore, setOpenIsShowingMore] = React.useState(false)

  return (
    <div className={classes.root}>      
     
    </div>
  );
}