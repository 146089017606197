import React, { useEffect } from "react";
import FormSatisfaccion from "./formSatisfaccion";
import "../../index.css";
import { Typography, AppBar, Toolbar } from "@material-ui/core";
import { img } from "../../constants/questions";
import { connect } from "react-redux";
import * as actions from "../../logic/actions/questions.airtable";
import * as selectors from "../../logic/reducers";
import { useParams } from "react-router";
import { CandidatePoll as CandidateModel } from "../../models";

const GeneralForm = ({
  fetchingCandidatePollAgenda,
  isCompletedFetchCandidate,
  isFailedFetchCandidate,
  isLoadingFetchCandidate,
}) => {
  const { id } = useParams;
  console.log(id)
  useEffect(() => {
    fetchingCandidatePollAgenda(id);
  }, [id, fetchingCandidatePollAgenda]);

  const model = new CandidateModel(isCompletedFetchCandidate);
  const empresa = model.toJson().imagen;

  console.log(isCompletedFetchCandidate)
  return (
    <div>
      <div>
        {empresa === "GRUPO MRB" ? (
          <AppBar
            style={{
              position: "relative",
            }}
          >
            <Toolbar>
              {
                <img
                  src={img.imgMrb}
                  style={{
                    width: "150px",
                    height: "auto",
                    position: "relative",
                  }}
                  alt="mrb logo"
                />
              }
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "13px",
                  fontStyle: "oblique",
                  paddingTop: "50px",
                }}
              >
                POWER BY GOLDEN
              </Typography>
            </Toolbar>
          </AppBar>
        ) : (
          <div>
            <AppBar
              style={{
                position: "relative",
                backgroundColor: '#85C1E9'
              }}
            >
              <Toolbar>
                {
                  <div>
                    <img
                      src={img.imgGolden}
                      style={{
                        width: "180px",
                        height: "auto",
                        position: "relative",
                      }}
                      alt="mrb logo"
                    />
                  </div>
                }
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "13px",
                    fontStyle: "oblique",
                    paddingTop: "50px",
                  }}
                >
                  POWER BY GOLDEN
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
        )}
      </div>
      {/* FORMULARIO */}
      <FormSatisfaccion />
      <Typography
        style={{
          paddingTop: "20px",
          backgroundColor: "#F4F6F6",
          textAlign: "center",
          right: 0,
          left: 0,
          bottom: 0,
          position: "fixed",
        }}
      >
        Copyright © QubitSystems 2021.
      </Typography>
    </div>
  );
};

export default connect(
  (state) => ({
    //fetching candidate agenda
    isLoadingFetchCandidate: selectors.getPollCandidateStarted(state),
    isCompletedFetchCandidate: selectors.getPOllCandidateCompleted(state),
    isFailedFetchCandidate: selectors.getPollCanidateFailed(state),
  }),
  (dispatch) => ({
    fetchingCandidatePollAgenda(id) {
      dispatch(actions.startedFetchingCandidatePoll(id));
    },
  })
)(GeneralForm);
