import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { request } from "../../../helpers/candidato.data.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Plazas, DatosPlaza, ValidarToken } from "../Prompts/Plazas";
import PrincipalCard from "./PrincipalCard";
import Nav_bar from "../navbar/navbar.js";
import "./components.css";
import { Button as Buttons } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "white",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Inicio(props) {
  const { setBandera } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [Documento, SetDocumento] = useState("");
  const [Bandera, SetBandera] = useState(false);
  const [ID_Usuario, setID] = useState("");
  const [DataSetUsuario, SetDataSetUsuario] = useState([]);
  const [Porcentaje, SetPorcentaje] = useState(0);
  const [DataPapeleria, SetDataPapeleria] = useState([]);
  const [TiposPapeleria, SetTiposPapeleria] = useState([]);
  const [Informacion, SetInformacion] = useState([]);
  const [Nombre, SetNombre] = useState("");
  const [Plaza, setPlaza] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [Estados, setEstados] = useState("");
  const [PAPELERIA_URL, setPAPELERIA_URL] = useState([]);
  const [Papeleria_Cargada, setPapeleria_Cargada] = useState([]);
  const [Papeleria_Pendiente, setPapeleria_Pendiente] = useState([]);
  const [Registros, setRegistros] = useState("");
  const [Registro, setRegistro] = useState("");
  const [Mensajes, SetMensajes] = useState("");
  const GuardarData = (data) => {
    data.sort((a, b) => (a.status > b.status ? 1 : -1));
    data.reverse((a, b) => (a.status > b.status ? 1 : -1));
    SetDataSetUsuario(data);
  };

  const SetSpinner = (condition) => {
    setLoading(condition);
  };

  const CalcularPorcentajeAprobado = (data) => {
    var contador = 0;
    for (let i = 0; i < data.length; i++) {
      let tmp = data[i].status;
      if (tmp === "Aceptado" || tmp === "NoAplica") {
        contador += 1;
      }
    }

    if (contador > 0) {
      let total = Math.round((contador / data.length) * 100);
      SetPorcentaje(total);
    } else {
      SetPorcentaje(0);
    }
  };

  const BuscarIdentificador = (Documento) => {
    SetSpinner(true);
    if (Documento.length > 0) {
      request
        .get(Documento)
        .then(async (Response) => {
          if (Response.data.records.length > 0) {
            SetBandera(true);

            //FORMAR DATA PARA MOSTRAR EN GRID
            setID(Response.data.records[0].id);
            let Estado = Response.data.records[0].fields["ESTADO_GOLDEN_PAPELERÍA"];
            setEstados(Estado);
            let Comentarios = Response.data.records[0].fields["COMENTARIOS_PAPELERIA"];
            let Papeleria = Response.data.records[0].fields["PAPELERIA"];
            let nombre = Response.data.records[0].fields["NOMBRE (from CANDIDATOS)"];
            let telefono = Response.data.records[0].fields["TELEFONO (from CANDIDATOS)"];
            let Pendiente = Response.data.records[0].fields["Papelería que falta:"];
            let PuestoPlaza = Response.data.records[0].fields["PLAZA QUE APLICA_"];
            let Cargada = Response.data.records[0].fields["Papelería_Cargada paperbox"];            
            let Information = await Plazas(PuestoPlaza);            
            let tipo = await DatosPlaza(PuestoPlaza);
            SetInformacion(Information);
            setPlaza(PuestoPlaza);
            SetDataPapeleria(Papeleria);
            SetTiposPapeleria(tipo);
            SetNombre(nombre);
            setTelefono(telefono);
            setPapeleria_Cargada(Cargada);
            setPapeleria_Pendiente(Pendiente);

            let Registro_Carga =
              Response.data.records[0].fields["REGISTRO_CARGAR_PAPELERIA"];
            setRegistro(Registro_Carga);

            let NuevosMensajes = Comentarios
            SetMensajes(NuevosMensajes);

            let datos = [];

            //inicia aqui condicion que valida si el usuario ya finalizado su papelería
            if (tipo != null) {
              for (let i = 0; i < tipo.length; i++) {
                let tmpComentario = "";
                let tmpPapeleriaId = "";
                let tmpPapeleriaIdurl = "";
                let tmpEstado = "";

                if (Papeleria != null) {
                  for (let e = 0; Papeleria.length; e++) {
                    let tmp = Papeleria[0].filename;
                    let tmp2 = tipo[i].split(" ");
                    if (tmp.includes(tmp2[0])) {
                      tmpPapeleriaId = Papeleria[0].id;
                      tmpPapeleriaIdurl = Papeleria[0].url;
                    }
                    break;
                  }
                }

                //Obtener estado de archivo tipo i
                if (Estado != null) {
                  let tmp1 = Estado.split("],");
                  for (let j = 0; j < tmp1.length; j++) {
                    let tmp2 = tmp1[j].replace("[", "").split(":");
                    if (tmp2[0] === tipo[i]) {
                      tmpEstado = tmp2[1].replace(" ", "");
                      break;
                    }
                  }
                }

                //Obtener comentarios de archivo tipo i
                if (Comentarios != null) {
                  let tmp1 = Comentarios.split("],");
                  for (let j = 0; j < tmp1.length; j++) {
                    let tmp2 = tmp1[j].replace("[", "").split(":");
                    if (tmp2[0] === tipo[i]) {
                      tmpComentario = tmp2[1];
                      break;
                    }
                  }
                }
                if (tmpEstado.length === 0) {
                  tmpEstado = "Pendiente por revisar";
                }
                if (tmpEstado === "Subido") {
                  tmpEstado = "Enviado";
                }
                let myarray = {
                  tipo: tipo[i],
                  status: tmpEstado,
                  comment: tmpComentario,
                  url: tmpPapeleriaIdurl,
                  idarchivo: tmpPapeleriaId,
                };
                datos.push(myarray);
              }
              GuardarData(datos);
              CalcularPorcentajeAprobado(datos);
              SetSpinner(false);
            }
            //Finaliza aqui condicion
            else {
              let PapeleriaCompleta =
                Response.data.records[0].fields.Papelería_Completa;
              if (PapeleriaCompleta === null) {
                alert("Ha ocurrido un error, intente más tarde.");
                SetBandera(false);
                SetSpinner(false);
              } else {
                SetBandera(true);
                SetPorcentaje(100);
                SetSpinner(false);
              }
            }
          } else {
            alert("Usuario no encontrado");
            SetSpinner(false);
          }
        })
        .catch((err) => {
          console.error(err);
          SetSpinner(false);
          SetBandera(false);
          alert("Ha ocurrido un error, intente de nuevo.");
        });
    } else {
      alert("Debe de ingresar un documento de identificación valido.");
      SetSpinner(false);
    }
  };

  function keyPress(e) {
    if (e.keyCode === 13) {
      BuscarIdentificador(Documento);
    }
  }

  useEffect(() => {
    async function Sesion() {
      let cond = await ValidarToken();
      let Token = JSON.parse(localStorage.getItem(`Token`));
      if (!cond.bandera && Token !== null) {
        //alert(cond.id)
        SetDocumento(cond.id);
        BuscarIdentificador(cond.id);
      }
    }

    Sesion();
  }, []);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div>
        {Bandera ? (
          <Container component="main" maxWidth="xs">
            <hr />
            <Nav_bar condicion={true} />
            <PrincipalCard
              Documento={Documento}
              ID_Usuario={ID_Usuario}
              datos={DataSetUsuario}
              Porcentaje={Porcentaje}
              DataPapeleria={DataPapeleria}
              TiposPapeleria={TiposPapeleria}
              Detalles={Informacion}
              Nombre={Nombre}
              Plaza={Plaza}
              Telefono={Telefono}
              GuardarData={SetDataSetUsuario}
              Estados={Estados}
              Bandera={Bandera}
              loading={loading}
              PAPELERIA_URL={PAPELERIA_URL}
              Papeleria_Pendiente={Papeleria_Pendiente}
              Papeleria_Cargada={Papeleria_Cargada}
              setBandera={setBandera}
              Registros={Registro}
              setRegistros={setRegistros}
              Mensajes={Mensajes}
            />
          </Container>
        ) : (
          <Container component="main" maxWidth="xs">
            <Nav_bar condicion={false} />
            <CssBaseline />
            <div className={classes.paper}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="Documento"
                label="Número de identificación"
                onChange={(e) => {
                  SetDocumento(e.target.value);
                }}
                autoFocus
                onKeyDown={keyPress}
                className="FormularioDocumento"
              />

              <Buttons
                variant="contained"
                className="Botones"
                onClick={() => BuscarIdentificador(Documento)}
                style={{ fontFamily: "Roboto", fontWeigth: "800 !important" }}
              >
                Ingresar
              </Buttons>
            </div>
          </Container>
        )}
      </div>
    </div>
  );
}