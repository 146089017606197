export function MensajeFinal(Mensajes,nuevo,tipo){    
  
    let New_Array = []
    let date = new Date
    let strTime = date.toLocaleString('en-GB', {
        timeZone: `US/Central`
        });
    let Entrada = null;

    if(Mensajes !== undefined)
    {
        Entrada = JSON.parse(Mensajes)
    }

    if(Entrada !== null)
    {
        Object.keys(Entrada).forEach( obj => {
            let tmp = Entrada[obj]
            if(tmp.Tipo === tipo)
            {
                let añadir = {Tipo: tipo ,Mensajes: MensajeTipo(tmp.Mensajes,strTime,nuevo)}
                if(!New_Array.includes(añadir))
                {New_Array.push(añadir)}                
            }
            else{
                let añadir = {Tipo: tmp.Tipo ,Mensajes: tmp.Mensajes}
                if(!New_Array.includes(añadir))
                {New_Array.push(añadir);}
            }
        })

             //Aqui se insertan los que no existían previamente.
             let ArrayMensajes=[]
             ArrayMensajes.push({Mensaje: nuevo, Registro: strTime})
             let Añadir = {Tipo: tipo, Mensajes: ArrayMensajes}
             if(!ValidarExistenciaMensaje(New_Array,tipo)){
                 New_Array.push(Añadir)
             }
    }
    else{
        let Nuevo_Mensaje = []
        Nuevo_Mensaje.push({Mensaje: nuevo, Registro: strTime})
        New_Array.push({Tipo: tipo, Mensajes: Nuevo_Mensaje})
    }

    return JSON.stringify(New_Array)     
}

function MensajeTipo(myArray,Fecha,Nuevo)
{
    myArray.push({Mensaje: Nuevo, Registro: Fecha})
    return myArray;
}


function ValidarExistenciaMensaje(myArray,tipo)
{
    let bandera = false
    Object.keys(myArray).forEach( obj => {
        let tmp = myArray[obj]
        if(tmp.Tipo === tipo)
        {
            bandera = true;
        }
    })
    return bandera;
}