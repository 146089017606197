import React from 'react';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import {AddComment} from '@material-ui/icons';
import '../Papeleria/Estilos.css'
import {MensajeFinal} from '../../Registro_Data/mensajes'

export default function FormDialog(props) {
  const {tipo,Commentarios,Mensajes,SetMensajes} = props
  const [open, setOpen] = React.useState(false);
  const [comentario, setComment] = React.useState("");
  const [bandera, setBandera] = React.useState(false);
  const [imprimir, setImprimir] = React.useState([]);

  const handleClickOpen = () => 
  {
    setOpen(true);
    let ArrayTmp = []
            
    let Entrada = null;

    if(Mensajes !== undefined)
    {
        Entrada = JSON.parse(Mensajes)
    }

    if(Entrada !== null)
    {
        Object.keys(Entrada).forEach( obj => {
            let tmp = Entrada[obj]
            if(tmp.Tipo === tipo)
            {
                Object.keys(tmp.Mensajes).forEach( obj2 => {
                    let tmp2 = tmp.Mensajes[obj2]
                    ArrayTmp.push("Fecha: " + tmp2.Registro + " - Mensaje: " + tmp2.Mensaje)
                })
            }
            
        })

        
    if(ArrayTmp.length === 0)
    {
      ArrayTmp.push("Sin mensajes.")
    }

    }
    
    else
    {
       //no hay mensajes.
       ArrayTmp.push("Sin mensajes.")
    }

    setImprimir(ArrayTmp)           

  };

  const handleClose = (action) => {
    if(action === "ok")
    {
    setOpen(false);
   
    let añadir = MensajeFinal(Mensajes,comentario.replace("null",""),tipo);

    localStorage.setItem(`Mensaje`,JSON.stringify({
      Comentario : añadir
    }))    

    SetMensajes(añadir)
    setComment("")
    } 
    else
    {
      setOpen(false);
    }
  };  
  

  const renderMensaje = () => {
    return(
      <div>      
          {imprimir.map(item => {
            return <li>{item}</li>
          })}
        
      </div>
    )
  }

  return (
    <div>
      
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Agregar Comentarios</Tooltip>}>
        <span className="d-inline-block">
        <Button  style={{margin:"auto"}} onClick={() => handleClickOpen ()}><AddComment
        style={{color:"black"}}
        /></Button>
        </span>
        </OverlayTrigger>   

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{"Mensajes " + tipo}</DialogTitle>
        <DialogContent>

          <DialogContentText>
           {renderMensaje()}
          </DialogContentText>
            <TextareaAutosize
            rowsMax={4}
            aria-label="maximum height"
            placeholder="Maximum 4 rows"  
            onChange={e => {setComment(e.target.value)}}
            defaultValue={`${comentario.replace('null','')}`}    
            style={{width:"100%",height:"100%"}}         
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose("cancelar")} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleClose("ok")} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
