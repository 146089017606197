import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Inicio from "./Papeleria/Formulario";
import "./fondos/estilo.css";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import "./Papeleria/components.css";
import LogOut from "../LogIn/LogOut";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    top: 0,
    left: 0,
    backgroundColor: "white",
    backgroundRepeat: "repeat repeat",
    minWidth: '100vh'
  },
  button: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "white",
    backgroundRepeat: "repeat repeat",
  },
  fondo: {
    backgroundColor: "white",
    backgroundPosition: "center center",
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
  },
}));

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="Footer"
    >
      {"Power by "}
      <Link
        color="inherit"
        //href="https://material-ui.com/"
      >
        Login Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const PapeleriaCandidato = () => {
  const classes = useStyles();
  const [bandera, setBandera] = useState(false);

  return (
    <div className={classes.fondo}>
      <Grid container className={classes.root}>
        <Grid item className={classes.root}>
          <hr />
          {bandera ? <LogOut /> : null}
        </Grid>
        <div
          style={{ margin: "auto", textAlign: "center" }}
          className={classes.root}
        >
          <Grid item className={classes.root}>
            <Inicio setBandera={setBandera} />
            <Box mt={8}>
              <Copyright />
            </Box>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default PapeleriaCandidato;