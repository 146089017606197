import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import * as selectors from '../../logic/reducers';
import * as actions from '../../logic/actions/questions.airtable';
import { Fragment } from 'react';
import Progress from '../resources/progressbar'
import Navbar from '../resources/Navbar'
import FormQuestions from './questions.format';
import './index.css'
import SendQuestions from '../view/sendQuestionForm';
import {
    Grid, makeStyles,Paper, Typography
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    gridMargin:{
        minHeight: '100vh'
    },
    modelClass :{
        marginTop: '00vh'
    },
    typographyColor:{
        color: '#DCDCDC'
    },
    responseQuestions:{
        marginTop: '41px'
    }
}));



const Questions = ({
    fetchAirtable,
    loadingQuestion,
    questions,
    fetchCollection,
    errorQuestion,
    fetchData,
    completedData
})=>{
    
    const classes = useStyles();
    const {id} = useParams()
    useEffect(()=>{
        fetchAirtable(id)
        fetchCollection(id)
    },[id])
    const questionsSend = completedData?.statusEntrevista
    return(
        <Fragment>
            <div>
            {
                loadingQuestion !== false ? 
                
                   <Grid
                        container
                        direction = 'column'
                        alignContent = 'center'
                        alignItems = 'center'
                        justify='center'
                        className = { classes.gridMargin }
                   >
                        <Grid 
                            item
                            lg = { 8 }
                            md = { 8 }
                            xs = { 8 }
                        >
                            <Progress/>
                        </Grid>
                    </Grid> 
                : 
                
                    questions != null && ( 
                        <div>
                          {
                              questionsSend != 'ENTREVISTA ENVIADA' && questionsSend != 'APROBO' && questionsSend != 'NO APROBO' ? (
                                    <Paper
                                    elevation = {3}
                                    className = 'paper2 paper3'
                                    >
                                        <Grid
                                            container
                                            direction = 'column'
                                            alignItems = 'center'
                                            alignContent = 'center'
                                            justify = 'center'
                                        >
                                            <Grid
                                                item
                                                lg = { 12 }
                                                md = { 12 }
                                                xs = { 12 }
                                            >
                                            
                                                {/* <img 
                                                    // src= { `${ constants.ROUTES.IMG_FONDO } `}
                                                    // className = 'imageFondoPequeno imageFondoGrande imageFondoPequenoPequeno2 imageFondoGradeSu'
                                                /> */}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            direction = 'column'
                                            alignItems = 'center'
                                            alignContent = 'center'
                                            justify = 'center'
                                        >
                                            <Grid
                                                item
                                                lg = { 12 }
                                                md = { 12 }
                                                xs = { 12 }
                                            >
                                                {/* <img 
                                                    // src= { `${ constants.ROUTES.IMG_MRB2 } `}
                                                    // className = 'imageMrbPequeno imageMrbPequenoPequeno2 imageMrbGrande'
                                                />
                                         */}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            direction = 'column'
                                            alignItems = 'center'
                                            alignContent = 'center'
                                            justify = 'center'
                                        >
                                            <Grid
                                                item
                                                lg = { 12 }
                                                md = { 12 }
                                                xs = { 12 }
                                            >
                                                <Typography variant = 'h6' className = {classes.typographyColor}>
                                                    Contesta todas las preguntas para la entrevista 
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                        <div className={classes.modelClass}>
                                            <FormQuestions 
                                                props = { questions === undefined ? " " : questions }
                                                questionId = {id}
                                            />
                                        </div>
                                    </Paper>
                              ): (
                                <div>
                                        <Navbar/>                                        

                                    <div className = {classes.responseQuestions}>   
                                        <SendQuestions/>
                                    </div>
                                        <center style={{bottom: 0, paddingTop: '10px'}}>
                                            <Typography variant="body2" component="p" style={{color: '#ABB2B9'}}>
                                            Copyright © QubitSystems 2021
                                            </Typography>
                                        </center>
                                </div>
                              )
                          }
                        </div>
                    )
                
            }
            </div>
        </Fragment>
    )

}

export default  connect(
    state =>({
        loadingQuestion: selectors.getIsFetchingQuestion(state),
        questions: selectors.getIsData(state),
        errorQuestion: selectors.getIsError(state),
        completedData: selectors.getIsFetchingQuestionFirebaseCompleted(state)
    }),
    dispatch=>({
        fetchAirtable(id){
            dispatch(actions.startedQuestionsAirtable(id))
        },
        fetchCollection(id){
            dispatch(actions.startedGetFirebaseQuestions(id))
        }
    })
)(Questions);