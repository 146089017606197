import React from 'react';
import  Visualizador from './visualizado'
import {Modal, OverlayTrigger,Tooltip} from 'react-bootstrap'
import VisibilityIcon from '@material-ui/icons';
import {Button,IconButton} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {ValidarToken} from '../../Prompts/Plazas'
import {Alert,AlertTitle} from '@material-ui/lab';
import {Snackbar} from '@material-ui/core';
import {renovarSesion} from '../../Sesion/index'

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {"Visualizador " + props.tipo + " - Estatus: " + props.status}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Visualizador
          urlfile={props.urlfile}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );}  

  function TransitionsModal2({DataPapeleria,tipo,status}) {
    const [modalShow, setModal] = React.useState(false);
    const [URL, setURL] = React.useState("");
    const [OpenAlert, setOpenAlert] = React.useState(false);  

    const setModalShow = async(condition) =>
    {
      let cond = await ValidarToken()
      let bandera = renovarSesion()
      if(!cond.bandera && !bandera)
        {
        setModal(true);
        VisualizarPdf(tipo);
        }
        else{
          setOpenAlert(true)
          localStorage.clear()
          window.location.href = "/Papeleria"
        }
    }

    function VisualizarPdf(tipo)
  {   
    let data = JSON.parse(localStorage.getItem(`url${tipo}`))
    if(data !== null || data !== undefined){
      setURL(data.url)
    }    
  }
  
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }    
    setOpenAlert(false);
    localStorage.clear()
    window.location.href = "/Papeleria"
  }; 

    return (
      <>               

        <label style={{cursor:"pointer"}}  onClick={() => setModalShow(true)}>
            <IconButton  aria-label="upload file" component="span">
              <FontAwesomeIcon icon={faSearch} className="IconoBuscar"/>              
            </IconButton>
            <span className="Titulo">Vista previa</span>
        </label>  
  
        <MyVerticallyCenteredModal
          show={modalShow}
          urlfile={URL}
          status={status}
          tipo={tipo}
          onHide={() => setModal(false)}
        />

        <Snackbar open={OpenAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
            <Alert severity="error">
            <AlertTitle>Tu sesión ha expirado</AlertTitle>
            <strong>Por favor, vuelve a iniciar sesión.</strong>
            </Alert> 
            </Snackbar> 


      </>
    );
  }

  export default TransitionsModal2