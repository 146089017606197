export default function PapeleriaCargada(datos,tipos)
{    
        let pendiente = [] 
        let cargados = [] 

        Object.keys(datos).forEach( (obj) => {
        let documento = datos[obj].Documento;
        let status = datos[obj].Status;           
        //let cond =  !cargadas.includes(documento); 
        if(status === "Aceptado" || status === "Aprobado" || status === "NoAplica" || status === "No Aplica")
       {
        cargados.push(documento)    
       }
       else
       {
        pendiente.push(documento)
       }        
    })

    Object.keys(tipos).forEach( (obj) => {
        let tmp = tipos[obj]
        if(!pendiente.includes(tmp) && !cargados.includes(tmp)){
            pendiente.push(tmp)
        }
    })

    return {"pendientes" : pendiente,"cargados": cargados}
}
