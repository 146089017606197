import { combineReducers } from "redux";
import * as types from '../types/file';
import {surveyJSON} from "../../json/format";


// ------------------------- SEND DATA

const  isUploadingJobRequest = (state= false, action)=>{
    switch (action.type) {
        case types.UPLOAD_JOB_REQUEST_FIREBASE_URL_STARTED:{
            return true
        }
        case types.UPLOAD_JOB_REQUEST_FIREBASE_URL_COMPLETED:{
            return false
        }
    
        case types.UPLOAD_JOB_REQUEST_FIREBASE_URL_FAILED:{
            return false
        }
        default:{
            return state
        }
    }
}

const jobRequestError = (state = null, action) =>{
    switch (action.type) {
        case types.UPLOAD_JOB_REQUEST_FIREBASE_URL_STARTED:{
            return null
        }
        case types.UPLOAD_JOB_REQUEST_FIREBASE_URL_COMPLETED:{
            return null
        }

        case types.UPLOAD_JOB_REQUEST_FIREBASE_URL_FAILED:{
            return action.payload.error
        }
        default:{
            return state
        }
    }
}



//------------------------- PLAZA ID
const isFetchingMarketStallStarted = (state=false, action)=>{
    switch(action.type){
        case types.FETCHING_ID_MARKET_STALL_STARTED:{
            return true
        }
        case types.FETCHING_ID_MARKET_STALL_COMPLETED:{
            return false
        }
        case types.FETCHING_ID_MARKET_STALL_FAILED:{
            return false
        }
        default:{
            return state
        }
    }
}

const isFetchingMarketStallCompleted = (state=null, action)=>{
    switch(action.type){
        case types.FETCHING_ID_MARKET_STALL_COMPLETED:{
            return action.payload.data
        }
        default:{ return state}
    }
}

const isFetchingMarketStallFailed = (state = false, action)=>{
    switch(action.type){
        case types.FETCHING_ID_MARKET_STALL_STARTED:{
            return false
        }
        case types.FETCHING_ID_MARKET_STALL_COMPLETED:{
            return false
        }
        case types.FETCHING_ID_MARKET_STALL_FAILED:{
            return true
        }
        default:{return state}
    }
}

//--------------------------JSON FORMAT QUESTIONS
const formFormat = (state=surveyJSON,action)=>{
    let elements = state.pages[0].elements
    let jobsIndex = elements.findIndex((element)=>element.name=="JOB_PLAZA");
    switch (action.type) {
        case types.FETCHING_ID_MARKET_STALL_COMPLETED:
            {
             elements[jobsIndex]={
                "type":"dropdown",
                "name":"JOB_PLAZA",
                "title":"Plaza que Aplica",
                "choices":action.payload.data.records.map((item)=>{
                    return{
                            "value":item.id,
                            "text":item.fields.Name
                            
                        }
                })
             }
             return {
                    ...state,
                    pages:[{
                        elements:elements,
                    }]
                }
            }
        
        default:
            return state;
    }
}
const files = combineReducers({
    //SEND DATA 
    jobRequestError,
    isUploadingJobRequest,
    //PLAZA ID
    isFetchingMarketStallStarted,
    isFetchingMarketStallCompleted,
    isFetchingMarketStallFailed,
    //FORMAT JSON
    formFormat,
    
})

export default files;

export const getIsUploadingJobRequest = state => state.isUploadingJobRequest; 
export const getJobRequestError = state => state.jobRequestError; 

// ID AIRTABLE MARKET STALL 

export const getIsFetchingIdMarketStarted = state => state.isFetchingMarketStallStarted;
export const getIsFetchingIdMarketCompleted = state => state.isFetchingMarketStallCompleted;
export const getIsFetchingIdMarketFailed = state => state.isFetchingMarketStallFailed;
export const getFormData = state => state.formFormat;
