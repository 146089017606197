import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const labels = {
    1: 'DEMASIADO MALA',
    2: 'MALA',
    3: 'MEDIA',
    4: 'BUENA',
    5: 'EXCELENTE',
};

const useStyles = makeStyles({
  root: {
    // width: 300,
    // display: 'flex',
    alignItems: 'center',
  },
});

export default function Calification1({
    ranquingOne,
}) {
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const classes = useStyles();

  ranquingOne(value);   


  return (
    <div className={classes.root}>
      <Rating
        name="start"
        value={value}
        precision={1}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        size = "large"

      />
      {
        value !== null &&
            <Box ml={2}>
                {labels[hover !== -1 ? hover : value]}
            </Box>
      }
    </div>
  );
}
