import React , { Fragment } from 'react';
import {connect} from 'react-redux'
import Formik from 'formik';
import PagePrincipalJob from '../view/viewJobApplication/pageprincipal.job';
import { 
    makeStyles,
} from '@material-ui/styles';
import { 
    Typography,
    Button
} from '@material-ui/core';
const useStyles = makeStyles((theme)=> ({
    root:{
        //marginTop: theme.spacing(1),
        display:'flex',
        flexDirection:'column',
        height:'100%',
        margin:0
    },
    conteiner:{
        backgroundColor: 'red'
    },
    content:{
        flex:1,
      },
}))


const JobApplication = (props) => {
    const classes = useStyles(); 
    return (
        <Fragment>
            <div className = {classes.root}>
                <div className ={classes.content}><PagePrincipalJob/></div>
                <div className={classes.conteiner}>
                <Button
                    style = {{
                        backgroundColor: 'blue',
                        color: 'white'
                    }}
                >ENVIAR PROCESOS</Button>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(
    state => ({
        
    }),
    dispatch => ({

    })
)(JobApplication)