export const questionsSatisfaccion = {
    question1:'¿Tuvo algún inconveniente durante la entrevista?',
    question2:'¿En terminos generales como fue su experiencia durante la entrevista?',
    question3:'¿Como considera la amabilidad de su entrevistador?',
    question4:'¿Comó considera la atención de su entrevistador?',
}

export const img = {
  imgMrb:
    "https://firebasestorage.googleapis.com/v0/b/talenthub-gt.appspot.com/o/whiteMRB.png?alt=media&token=a04283e0-daf2-452a-82ff-1a7b036ce1a4",
  imgGolden:
    "https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2FScreen%20Shot%202021-02-26%20at%2011.03.39.png?alt=media&token=20ee4640-343f-4fa9-85e1-ae016489ef63",
};