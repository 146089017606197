export function GetRegistro(Registro, tipo)
{
  
  let actual = null;
  let Nuevo_Registro = "";
      
  if(Registro !== undefined)
  {
    actual = JSON.parse(Registro)
  }

  if(actual !== null)
      {
        Object.keys(actual).forEach( obj => {
          let tmp = actual[obj] 
          if(tmp.tipo === tipo){     
            Nuevo_Registro = tmp.Registro;
          }
        })
        if(Nuevo_Registro.length === 0){
          Nuevo_Registro = "Sin Registro."
        }
      }
  else{
    Nuevo_Registro = "Sin Registro."
  }

  return Nuevo_Registro;
}

export function RegistroRevision(Registro,tipo){

  let New_Array = []
  let date = new Date
  let strTime = date.toLocaleString('en-GB', {
      timeZone: `US/Central`
    });
  console.log(strTime);


    let actual = null;
    
    if(Registro !== undefined)
    {
      actual = JSON.parse(Registro)
    }

    if(actual !== null)
    {                              
              let insertar = {"tipo": tipo, Registro: strTime}
              New_Array.push(insertar)                    
              Object.keys(actual).forEach( obj => {
                    let tmp = actual[obj]         
                    if(!ValidarExistencia(New_Array,tmp.tipo))
                    {
                    let tmp2 = {"tipo": tmp.tipo, Registro: tmp.Registro}                 
                    New_Array.push(tmp2)                  
                    }
               })              
    }
    else{
      New_Array.push({"tipo": tipo, Registro: strTime})
    }
          
    return JSON.stringify(New_Array)
}

function ValidarExistencia(myArray,tipo)
{
    let bandera = false;
    Object.keys(myArray).forEach( obj => {
      let tmp = myArray[obj]
      if(tipo === tmp.tipo)
      {
        bandera = true;
      }
    })
    return bandera;
}

export function RegistroCargar(Registro,cambiarEstado){
        //Codigo
    let New_Array = []
    let date = new Date
    let strTime = date.toLocaleString('en-GB', {
        timeZone: `US/Central`
      });
      
      
    let actual = null;
      
      if(Registro !== undefined)
      {
        actual = JSON.parse(Registro)
      }
            
    if(actual !== null)
      {     
          //Aqui se insertan los existentes.
          Object.keys(actual).forEach( obj => {
            let tmp = actual[obj];
            let tmp2 = GetPapeleria(cambiarEstado)
            if(tmp2.includes(tmp.tipo)){    
              New_Array.push({"tipo": tmp.tipo, Registro: strTime})  
            }
            else{
              New_Array.push({"tipo": tmp.tipo, Registro: tmp.Registro})
            }
          })

          //Aqui se insertan los que no existían previamente.
          Object.keys(cambiarEstado).forEach( obj => {
            let tmp = cambiarEstado[obj]
            if(!ValidarTipo(New_Array,tmp.tipo))
            {
            New_Array.push({"tipo": tmp.tipo, Registro: strTime})
            }
          })  
      }
      else{
        //Se insertan los que no deberían.
        Object.keys(cambiarEstado).forEach( obj => {
          let tmp = cambiarEstado[obj]
          New_Array.push({"tipo": tmp.tipo, Registro: strTime})
        })        
      }
       return JSON.stringify(New_Array)      
}

function GetPapeleria(myArray)
{
  let nuevo = []

  Object.keys(myArray).forEach( obj => {
    let tmp = myArray[obj].tipo
    nuevo.push(tmp)
  })

  return nuevo;
}

function ValidarTipo(myArray,tipo)
{
  let condicion = false
  for(let i = 0; i < myArray.length;i++){
    let tmp = myArray[i].tipo;
    if(tmp === tipo){
      condicion = true;
      break;
    }
  }
  return condicion;
}