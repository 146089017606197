import { put,takeEvery } from 'redux-saga/effects'
import * as helpers from '../../helpers/job.aplication';
import * as actions from '../actions/job.aplication';
import * as types from '../types/job.aplication';

export function * postDataAirtableJob(action){
    try{
        let Response = yield put(helpers.postDataJob(action.payload.request,action.payload.file,action.payload.fileDescriptor));
        if(Response.error === null){
            yield put(actions.completedPostAirtable(Response))
        }else{
            yield put(actions.completedPostAirtable(Response.error))
        }
    }catch(error){
        console.log('');
    }
}
export function * watchPostDataAirtable(){
    yield takeEvery(
        types.POST_JOB_APLICATION_STARTED,
        postDataAirtableJob
    )
}