import React from 'react';
import  Visualizador from './visualizador'
import {Modal, OverlayTrigger,Tooltip} from 'react-bootstrap'
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {"Visualizador " + props.tipo + " - Estatus: " + props.status}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Visualizador
          urlfile={props.urlfile}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );}  

  function TransitionsModal({DataPapeleria,tipo,status}) {
    const [modalShow, setModal] = React.useState(false);
    const [URL, setURL] = React.useState("");

    const setModalShow = (condition) =>
    {
        setModal(true);
        VisualizarPdf(tipo);
    }

    const validarData = (arrayTmp,filename) =>{
      var bandera = false 
      var contador = 0;   

      for(let i =0; i < arrayTmp.length;i++)
      {
          if(filename.includes(arrayTmp[i])){
              contador ++;
          }
      }
      if(contador ===arrayTmp.length) {bandera = true;}
      return bandera;
  }

    function VisualizarPdf(tipo)
  {   
    var UrlFiles = ""         
    if(DataPapeleria != null)
    {
      var NombreFile = ""
      var UrlFiles = ""      
    for(var i =0; i< DataPapeleria.length;i++)
    {
      NombreFile = GetFileName(DataPapeleria[i].filename)  
      var tmp = tipo.split(" ")
      var bandera = validarData(tmp,NombreFile)
      if(bandera)
      {
        UrlFiles = DataPapeleria[i].url;                
        break
      }
    }
    if(UrlFiles.length === 0)
    {
      alert("Archivo pendiente por subir.") 
    }
    else{        
    //Pendiente, url de archivo candidato.   
    setURL(UrlFiles)     
    }  
  }
  else{
    alert("Archivo pendiente por subir.")
  }
  }

  function GetFileName(url_link)
  {
    let tmp = url_link.split("%2F")
    let tmp2 = tmp[1].split("%7C")
    let tmp3 = tmp2[0].replace("%20"," ").replace("%20"," ")
    return tmp3
  }
  
    return (
      <>        
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{"Visualizar " + tipo}</Tooltip>}>
        <span className="d-inline-block">
        <Button  style={{margin:"auto"}} onClick={() => setModalShow(true)} ><VisibilityIcon
        style={{color:"black"}}
        /></Button>
        </span>
        </OverlayTrigger>    
  
        <MyVerticallyCenteredModal
          show={modalShow}
          urlfile={URL}
          tipo={tipo}
          status={status}
          onHide={() => setModal(false)}
        />
      </>
    );
  }

  export default TransitionsModal