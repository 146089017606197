import { combineReducers } from 'redux';
import * as types from '../types/questions.airtable';

const isFetching = (state = false, action) =>{
    switch (action.type) {
        case types.FETCHING_DATA_AIRTABLE_STARTED:{
            return true
        }
        case types.FETCHING_DATA_AIRTABLE_COMPLETED:{
            return false
        }
        
        case types.FETCHING_DATA_AIRTABLE_FAILED:{
            return false
        }    

        
        default: return state;
        
    }
}

const isFetchingFirebase = (state = false,action) => {
    switch (action.type) {
        case types.FETCHING_DATA_FIREBASE_STARTED:{
            return true
        }

        case types.FETCHING_DATA_FIREBASE_COMPLETED:{
            return false
        }

        case types.FETCHING_DATA_FIREBASE_FAILED: {
            return false
        }

              
        default:{
            return state
        }
    }
}

const data = (state = null, action)=>{
    switch (action.type) {
        
        case types.FETCHING_DATA_AIRTABLE_COMPLETED:{
            return action.payload.data
        }

        default: return state
  
    }
}

const dataFirebases = (state = null, action)=>{

    switch (action.type) {
        case types.FETCHING_DATA_FIREBASE_COMPLETED:{
            return action.payload.dataFirebase
        }
        default:{
            return state
        }
    }
}

const error = (state = false, action)=>{
    switch (action.type) {
        case types.FETCHING_DATA_AIRTABLE_STARTED:{
            return false
        }
        case types.FETCHING_DATA_AIRTABLE_COMPLETED:{
            return false
        }
        case types.FETCHING_DATA_AIRTABLE_FAILED:{
            return true
        }

        default: return  state 
        
    }
}

const errorFirebase = (state = false, action) =>{

    switch (action.type) {
        case types.FETCHING_DATA_FIREBASE_STARTED:{
            return false
        }
    
        case types.FETCHING_DATA_FIREBASE_COMPLETED:{
            return false
        }

        case types.FETCHING_DATA_FIREBASE_FAILED:{
            return true
        }
        default: return state
    }
}

const sendStarted = ( state = false, action) =>{
    switch (action.type) {
        case types.CREATE_DATA_AIRTABLE_STARTED:{
            return true
        }

        case types.CREATE_DATA_AIRTABLE_COMPLETED:{
            return false
        }

        case types.CREATE_DATA_AIRTABLE_FAILED:{
            return false
        }

        default: {
            return state
        }

    }
}



const sendError = (state = null, action) =>{
    switch (action.type) {
        
        case types.CREATE_DATA_AIRTABLE_STARTED:{
            return null
        }

        case types.CREATE_DATA_AIRTABLE_COMPLETED:{
            return null
        
        }
        
        case types.CREATE_DATA_AIRTABLE_FAILED:{
            return action.payload.error
        
        }
    
        default:{
            return state
        }
    }
}




const updateDataStarted = (state = false, action)=>{
    switch (action.type) {

        case types.UPDATE_AIRTABLE_QUESTION_STARTED:{
            return true
        }

        case types.UPDATE_AIRTABLE_QUESTION_COMPLETED:{
            return false
        }

        case types.UPDATE_AIRTABLE_QUESTION_FAILED:{
            return false
        }


        case types.UPDATA_DATA_FIREBASE_STARTED:{
            return true
        }

        case types.UPDATA_DATA_FIREBASE_COMPLETED:{
            return false
        }

        case types.UPDATA_DATA_FIREBASE_FAILED:{
            return false
        }

        default:
           {
               return state
           }
    }
}

const updateDataCompleted = (state = null, action)=>{
    switch (action.type) {
        case types.UPDATA_DATA_FIREBASE_COMPLETED:{
            return action.payload.questions
        }
    
        case types.UPDATE_AIRTABLE_QUESTION_COMPLETED:{
            return action.payload.resul
        }
        
        default:{
            return state
        }
    }
}

const updateDataError = (state=false, action)=>{
    switch (action.type) {
        case types.UPDATE_AIRTABLE_QUESTION_STARTED:{
            return false
        }

        case types.UPDATE_AIRTABLE_QUESTION_COMPLETED:{
            return false
        }
            
        case types.UPDATE_AIRTABLE_QUESTION_FAILED:{
            return true
        }

        case types.UPDATA_DATA_FIREBASE_STARTED:{
            return false
        }

        case types.UPDATA_DATA_FIREBASE_COMPLETED:{
            return false
        }

        case types.UPDATA_DATA_FIREBASE_FAILED:{
            return true
        }
    
        default:{
            return state
        }
    }
}

const isUpdateStarted = (state = false, action) => {
    switch (action.type) {
        case types.UPDATE_AIRTABLE_POLL_STARTED:{
            return true;
        }
            
        case types.UPDATE_AIRTABLE_POLL_COMPLETED:{
            return false;
        }

        case types.UPDATE_AIRTABLE_POLL_FAILED:{
            return false;
        }

        default: return state;        
    }
}

const isUpdatedFailed = (state = false, action) => {
    switch (action.type) {
        case types.UPDATE_AIRTABLE_POLL_STARTED:{
            return false;
        }
            
        case types.UPDATE_AIRTABLE_POLL_COMPLETED:{
            return false;
        }

        case types.UPDATE_AIRTABLE_POLL_FAILED:{
            return true;
        }

        default: return state;        
    }
}

const isUpdateCompleted = (state= null, action) => {
    switch (action.type) {
        case types.UPDATE_AIRTABLE_POLL_COMPLETED:{
            return action.payload.response
        }
            
        default: return state;
    }
}

export const isGetPollStarted = (state = false, action) => {
    switch (action.type) {
        case types.GET_AIRTABLE_POLL_STARTED:{
            return true;
        }
        
        case types.GET_AIRTABLE_POLL_COMPLETED:{
            return false;
        }
        
        case types.GET_AIRTABLE_POLL_FAILED:{
            return false;
        }

        default: return state;
    }
}

export const isGetPollCompleted = (state = null, action) => {
    switch (action.type) {
        case types.GET_AIRTABLE_POLL_COMPLETED:{
            return action.payload.res
        }
            
        default: return state;
    }
}

export const isGetPollFailed = (state = false, action) => {
  switch (action.type) {
    case types.GET_AIRTABLE_POLL_STARTED: {
      return false;
    }

    case types.GET_AIRTABLE_POLL_COMPLETED: {
      return false;
    }

    case types.GET_AIRTABLE_POLL_FAILED: {
      return true;
    }

    default:
      return state;
  }
};

export const isFetchingCandidatePollStarted = (state = false, action) => {
    switch (action.type) {
        case types.FETCH_CANDIDATE_POLL_STARTED:{
            return true
        }
        
        case types.FETCH_CANDIDATE_POLL_COMPLETED:{
            return false
        }

        case types.FETCH_CANDIDATE_POLL_FAILED:{
            return false
        }
        
        default:{
          return state;
        }
    }
}

export const isFetchingCandidatePollCompleted = (state = null, action) => {
    switch (action.type) {
        case types.FETCH_CANDIDATE_POLL_COMPLETED:{
          return action.payload.request;
        }
        default:{
            return state;
        }
    }
}


export const isFetchingCandidatePollFailed = (state = false, action) => {
    switch (action.type) {
        case types.FETCH_CANDIDATE_POLL_STARTED:{
            return false
        }
        
        case types.FETCH_CANDIDATE_POLL_COMPLETED:{
            return false
        }

        case types.FETCH_CANDIDATE_POLL_FAILED:{
            return true
        }
        
        default:{
          return state;
        }
    }
}
const questions_airtable = combineReducers({
    isFetching,
    updateDataCompleted,
    updateDataError,
    updateDataStarted,
    data,
    error,
    sendError,
    sendStarted,
    errorFirebase,
    dataFirebases,
    isFetchingFirebase,
    isUpdateStarted,
    isUpdatedFailed,
    isUpdateCompleted,
    isGetPollCompleted,
    isGetPollStarted,
    isGetPollFailed,
    isFetchingCandidatePollStarted,
    isFetchingCandidatePollCompleted,
    isFetchingCandidatePollFailed,
})

export default questions_airtable; 

export const getIsFetchingQuestion = state => state.isFetching;
export const getIsData = state => state.data;
export const getIsError = state => state.error; 

export const patchDataStarted = state => state.sendStarted;
export const pathcDataError = state => state.sendError; 

export const getIsFetchingQuestionFirebaseStarted = state => state.isFetchingFirebase;
export const getIsFetchingQuestionFirebaseCompleted = state => state.dataFirebases;
export const getIsFetchingQuestionFirebaseError = state => state.errorFirebase;

export const updateQuestionsStarted = state => state.updateDataStarted;
export const updateQuestionsCompleted = state => state.updateDataCompleted;
export const updateQuestionsError = state => state.updateDataError; 

export const updatePollStarted = state => state.isUpdateStarted; 
export const updatePollFailed = state => state.isUpdatedFailed;
export const updatePollCompleted = state =>  state.isUpdateCompleted;

export const getPollCompleted = state => state.isGetPollCompleted;
export const getPollStarted = state => state.isGetPollStarted;
export const getPollFailed = state =>  state.isGetPollFailed;

export const getPollCandidateStarted = state => state.isFetchingCandidatePollStarted;
export const getPOllCandidateCompleted = state => state.isFetchingCandidatePollCompleted;
export const getPollCanidateFailed = state => state.isFetchingCandidatePollFailed;
