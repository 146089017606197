import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import {FlipCameraAndroid} from '@material-ui/icons';


const options = [  
  'No Aplica',
  'Aceptado',    
  'Rechazado',  
];


function ConfirmationDialogRaw(props) {
  const {tipo,ActualizarEstado,onClose, value: valueProp,open, ...other } = props
  const [value, setValue] = React.useState(valueProp);    
  const radioGroupRef = React.useRef(null);
  
  React.useEffect(() => {
    if (!open) {            
        setValue(valueProp);      
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {      
    localStorage.setItem(`Estado${tipo}`,JSON.stringify({
      estado: value}))
      ActualizarEstado(value)
    onClose(value);   
  };

  const handleChange = (event) => {
    setValue(event.target.value);    
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Seleccionar estado de papelería</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',   
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
}));


export default function ConfirmationDialog(props) {
  const classes = useStyles();
  const {tipo,datos,SetDataSetUsuario} = props
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [bandera, setBandera] = React.useState(false);
  const [initsValue, setinitsValue] = React.useState('');
  
  const handleClickListItem = () => {               
    setOpen(true);         
  };
  
  const handleClose = (newValue) => {
    setOpen(false);             
  };

  
  function ActualizarEstado(value){    
    if(value !== null){
        let estado = value;
        
        let index= 0;
        let newData = undefined;
        
    for(let i = 0; i < datos.length;i++){          
        if(tipo === datos[i].Documento){ 
            index = i;               
            break;             
        }
    }  

    newData = datos[index]   
    newData["Status"] = estado
    datos[index] = newData        
    SetDataSetUsuario([...datos])
    }
}




  return (
    <div className={classes.root}>

        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Cambiar estado</Tooltip>}>               
        <FlipCameraAndroid
        style={{cursor:"pointer"}}
        onClick={() => handleClickListItem()}
        />
        </OverlayTrigger>    
        
        <ConfirmationDialogRaw
          classes={{
            paper: classes.paper,
          }}
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}     
          tipo={tipo}  
          ActualizarEstado={ActualizarEstado}   
        />
      
    </div>
  );
}
