import { combineReducers } from 'redux'
import formFile, * as files from './formfile';
import questionForm, * as question from './questions.airtable';
import jobAplication, * as job_application from './job.aplication'
const reducer = combineReducers({
    formFile,
    questionForm,
    jobAplication
})

export default reducer;


// SEND DATA
export const getIsUploadingJobRequest = state => files.getIsUploadingJobRequest(state.formFile);
export const getJobRequestError = state => files.getJobRequestError(state.formFile);
// ID PLAZA
export const getIsFetchingIdMarketStarted = state => files.getIsFetchingIdMarketStarted(state.formFile);
export const getIsFetchingIdMarketCompleted = state => files.getIsFetchingIdMarketCompleted(state.formFile);
export const getIsFetchingIdMarketFailed = state => files.getIsFetchingIdMarketFailed(state.formFile);

// FORMAT
export const getFormData = state => files.getFormData(state.formFile);

// AIRTABLE GET DATA 
export const getIsFetchingQuestion = state => question.getIsFetchingQuestion(state.questionForm);
export const getIsData = state => question.getIsData(state.questionForm);
export const getIsError = state => question.getIsError(state.questionForm);

// AIRTABLE SEND DATA AND FIREBASE 
export const patchDataStarted = state => question.patchDataStarted(state.questionForm)
export const pathcDataError = state => question.pathcDataError(state.questionForm)

// FIREBASE GET DATA 

export const getIsFetchingQuestionFirebaseStarted = state => question.getIsFetchingQuestionFirebaseStarted(state.questionForm);
export const getIsFetchingQuestionFirebaseCompleted = state => question.getIsFetchingQuestionFirebaseCompleted(state.questionForm);
export const getIsFetchingQuestionFirebaseError = state => question.getIsFetchingQuestionFirebaseError(state.questionForm);

// UPDATE DATA FIREBASE AND AIRTABLE 

export const updateQuestionsStarted = state => question.updateQuestionsStarted(state.questionForm);
export const updateQuestionsCompleted = state => question.updateQuestionsCompleted(state.questionForm);
export const updateQuestionError = state => question.updateQuestionsError(state.questionForm);

// GET DATA JOB APLICATION

export const getIsFetchingData = state => job_application.getIsFetchingData(state.jobAplication);
export const getIsDataAir = state => job_application.getIsData(state.jobAplication);
export const getIsErrorAir = state => job_application.getError(state.jobAplication);

// POST DATA 
export const postDataAirtable = state=> job_application.postDataAirtable(state.jobAplication);
export const postDataStarted = state => job_application.postDataStarted(state.jobAplication);
export const postDataFailed = state => job_application.postDataFailed(state.jobAplication);

export const updatePollStarted = state => question.updatePollStarted(state.questionForm); 
export const updatePollFailed = state => question.updatePollFailed(state.questionForm);
export const updatePollCompleted = state => question.updatePollCompleted(state.questionForm);

// GET DATA QUESTIONS 

export const getPollCompleted = state => question.getPollCompleted(state.questionForm);
export const getPollStarted = state => question.getPollStarted(state.questionForm);
export const getPollFailed = state => question.getPollFailed(state.questionForm);

export const getPollCandidateStarted = state => question.getPollCandidateStarted(state.questionForm);
export const getPOllCandidateCompleted = state => question.getPOllCandidateCompleted(state.questionForm);
export const getPollCanidateFailed = state => question.getPollCanidateFailed(state.questionForm);
