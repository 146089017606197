import React from 'react'
import {request} from '../../helpers/candidato.data.js'
import Grid from '@material-ui/core/Grid';

import NavBar from './navbar/navbar'

import {Nav} from 'react-bootstrap'
import BuscarCandidato from './Papeleria/BuscarCandidato'


class General extends React.Component {
    constructor(props) {
        super(props);
        this.state = {              
                nombre: "",
                telefono: "",
                email: "",
                currentTab: "buscar",
                _id: null            
        };
            this.changeTab = this.changeTab.bind(this)
    }
    
    changeTab(tab)
    {
        this.setState({ currentTab: tab });
    }

    setIdCandidato(id)
    {
        this.setState({_id: id});
    }

    getIdCandidato()
    {
        return this.state._id
    }
    
    render() {
        return (                        
                <div >                    
                <NavBar/>  
                <Grid>                        
        <br></br>
        <BuscarCandidato/>

        </Grid>        
            </div>
     
        );
    }
}

export default General;
