import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Divider,
} from "@material-ui/core";
import RatingSatisfaccion from "./ratingsSatisfaccion";
import StartsRating from "./starsRating";
import { questionsSatisfaccion } from "../../constants/questions";
import { Button } from "@material-ui/core";
import * as actions from "../../logic/actions/questions.airtable";
import * as selectors from "../../logic/reducers";
import { useParams } from "react-router";
import Spinner from "../../Spinner/Spinner";
import { useSnackbar } from "notistack";
import CompletedQuestionary from "./completedQuestionary";
import { CandidatePoll as CandidateModel } from "../../models";


const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: "40px",
    textAlign: "center",
    fontSize: "35px",
    fontFamily: "Oswald, sans-serif",
  },
  divider: {
    paddingLeft: "30%",
    paddingRight: "30%",
  },
  titleQuestions: {
    paddingTop: "60px",
    paddingBottom: "6px",
    fontWeight: 600,
    fontSize: "20px",
    textAlign: "left",
    fontFamily: "Oswald, sans-serif",
  },
  responsTextfield: {
    width: "100%",
  },
  buttonSubmit: {
    padding: "10px",
  },
}));

const FormSatisfaccion = ({
  // update question for candidate
  updatePoll,
  isLoadingUpdate,
  isCompletedUpdate,
  isFailedUpdate,
  // looking for candidate response
  fetchingPollCandidate,
  isCompletedGetQuestion,
  isFailedGetQuestion,
  isLoadingGetQuestion,
  // fetching candidate agenda golden
  fetchingCandidatePollAgenda,
  isCompletedFetchCandidate,
  isLoadingFetchCandidate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const [calification1, setCalification1] = useState(null);
  const [calification2, setCalification2] = useState(null);

  let schema = yup.object({
    pregunta1: yup.string().required(" INGRESA LA RESPUESTA 1 "),
    pregunta2: yup.string().required(" INGRESA LA RESPUESTA 2 "),
  });

  let initialValue = {
    pregunta1: undefined,
    pregunta2: undefined,
  };

  const modal = new CandidateModel(isCompletedFetchCandidate);
  let prueba = modal.toJson().prueba;
  let asignada =modal.toJson().asignada;

  console.log(prueba)
  const valueCalification1 = (res) => {
    setCalification1(res || null);
  };

  const valueCalification2 = (res) => {
    setCalification2(res || null);
  };

  useEffect(() => {
    fetchingPollCandidate(id);
  }, [id, fetchingPollCandidate]);

  useEffect(() => {
    fetchingCandidatePollAgenda("recnV5r7t3j2pbR04");
  }, [id, fetchingCandidatePollAgenda]);

  const buttonOnClick = async () => {
    if ((await isFailedUpdate) !== null) {
      enqueueSnackbar("SE A ENVIADO CORRECTAMENTE LA RESPUESTA", {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      enqueueSnackbar("ERROR AL ENVIAR LA ENCUESTA", {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <Fragment>
      {isLoadingFetchCandidate ? (
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          style={{
            minHeight: "100vh",
          }}
        >
          <Grid>
            <Spinner />
          </Grid>
        </Grid>
      ) : (
        <div>
          {isCompletedFetchCandidate !== null && (
            <div>
              {isLoadingUpdate !== false ? (
                <Grid
                  container
                  direction="column"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    minHeight: "100vh",
                  }}
                >
                  <Grid>
                    <Spinner />
                  </Grid>
                </Grid>
              ) : isLoadingGetQuestion !== false ? (
                <Grid
                  container
                  direction="column"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    minHeight: "100vh",
                  }}
                >
                  <Grid>
                    <Spinner />
                  </Grid>
                </Grid>
              ) : (
                <div>
                  {isCompletedGetQuestion !== null ? (
                    <CompletedQuestionary />
                  ) : (
                    <div>
                      {isCompletedUpdate !== null ? (
                        <CompletedQuestionary />
                      ) : (
                        <Formik
                          initialValues={initialValue}
                          onSubmit={(values) => {
                            let data = {
                              values,
                              calification1,
                              calification2,
                              prueba,
                              asignada,
                            };

                            updatePoll(id, data);
                          }}
                          validationSchema={schema}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            errors,
                            values,
                            touched,
                          }) => (
                            <form onSubmit={handleSubmit} noValidate>
                              <Typography className={classes.title}>
                                “CÓMO ESTUVO TU EXPERIENCIA? AYÚDANOS A
                                MEJORAR.”
                              </Typography>
                              <Grid
                                container
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Divider
                                  variant="middle"
                                  className={classes.divider}
                                />
                              </Grid>
                              {/* QUESTIONS RESPONSE CLIENT */}
                              <Grid
                                container
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Grid item xs={10} lg={6} md={12} sm={12}>
                                  <Typography
                                    className={classes.titleQuestions}
                                  >
                                    {questionsSatisfaccion.question3.toUpperCase()}
                                  </Typography>
                                  {/*  RATING 1 */}
                                  <StartsRating
                                    ranquingOne={valueCalification1}
                                  />

                                  <Typography
                                    className={classes.titleQuestions}
                                  >
                                    {questionsSatisfaccion.question4.toUpperCase()}
                                  </Typography>

                                  {/* RATING 2 */}

                                  <RatingSatisfaccion
                                    ranquingOne={valueCalification2}
                                  />

                                  <Typography
                                    className={classes.titleQuestions}
                                  >
                                    {questionsSatisfaccion.question1.toUpperCase()}
                                  </Typography>

                                  <TextField
                                    name="pregunta1"
                                    key="pregunta1"
                                    id="pregunta1"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    className={classes.responsTextfield}
                                    error={
                                      errors.pregunta1 && touched.pregunta1
                                    }
                                    value={
                                      values.pregunta1 === undefined
                                        ? values.pregunta1
                                        : values.pregunta1.toUpperCase()
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={
                                      errors.pregunta1 &&
                                      touched.pregunta1 &&
                                      errors.pregunta1
                                    }
                                  />

                                  <Typography
                                    className={classes.titleQuestions}
                                  >
                                    {questionsSatisfaccion.question2.toUpperCase()}
                                  </Typography>
                                  <TextField
                                    name="pregunta2"
                                    key="pregunta2"
                                    id="pregunta2"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    className={classes.responsTextfield}
                                    style={{ paddingBottom: "40px" }}
                                    error={
                                      errors.pregunta2 && touched.pregunta2
                                    }
                                    value={
                                      values.pregunta2 === undefined
                                        ? values.pregunta2
                                        : values.pregunta2.toUpperCase()
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={
                                      errors.pregunta2 &&
                                      touched.pregunta2 &&
                                      errors.pregunta2
                                    }
                                  />

                                  {calification1 > 0 && calification2 > 0 ? (
                                    <div
                                      style={{
                                        paddingBottom: "40px",
                                      }}
                                    >
                                      <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        onClick={buttonOnClick}
                                        className={classes.buttonSubmit}
                                      >
                                        ENVIAR RESPUESTAS
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        paddingBottom: "40px",
                                      }}
                                    >
                                      <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        onClick={buttonOnClick}
                                        className={classes.buttonSubmit}
                                        disabled
                                      >
                                        ENVIAR RESPUESTAS
                                      </Button>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </form>
                          )}
                        </Formik>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => ({
    // is updated question candidate
    isLoadingUpdate: selectors.updatePollStarted(state),
    isCompletedUpdate: selectors.updatePollCompleted(state),
    isFailedUpdate: selectors.updatePollFailed(state),
    // looking for candidate response
    isLoadingGetQuestion: selectors.getPollStarted(state),
    isCompletedGetQuestion: selectors.getPollCompleted(state),
    isFailedGetQuestion: selectors.getPollFailed(state),
    //fetching candidate agenda
    isLoadingFetchCandidate: selectors.getPollCandidateStarted(state),
    isCompletedFetchCandidate: selectors.getPOllCandidateCompleted(state),
    isFailedFetchCandidate: selectors.getPollCanidateFailed(state),
  }),
  (dispatch) => ({
    updatePoll(id, request) {
      dispatch(actions.startedUpdatedPoll(id, request));
    },
    fetchingPollCandidate(id) {
      dispatch(actions.startedGetPoll(id));
    },
    fetchingCandidatePollAgenda(id) {
      dispatch(actions.startedFetchingCandidatePoll(id));
    },
  })
)(FormSatisfaccion);
