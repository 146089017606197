import * as types from '../types/file';

export const uploadJobRequestStarted = request =>({
    type: types.UPLOAD_JOB_REQUEST_FIREBASE_URL_STARTED,
    payload:{
        request
    }
})


export const uploadJobRequestCompleted = ( request  ) => ({
    type: types.UPLOAD_JOB_REQUEST_FIREBASE_URL_COMPLETED, 
    payload:{
        request
    }
})

export const uploadJobRequestFailed = error => ({
    type: types.UPLOAD_JOB_REQUEST_FIREBASE_URL_FAILED,
    payload:{
        error
    }
})


export const getIdMarketStallStarted = () =>({
    type: types.FETCHING_ID_MARKET_STALL_STARTED,
})

export const getIdMarketStallCompleted = (data) =>({
    type: types.FETCHING_ID_MARKET_STALL_COMPLETED,
    payload:{
        data
    }
})

export const getIdMarketStallFailed = (error) => ({
    type: types.FETCHING_ID_MARKET_STALL_FAILED,
    payload:{
        error
    }
})



