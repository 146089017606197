import { put,take,takeEvery,takeLatest } from "redux-saga/effects"
import * as helpers from '../../helpers/fileData'
import * as actions from '../actions/formfile';
import * as types from '../types/file';

export function * uploadJobRequest(action) {
    try{    
        console.log(action.payload.file)
        let Response = yield(helpers.filePost(action.payload.request))
        console.log(Response)
        if(Response.error === null){
            yield put(actions.uploadJobRequestCompleted(Response))
        }else{
            yield put(actions.uploadJobRequestFailed(Response.error))
        }
    }catch(error){
        console.log(error.message)
    }
}

export function* watchUploadJobRequest (){
    yield takeEvery(
        types.UPLOAD_JOB_REQUEST_FIREBASE_URL_STARTED, 
        uploadJobRequest
    )
}


export function * fetchingIdDataAirtable(action){
    try{
        let Response = yield(helpers.idMarketStell())
        yield put(actions.getIdMarketStallCompleted(Response.data))
    }catch(error){
        console.log("unespected error")
        console.log(error.message);
        yield put(actions.getIdMarketStallFailed(error))
    }
}

export function* watchIdMarket (){
    yield takeEvery(
        types.FETCHING_ID_MARKET_STALL_STARTED,
        fetchingIdDataAirtable
    )
}