import React from 'react';
import  Visualizador from './visualizador'
import {Modal, OverlayTrigger,Tooltip} from 'react-bootstrap'
import VisibilityIcon from '@material-ui/icons';
import {Button,IconButton} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {ValidarToken} from '../../Prompts/Plazas'
import {Alert,AlertTitle} from '@material-ui/lab';
import {Snackbar} from '@material-ui/core';
import {renovarSesion} from '../../Sesion/index'

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {"Visualizador " + props.tipo + " - Estatus: " + props.status}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Visualizador
          urlfile={props.urlfile}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );}  

  function TransitionsModal({DataPapeleria,tipo,status}) {
    const [modalShow, setModal] = React.useState(false);
    const [URL, setURL] = React.useState("");
    const [OpenAlert, setOpenAlert] = React.useState(false); 

    const setModalShow = async(condition) =>
    {
      
      let cond = await ValidarToken()

       let bandera = renovarSesion()
       if(!cond.bandera && !bandera)
        {setModal(true);
        VisualizarPdf(tipo);}
       else{ setOpenAlert(true)
        localStorage.clear()
        window.location.href = "/Papeleria"}

    }

    const validarData = (arrayTmp,filename) =>{
      var bandera = false 
      var contador = 0;   

      for(let i =0; i < arrayTmp.length;i++)
      {
          if(filename.includes(arrayTmp[i])){
              contador ++;
          }
      }
      if(contador ===arrayTmp.length) {bandera = true;}
      return bandera;
  }

    function VisualizarPdf(tipo)
  {   
    var UrlFiles = ""         
    if(DataPapeleria != null)
    {
      var NombreFile = ""          
    for(var i =0; i< DataPapeleria.length;i++)
    {
      NombreFile = GetFileName(DataPapeleria[i].filename)   
      var tmp = tipo.split(" ")
      var bandera = validarData(tmp,NombreFile)
      if(bandera)
      {
        UrlFiles = DataPapeleria[i].url;                
        break
      }
    }
    if(UrlFiles.length === 0)
    {
      alert("Archivo pendiente por subir.") 
    }
    else{        
    //Pendiente, url de archivo candidato.   
    setURL(UrlFiles)     
    }  
  }
  else{
    alert("Archivo pendiente por subir.")
  }
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }    
    setOpenAlert(false);
    localStorage.clear()
    window.location.href = "/Papeleria"
  };

  function GetFileName(url_link)
  {
    let tmp = url_link.split("%2F")
    let tmp2 = tmp[1].split("%7C")
    let tmp3 = tmp2[0].replace("%20"," ").replace("%20"," ")
    return tmp3
  }
  
    return (
      <>               

        <label style={{cursor:"pointer"}}  onClick={() => setModalShow(true)}>
            <IconButton  aria-label="upload file" component="span">
              <FontAwesomeIcon icon={faSearch} className="IconoBuscar"/>              
            </IconButton>
            <span className="Titulo">Vista previa</span>
        </label>  
  
        <MyVerticallyCenteredModal
          show={modalShow}
          urlfile={URL}
          tipo={tipo}
          status={status}
          onHide={() => setModal(false)}
        />

<Snackbar open={OpenAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
            <Alert severity="error">
            <AlertTitle>Tu sesión ha expirado</AlertTitle>
            <strong>Por favor, vuelve a iniciar sesión.</strong>
            </Alert> 
            </Snackbar> 
      </>
    );
  }

  export default TransitionsModal