import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core';
import * as constants from '../../constants'
const useStyles = makeStyles(theme => ({
    imageMrbWith:{
        width: '110px',
        height: 'auto',
        marginRight: '0px'
    },
    Navbar: {
        backgroundColor: '#000' //'#E2F000',
    },
}))

const NavBar = () => {
    const classes = useStyles();
    return (
        <Fragment>
           
                <AppBar  position="fixed" className = { classes.Navbar } >
                    <Toolbar>
                        <img 
                            // src= { `${ constants.ROUTES.IMG_MRB } `}
                            // className = { classes.imageMrbWith }
                        />
                    </Toolbar>
                </AppBar>
        </Fragment>
    );
};

export default connect(
    state => ({
        
    }),
    dispatch =>({
       
        
    })
)(NavBar);