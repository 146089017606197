import React,{useEffect} from 'react'
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import '../fondos/estilo.css'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ReactTour from '../Tour/ReactTour'

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  

const PrincipalCard = (props) => {
    const {Telefono,Plaza,Nombre,Documento,ID_Usuario,datos,Porcentaje,DataPapeleria,TiposPapeleria,Detalles,
      GuardarData,Estados,Bandera,loading,PAPELERIA_URL,
      Papeleria_Pendiente,Papeleria_Cargada,setBandera,
      Registros,setRegistros,Mensajes} = props

      useEffect( () => {
        if(datos !== null){
          if(datos.length > 0)
          {
            setBandera(true)
          }
        }
      },[datos])
    return (
        <div>
            {
            datos.length > 0 ?
            <div>
             <hr></hr> 
             <div className="container">
             <div className="column">
             <p className="Nombre">{Nombre}</p>
            <p className="Plaza">{Plaza}</p>
            <p className="Numero"><PhoneAndroidIcon className="PhoneIcon"/> {Telefono}</p>   
             </div>
             <br/>
             <div className="column">
             <p className="Porcentaje">{"Avance"}             
             <p className="Total">{Porcentaje + " %"}</p></p>                        
             </div>
             </div>      
        {
        Porcentaje === 100 ?
        <div>        
        <hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/>
        <p className="Mensaje">Haz Completado con toda tu papelería, espera el mensaje de nuestros reclutadores.</p>   
        <hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/><hr/>          
        </div>
        :   null
        }
           <br/>

             <ReactTour 
            Nombre={Nombre}
            Documento={Documento}
            ID_Usuario={ID_Usuario}
            datos={datos}
            Porcentaje={Porcentaje}
            DataPapeleria={DataPapeleria}
            TiposPapeleria={TiposPapeleria}
            Detalles={Detalles}
            GuardarData={GuardarData}
            Estados={Estados}
            PAPELERIA_URL={PAPELERIA_URL}
            Papeleria_Pendiente={Papeleria_Pendiente}
            Papeleria_Cargada={Papeleria_Cargada}
            Registros={Registros}
            setRegistros={setRegistros}
            Mensajes={Mensajes}
            />

        </div>
            :                                                 
              (!Bandera || !loading) ?
              <div> <hr></hr> <p className="Mensaje_error">Papelería pendiente por cargar.</p></div>
              : null
            
    }   
        </div>
    )
}

export default PrincipalCard;