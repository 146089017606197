export class updatePollCandidate{
    constructor(id,response){
        this.id = id;
        this.ragtin1 = response.ragting1; 
        this.ragtin2 = response.ragting2;
        this.respuesta3 = response.respuesta3;
        this.respuesta4 = response.respuesta4;
        this.prueba = response.prueba;
        this.asignada = response.asignada;
    }
    toJson(){
        return{
            "fields":{
                "CALIFICACIÓN 1":this.ragtin1,
                "CALIFICACIÓN 2":this.ragtin2,
                "CALIFICACIÓN 3":this.respuesta3,
                "CALIFICACIÓN 4":this.respuesta4,
                "Agenda": [
                    this.id
                ],
                "CALIFICADO": true,
                "TIPO PRUEBA": this.prueba,
                "ASIGNADA": this.asignada,
            }
        }
    }
}