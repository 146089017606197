import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Snackbar} from '@material-ui/core';
import {Alert,AlertTitle} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import postPapeleriaCandidato from "../../../helpers/Papeleria"
import { jsPDF } from "jspdf";
import {ValidarArchivo,GetTipo} from '../ValidarArchivos/Metodos'
import IconButton from '@material-ui/core/IconButton';
import {ValidarToken} from './Plazas'
import {renovarSesion} from '../Sesion/index'
import './estilos.css'
import '../Papeleria/cardEstilo.css'

const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(10),
      flexBasis: '33.33%',
      flexShrink: 0,
      fontWeight: "700",
      
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',    
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    Texto: {
      fontSize: theme.typography.pxToRem(11),  
      fontWeight: "700"
    },
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      }
}));

    const Upload = (props) => {
    const {status,tipo,Documento,setBanderaSubido,datos,
    GuardarData,setCambiarEstado,cambiarEstado,tmp} = props    
       
    const [banderaFile, setbanderaFile] = useState(null)
    const [banderaFileFalse, setbanderaFileFalse] = useState(null)    
    const [open, setOpen] = React.useState(false);       
    const [OpenAlert, setOpenAlert] = useState(false);   

    const classes = useStyles();   
 
    //ALERTA SESIÓN EXPIRADA
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }    
        setOpenAlert(false);
        localStorage.clear()
        window.location.href = "/Papeleria"
      };
        
      //ALERTA ARCHIVO SUBIDO EXITOSO
        const handleClose = () => {
        setOpen(false);
        setbanderaFile(false);
        };

      //ALERTA ARCHIVO SUBIDO SIN EXITO
      const handleClosed = () => {
        setOpen(false);
        setbanderaFileFalse(false);
        };

        //cierra el spinner
        const handleCloseP = () => {
            setOpen(false);
            };

                         
       function Archivos(status, type)
        {    
            if(status === "Aceptado")
            {                  
            return(
                <p className="Mensajes">Archivo Aprobado.</p>         
            )  
            }
            else if( status === "NoAplica")   {
                                
            return(
                <p className="Mensajes">Archivo no requerido.</p>  
            ) 
            } 
            else{
            return(
                
                <div>
                    {/*Boton para subir archivo*/}    
                <input className={classes.input} id={`icon-button-file${tmp}`} type="file"
                onChange={onChanges}  onClick={() => SetTipo(type,status)}/>
                <label htmlFor={`icon-button-file${tmp}`} style={{cursor:"pointer"}} className="AlinearFile">
                <IconButton aria-label="upload file" component="span">
                <FontAwesomeIcon icon={faUpload} className="IconoSubir"/>
                </IconButton>
                <span className="Titulo">Cargar</span>
                </label>           
                </div>
            )
            }
        }

    function CambiarEstados()
    {
        let type = JSON.parse(localStorage.getItem('tipo'))     
        let index= 0;
        let newData = undefined;

        for(let i = 0; i < datos.length;i++){    

            if(type.tipo === datos[i].tipo){ 
                index = i;
                newData = datos[index]   
                break;             
            }
        }  

        newData["status"] = "Subido"
        datos[index] = newData
        // datos.sort((a,b) => (a.status > b.status) ? 1: -1)
        // datos.reverse((a,b) => (a.status > b.status) ? 1: -1) 
        GuardarData([...datos])
        //datos[i] = {tipo: datos[i].tipo, status: "Subido", comment : datos[i].comment, url:datos[i].url, idarchivo: datos[i].idarchivo}                 


        setCambiarEstado( (tmp) => {
            tmp = cambiarEstado
            tmp.push({tipo: type.tipo, status: "Subido"})
            return tmp
        })          
    }

    const ConsultarToken = async() => {
        let cond = await ValidarToken()    
        return cond
    }

    const onChanges = async(e) => {        
        setOpen(!open)      
        let cond = ConsultarToken()
        let bandera = renovarSesion()
        if(!cond.bandera && !bandera)   
        {        
            let f = e.target.files[0]   
        let f2 = e.target.files   
        let tipo = JSON.parse(localStorage.getItem('tipo'))   
        let banderaArchivo = ValidarArchivo(f2)
   
        if(!banderaArchivo.bandera)
        {        
            let banderatmp = GetTipo(f2)   
        //si es verdadero es imagen y convertira la imagen a pdf, para que en el visualizador de pdf se pueda ver estos archivos.  
        if(banderatmp)
        {
        //endcodeImage(f,tipo.tipo,Documento)             
        let reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = function (e) {
            //Initiate the JavaScript Image object.
            let image = new Image();
            image.src = e.target.result;
                        
            //Validate the File Height and Width.
            image.onload = function () {
                let height = this.height;
                let width = this.width;
            if(ValidarPesoImagen(width,height)){
                endcodeImage(f,tipo.tipo,Documento)  
            }
            else
            {
                alert("Se intento subir una imagen con un tamaño no valido.")
                handleCloseP()
            }
            
            };}
            
        }
        //si es falso es pdf, se sube con el proceso normal.
        else{        
        let m = postPapeleriaCandidato(f,tipo.tipo,Documento)  
        m.then(val => Message(val))   
        }
            
        }
        else
        {      
        alert(banderaArchivo.mensaje)
        handleCloseP()        
        setbanderaFileFalse(true)
        }    
    }

        else{
          setOpenAlert(true)          
          handleCloseP()
        }
    }  

    function ValidarPesoImagen(width,height){
        let bandera = false;
        let total = height - width;
        if( (total <= 1200) && (height >= width) && (width > 300 && height > 350)){
            bandera = true;
        }
        return bandera;
    }
    
    function SetTipo(tipo,status)
    {   
        localStorage.setItem(`tipo`,JSON.stringify({
            tipo : tipo
        }))    
    }
        const Message = (val) =>
        {        
        if(val === "Si")
        {           
            setbanderaFile(true)                            
            CambiarEstados()          
            handleCloseP()
        }
        else
        {
            handleCloseP()
            setbanderaFileFalse(true)            
        }
        }
          
        const endcodeImage = (img,tipo,Documento) =>
        {              
            let file = new FileReader()              
            file.onloadend = function()
            {        
                let data = file.result            
                pdfconvert(data,tipo,Documento)                                                 
            }       
            file.readAsDataURL(img)                
        }
        const pdfconvert = (data,tipo,Documento) =>
        {                                                                              
                let doc = new jsPDF()            
                doc.setFontSize(40);        
                doc.addImage(data,15, 30, 180, 250);            
                let out = doc.output();
                let url = 'data:application/pdf;base64,' + btoa(out);                                
                let file = dataURLtoFile(url,'hello.txt');          
                let m = postPapeleriaCandidato(file,tipo,Documento)  
                m.then(val => Message(val))                                                          
        }
        
        function dataURLtoFile(dataurl, filename) {    
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }                  

    return (
        <div>
            
            <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
            </Backdrop>  
                    
            <Snackbar open={banderaFile} autoHideDuration={2000} onClose={handleClose}>
            <Alert variant="filled" severity="success" onClose={handleClose} >
            Archivo subido correctamente.
            </Alert>
            </Snackbar>          
            
            <Snackbar open={banderaFileFalse} autoHideDuration={2000} onClose={handleClosed}>
            <Alert variant="filled" severity="error" onClose={handleClosed} >
            Error al subir archivo, pruebe de nuevo por favor.
            </Alert>
            </Snackbar>

            
            <Snackbar open={OpenAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
            <Alert severity="error">
            <AlertTitle>Tu sesión ha expirado</AlertTitle>
            <strong>Por favor, vuelve a iniciar sesión.</strong>
            </Alert> 
            </Snackbar>         

            {Archivos(status,tipo)}     

        </div>
    )
}

export default Upload;