import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Result as ResultModel } from '../../models';
import * as selectors from '../../logic/reducers';
import * as actions from '../../logic/actions/questions.airtable';
import { Fragment } from 'react';
import { makeStyles,Grid,Typography,TextField, Button } from '@material-ui/core'
import swal from 'sweetalert'

const useStyles = makeStyles(theme => ({
    gridMargin:{
        minHeight: '100vh'
    },
    modelClass :{
        marginTop: '20vh'
    },
    typografy:{
        marginTop: '5vh',
        marginBottom: '5vh',
        width:'50ch',
        textAlign: 'center'
    },
    buttonAprobado:{
        backgroundColor: 'green',
        margin: theme.spacing(3)
    },
    buttonNoAprobado:{
        backgroundColor: 'red',
    }
}));


const ResultFrom = (props) =>{
    const id = props.idResult
    const onClikAprobado = ()=>{
        const statusNew = 'APROBO';
        props.updateCollectionFirebase(id,statusNew);
        props.updateCollectionAirtable(id,statusNew);
        swal("Has enviado la calificacion, ",` La calificación es ${statusNew}`, "Estado de entrevista enviada", "success")

    }

    const onClickNoAprobado = () =>{
        const statusNew = 'NO APROBO';
        props.updateCollectionFirebase(id,statusNew);
        props.updateCollectionAirtable(id,statusNew);
        swal("Has enviado la calificacion, ",` La calificación es ${statusNew}`, "Estado de entrevista enviada", "success")
    }

    console.log(props.completed)

    const classes = useStyles()
    let model = new ResultModel(props.params)
    return(
        <Fragment>
            {
                props.loading === false ? (
                    <div>
                         {
                                <div>
                                {
                                    model.getInputsResponse().inputs.filter((element)=>element.title !== undefined).map((input)=>{
                                        return(
                                            <div>      
                                                    <Grid
                                                    container
                                                    direction = 'column'
                                                    alignItems = 'center'
                                                    alignContent = 'center'
                                                    justify = 'center'
                                                    >
                                                        <Grid
                                                            item
                                                            lg = { 4 }
                                                            md = { 5 }
                                                            xs = { 10 }
                                                            sm = { 7 }
                                                        >
                                                            <Typography 
                                                                variant='subtitle1'
                                                            >
                                                            PREGUNTA  {""+input.title}
                                                            </Typography>    
                                                        </Grid>
                                                    </Grid>
                                            
                                                    <Grid
                                                        container
                                                        direction = 'column'
                                                        alignItems = 'center'
                                                        alignContent = 'center'
                                                        justify = 'center'
                                                    >
                                                        <Grid
                                                            item
                                                            lg = { 8 }
                                                            md = { 6 }
                                                            xs = { 12 }
                                                        >
                                                            <TextField
                                                                disabled
                                                                multiline
                                                                style = {{
                                                                    padding: '20px',
                                                                    width: '60ch'
                                                                }}
                                                                rowsMax={4}
                                                                variant='outlined'
                                                                defaultValue={input.result}
                                                                className={classes.typografy}
                                                            >
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                        )    
                                    })
                                
                                }
                                <Grid
                                    container
                                    direction = 'column'
                                    alignItems = 'center'
                                    alignContent = 'center'
                                    justify = 'center'
                                >
                                    <Grid
                                        item
                                        lg = { 12 }
                                        md = { 12 }
                                        xs = { 12 }
                                    >
                                        <div>
                                                <Button
                                                    className = {classes.buttonAprobado}
                                                    variant='outlined'
                                                    onClick= {onClikAprobado}
                                                >
                                                    APROBADO
                                                </Button>
                            
                                                <Button
                                                    className = {classes.buttonNoAprobado}
                                                    variant ='outlined'
                                                    onClick = {onClickNoAprobado}
                                                >
                                                    NO APROBADO
                                                </Button>
                                        </div>
                                    </Grid>
                            </Grid> 
                            </div>
                      
                        }
                    </div>
                ):(
                    "cargando"
                )
                
                
            }
        </Fragment>
    )
}

export default connect(
        state=>({
            loading: selectors.getIsFetchingQuestionFirebaseStarted(state),
        }),
        dispatch=>({
            updateCollectionFirebase(id,question){
                dispatch(actions.startedUpdateFirebaseQuestions(id,question))
            },
            updateCollectionAirtable(id,question){
                dispatch(actions.startedUpdateAirtableQuestions(id,question))
            }
        })
)(ResultFrom);