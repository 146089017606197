import { put, takeEvery } from "redux-saga/effects";
import * as helpers from "../../helpers/airtableData";
import * as actions from "../actions/questions.airtable";
import * as types from "../types/questions.airtable";

export function* getDataAirtable(action) {
  try {
    let Response = yield helpers.airtableData(action.payload.id);
    if (Response.error === null) {
      yield put(actions.completedQuestionsAirtable(Response));
    } else {
      yield put(actions.completedQuestionsAirtable(Response.error));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetQuestionsRequest() {
  yield takeEvery(types.FETCHING_DATA_AIRTABLE_STARTED, getDataAirtable);
}

export function* sendDataAirtableFirebase(action) {
  try {
    let Response = yield helpers.dataAirtable(
      action.payload.request,
      action.payload.id
    );
    yield put(actions.completedCreateData(Response));
  } catch (error) {}
}

export function* watchSendDataRequest() {
  yield takeEvery(types.CREATE_DATA_AIRTABLE_STARTED, sendDataAirtableFirebase);
}

export function* getDataFirebaseQuestion(action) {
  try {
    let Response = yield helpers.firebaseGet(action.payload.idFirebase);
    if (Response.error === null) {
      yield put(actions.completedGetFirebaseQuestions(Response));
    } else {
      yield put(actions.completedGetFirebaseQuestions(Response.error));
    }
  } catch (error) {}
}

export function* watchGetDataFirebase() {
  yield takeEvery(
    types.FETCHING_DATA_FIREBASE_STARTED,
    getDataFirebaseQuestion
  );
}

export function* updateQuestionFirebase(action) {
  try {
    let Response = yield helpers.updateDataFirebase(
      action.payload.id,
      action.payload.questions
    );
    if (Response.error === null) {
      yield put(actions.completedUpdateFirebaseQuestions(Response));
    } else {
      yield put(actions.completedUpdateFirebaseQuestions(Response.error));
    }
  } catch (error) {
    console.log("");
  }
}

export function* watchUpdateDataFirebase() {
  yield takeEvery(types.UPDATA_DATA_FIREBASE_STARTED, updateQuestionFirebase);
}

export function* updateQuestionAirtable(action) {
  try {
    let Response = yield put(
      helpers.updateDataAirtable(action.payload.id, action.payload.resul)
    );
    if (Response.error === null) {
      yield put(actions.completedUpdateAirtableQuestions(Response));
    } else {
      yield put(actions.completedUpdateAirtableQuestions(Response.error));
    }
  } catch (error) {
    console.log("");
  }
}

export function* watchUpdateDataAirtable() {
  yield takeEvery(
    types.UPDATE_AIRTABLE_QUESTION_STARTED,
    updateQuestionAirtable
  );
}

function* pollCandidate(action) {
  try {
    let { id, request } = action.payload;
    console.log(action);
    let Response = yield helpers.updatePollCandidate(id, request);
    let { data, error } = Response;
    if (error === null) {
      yield put(actions.completedUpdatePoll(data));
    } else {
      yield put(actions.failedUpdatePoll(error));
    }
  } catch (error) {
    console.error("ERROR ----->", error.message);
    yield put(actions.completedCreateData(error.message));
    yield put(
      actions.failedUpdatePoll("HA OCURRIDO UN ERROR AL MOMENTO DE ACTUALIZAR")
    );
  }
}

export function* watchedUpdatePollCandidate() {
  yield takeEvery(types.UPDATE_AIRTABLE_POLL_STARTED, pollCandidate);
}

function* FetchingpollCandidate(action) {
  try {
    let { id } = action.payload;
    let response = yield helpers.GetPollQuestions(id);
    let { data, error } = response;
    console.log('in sagas',data)
    if (error === null) {
      yield put(actions.completedGetPoll(data));
    } else {
      yield put(
        actions.failedGetPoll("HA OCURRIDO UN ERROR AL OBTENER EL CANDIDATO")
      );
    }
  } catch (error) {
    console.log("ERROR ------> ", error);
    yield put(actions.failedGetPoll(error));
  }
}

export function* watchedFetchingPollCandidate() {
  yield takeEvery(types.GET_AIRTABLE_POLL_STARTED, FetchingpollCandidate);
}

function* FetchingCandidatePoll(action) {
  try {
    let { id } = action.payload;
    let response = yield helpers.getPollCandidateQuestions(id);
    console.log(response)
    let { data, error } = response;
    if (error === null) {
      yield put(actions.completedFetchingCandidatePoll(data));
    } else {
      yield put(
        actions.failedFetchingCandidateCandidatePoll(
          "HA OCURRIDO UN ERROR AL OBTENER AL CANDIDATO"
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.failedFetchingCandidateCandidatePoll(
        "HA OCURRIDO UN ERROR AL OBTENER AL CANDIDATO"
      )
    );
  }
}

export function* watchedFetchPollCandidatesQuestions() {
  yield takeEvery(types.FETCH_CANDIDATE_POLL_STARTED, FetchingCandidatePoll);
}
