import { fork, all } from "redux-saga/effects";
import { watchUploadJobRequest, watchIdMarket } from "./formfile";
import {
  watchGetQuestionsRequest,
  watchSendDataRequest,
  watchGetDataFirebase,
  watchUpdateDataAirtable,
  watchUpdateDataFirebase,
  watchedUpdatePollCandidate,
  watchedFetchingPollCandidate,
  watchedFetchPollCandidatesQuestions,
} from "./questions.airtable";
import { watchPostDataAirtable } from "./job.aplication";
function* mainSaga() {
  yield all([
    fork(watchUploadJobRequest),
    fork(watchIdMarket),
    fork(watchGetQuestionsRequest),
    fork(watchSendDataRequest),
    fork(watchGetDataFirebase),
    fork(watchUpdateDataAirtable),
    fork(watchUpdateDataFirebase),
    fork(watchPostDataAirtable),
    fork(watchedUpdatePollCandidate),
    fork(watchedFetchingPollCandidate),
    fork(watchedFetchPollCandidatesQuestions),
  ]);
}

export default mainSaga;
