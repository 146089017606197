import React ,{ useEffect }from 'react';
import { Fragment } from 'react';
import  { connect }  from 'react-redux'
import { makeStyles, Grid ,Paper} from '@material-ui/core'
import JobApplicatioForm from './job.applicationsForm'
import * as actions from '../../logic/actions/formfile'
import * as selectors from '../../logic/reducers'
import './index'
const useStyles = makeStyles(theme=>({

    bodyStyle:{
        backgroundColor: '#F4D03F',
        width:'100%',
        height:'100vh',
        position:'absolute',
    }
}))

const JobAplication = () =>{
    const classes = useStyles()
    return (
        <div className={classes.bodyStyle}>
            <JobApplicatioForm/>
        </div>
    )
}

export default connect(
    state=>({
        
    }),
    dispatch=>({
       
    })
)(JobAplication)
