import axios from "axios";
import {
  // firebaseFirestore,
  // firebaseConfig,
  firebaseFirestoreApp,
} from "../config/config";
import { store } from "../App";
import * as actions from "../logic/actions/questions.airtable";
// import * as constastForm from "../constants/airtable";
import { updatePollCandidate as candidate } from "../models/candidatePoll";
import { isEmpty } from "lodash";

// const collection = 'interviewMrb'

const db = firebaseFirestoreApp;
export const airtableData = async (id) => {
  try {
    var config = {
      method: "get",
      url: `https://api.airtable.com/v0/app2tSIDsOpffig85/Entrevistas.E_Preguntas/${id}`,
      headers: {
        Authorization: "Bearer keycnxGOFUAfvAOcc",
      },
    };
    let response = await axios(config);

    return {
      data: response.data,
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {
      data: null,
      error,
    };
  }
};

export const dataAirtable = async (datas, id) => {
  try {
    let updateData = await axios({
      url: "https://api.airtable.com/v0/app2tSIDsOpffig85/Respuestas.E_Respuestas",
      method: "PATCH",
      headers: {
        Authorization: "Bearer keycnxGOFUAfvAOcc",
      },
      data: {
        records: [
          {
            id: id,
            fields: {
              "RESPUESTA 2": datas["PREGUNTA 2 (from prueba)"],
              "RESPUESTA 3": datas["PREGUNTA 3 (from prueba)"],
              "RESPUESTA 4": datas["PREGUNTA 4 (from prueba)"],
              "RESPUESTA 5": datas["PREGUNTA 5 (from prueba)"],
              "RESPUESTA 6": datas["PREGUNTA 6 (from prueba)"],
              "RESPUESTA 7": datas["PREGUNTA 7 (from prueba)"],
              "RESPUESTA 8": datas["PREGUNTA 8 (from prueba)"],
              "RESPUESTA 9": datas["PREGUNTA 9 (from prueba)"],
              "RESPUESTA 10": datas["PREGUNTA 10 (from prueba)"],
              "RESPUESTA 11": datas["PREGUNTA 11 (from prueba)"],
              "RESPUESTA 12": datas["PREGUNTA 12 (from prueba)"],
              "RESPUESTA 13": datas["PREGUNTA 13 (from prueba)"],
              "RESPUESTA 14": datas["PREGUNTA 14 (from prueba)"],
              "RESPUESTA 15": datas["PREGUNTA 15 (from prueba)"],
              "RESPUESTA 1": datas["PREGUNTA I (from Prueba)"],
              "Status Entrevista:": "ENTREVISTA ENVIADA",
            },
          },
        ],
      },
    });
    let data = {
      respuesta2:
        datas["PREGUNTA 2 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 2 (from prueba)"],
      respuesta3:
        datas["PREGUNTA 3 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 3 (from prueba)"],
      respuesta4:
        datas["PREGUNTA 4 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 4 (from prueba)"],
      respuesta5:
        datas["PREGUNTA 5 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 5 (from prueba)"],
      respuesta6:
        datas["PREGUNTA 6 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 6 (from prueba)"],
      respuesta7:
        datas["PREGUNTA 7 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 7 (from prueba)"],
      respuesta8:
        datas["PREGUNTA 8 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 8 (from prueba)"],
      respuesta9:
        datas["PREGUNTA 9 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 9 (from prueba)"],
      respuesta10:
        datas["PREGUNTA 10 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 10 (from prueba)"],
      respuesta11:
        datas["PREGUNTA 11 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 11 (from prueba)"],
      respuesta12:
        datas["PREGUNTA 12 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 12 (from prueba)"],
      respuesta13:
        datas["PREGUNTA 13 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 13 (from prueba)"],
      respuesta14:
        datas["PREGUNTA 14 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 14 (from prueba)"],
      respuesta15:
        datas["PREGUNTA 15 (from prueba)"] === undefined
          ? ""
          : datas["PREGUNTA 15 (from prueba)"],
      respuesta1:
        datas["PREGUNTA I (from Prueba)"] === undefined
          ? ""
          : datas["PREGUNTA I (from Prueba)"],
      statusEntrevista: "ENTREVISTA ENVIADA",
    };
    let baseDataFirebase = await db
      .collection("interviewMrb")
      .doc(id)
      .set(data);
    return baseDataFirebase, updateData;
  } catch (error) {
    console.log("error");
  }
};

export const firebaseGet = async (id) => {
  try {
    let response = await db
      .collection("interviewMrb")
      .doc(id)
      .onSnapshot((snap) => {
        if (snap.exists) {
          store.dispatch(actions.completedGetFirebaseQuestions(snap.data()));
        } else {
          store.dispatch(actions.completedGetFirebaseQuestions(null));
        }
      });
    return {
      dataFirebase: response,
      error: null,
    };
  } catch (error) {
    console.log("error");
    return {
      dataFirebase: null,
      error,
    };
  }
};

export const updateDataFirebase = async (id, question) => {
  db.collection("interviewMrb").doc(id).update({ statusEntrevista: question });
};
export const updateDataAirtable = async (id, question) => {
  try {
    let updateData = await axios({
      url: "https://api.airtable.com/v0/app2tSIDsOpffig85/Respuestas.E_Respuestas",
      method: "patch",
      headers: {
        Authorization: "Bearer keycnxGOFUAfvAOcc",
      },
      data: {
        records: [
          {
            id: id,
            fields: {
              "Status Entrevista:": question,
            },
          },
        ],
      },
    });
    return updateData;
  } catch (error) {
    console.log(error);
  }
};

export const updatePollCandidate = async (id, response) => {
  let respuesta3 = response.values.pregunta1;
  let respuesta4 = response.values.pregunta2;
  let ragting1 = response.calification1;
  let ragting2 = response.calification2;
  let prueba = response.prueba;
  let asignada = response.asignada;

  let encuesta = {
    ragting1,
    ragting2,
    respuesta3,
    respuesta4,
    prueba,
    asignada
  };
  const modal = new candidate(id, encuesta);
  console.log(encuesta)

  try {
    let configPoll = await axios({
      url: "https://api.airtable.com/v0/app6HHzP3eNjSeFzo/CALIFICACIONES",
      method: "post",
      headers: {
        Authorization: "Bearer keycnxGOFUAfvAOcc",
      },
      data: {
        records: [modal.toJson()],
      },
    });
    return {
      data: configPoll.data.records[0].fields,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const GetPollQuestions = async (id) => {
  try {
    var config = {
      method: "get",
      url: `https://api.airtable.com/v0/app6HHzP3eNjSeFzo/CALIFICACIONES`,
      headers: {
        Authorization: "Bearer keycnxGOFUAfvAOcc",
      },
      params: {
        filterByFormula: `FIND("${id}", {AGENDA ids} & '') `,
      },
    };

    let response = await axios(config);
    return {
      data:
        response.data.records[0] !== null
          ? response.data.records[0].fields
          : null,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};

export const getPollCandidateQuestions = async (id) => {
  try {
    var config = {
      method: "get",
      url: "https://api.airtable.com/v0/app6HHzP3eNjSeFzo/Agenda",
      headers: {
        Authorization: "Bearer keycnxGOFUAfvAOcc",
      },
      params: {
        filterByFormula: `AND({ID GOLDEN}='${id}')`,
      },
    };

    let response = await axios(config);

    console.log('aqui esta ',response)
    return {
      data:response.data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
