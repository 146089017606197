export const modal ={
    question1: 'PREGUNTA I (from Prueba)',
    question2: 'PREGUNTA 2 (from prueba)',
    question3: 'PREGUNTA 3 (from prueba)', 
    question4: 'PREGUNTA 4 (from prueba)',
    question5: 'PREGUNTA 5 (from prueba)',
    question6: 'PREGUNTA 6 (from prueba)',
    question7: 'PREGUNTA 7 (from prueba)',
    question8: 'PREGUNTA 8 (from prueba)',
    question9: 'PREGUNTA 9 (from prueba)',
    question10: 'PREGUNTA 10 (from prueba)',
    question11: 'PREGUNTA 11 (from prueba)',
    question12: 'PREGUNTA 12 (from prueba)',
    question13: 'PREGUNTA 13 (from prueba)',
    question14: 'PREGUNTA 14 (from prueba)',
    question15: 'PREGUNTA 15 (from prueba)',
};
export const questionNames={
    question1: 'question1',
    question2: 'question2',
    question3: 'question3', 
    question4: 'question4',
    question5: 'question5',
    question6: 'question6',
    question7: 'question7',
    question8: 'question8',
    question9: 'question9',
    question10: 'question10',
    question11: 'question11',
    question12: 'question12',
    question13: 'question13',
    question14: 'question14',
    question15: 'question15',
}

export const jobAplication = {
    job1 : 'name',
    job2 : 'dateNacimiento',
    job3 : 'TipoDeDocumento',
    job4 : 'numberIdentification',
    job5 : 'telefono',
    job6 : 'plazaQueAplica',
    job7 : 'fotoPerfil',
    job8 : 'CV',
    job9 : 'correoElectronico',
    job10 : 'PrestasionSalarial',
    job11 : 'Departamento',
    job12 : 'TelefonoEmergencia',
    job13 : 'NombreTelefonoEmergencia',
    job14 : 'Direccion',
    job15 : 'zona',
    job16 : 'EstadoCivil',
    job17 : 'EstudiosUniversitarios'
}

export const jobAplicationNames={
    question1: 'question1',
    question2: 'question2',
    question3: 'question3', 
    question4: 'question4',
    question5: 'question5',
    question6: 'question6',
    question7: 'question7',
    question8: 'question8',
    question9: 'question9',
    question10: 'question10',
    question11: 'question11',
    question12: 'question12',
    question13: 'question13',
    question14: 'question14',
    question15: 'question15',
    question16: 'question16',
    question17: 'question17',

}
