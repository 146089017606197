import Cookies from 'universal-cookie'
import { isUndefined }  from 'util'


const cookies = new Cookies();

//metodo que carga la sesión
export function CargarSesion(datos,GuardarData,setCambiarEstado,cambiarEstado,tipos){
    let bandera = false
    Object.keys(tipos).forEach( (obj) => {        
        let tmp = tipos[obj]
        let URL = JSON.parse(localStorage.getItem(`url${tmp}`))  
        if(URL !== null){
            CambiarEstados(datos,GuardarData,setCambiarEstado,cambiarEstado,tmp) 
            bandera = true;
        }
    })    
    return bandera
}

//metodo que calcula que el token expire en 30 mins
export function calculaExpiracionSesion()
{
    const now = new Date().getTime();
    const newDate = now + 60 * 15 * 1000
    return new Date(newDate)
}

//metodo que valida si la sesion ya finalizo
export function getSession()
{
    return isUndefined(cookies.get("_s")) ? false : cookies.get("_s")
}

//metodo que redirecciona al login si el token paso sus 30 mins
export function renovarSesion()
{
    const sesion = getSession()
    let bandera = false
    if(!sesion)        
      { 
    //window.location.href = "/Papeleria"
        bandera = true
      }
    
      else
   {   
    cookies.set("_s",sesion,{
        path: "/", 
        expires: calculaExpiracionSesion() 
            
    })}

    return bandera;
}

function CambiarEstados(datos,GuardarData,setCambiarEstado,cambiarEstado,tipo)
{    
    let index= 0;
    let newData = undefined;

    for(let i = 0; i < datos.length;i++){    

        if(tipo === datos[i].tipo){ 
            index = i;
            newData = datos[index]   
            break;             
        }
    }  

    newData["status"] = "Subido"
    datos[index] = newData
    datos.sort((a,b) => (a.status > b.status) ? 1: -1)
    datos.reverse((a,b) => (a.status > b.status) ? 1: -1) 
    GuardarData([...datos])
    //datos[i] = {tipo: datos[i].tipo, status: "Subido", comment : datos[i].comment, url:datos[i].url, idarchivo: datos[i].idarchivo}                 

    setCambiarEstado( (tmp) => {
        tmp = cambiarEstado
        tmp.push({tipo: tipo, status: "Subido"})
        return tmp
    })          
}
