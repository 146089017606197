import React,{useState,useEffect} from 'react'
// Import the main component
import { Viewer } from '@react-pdf-viewer/core'; // install this library

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import axios from 'axios';

const Visualizador = ({urlfile}) => {
     // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  
  // for onchange event
  const [pdfFile, setPdfFile]=useState(null);
  const [pdfFileError, setPdfFileError]=useState('');

  // for submit event
  const [viewPdf, setViewPdf]=useState(null);
  const [archivo, setArchivo]=useState(null)

  // onchange event
  const fileType=['application/pdf'];

    const handlePdfFileChange=(e)=>{     
    const config = { responseType: 'blob' };
    axios.get(urlfile, config).then(response => {
    let selectedFile =new File([response.data], "prueba.pdf")                           
        let reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onloadend = (e) =>{
              setPdfFile(e.target.result);
              setPdfFileError('');          
      }} );
  }

  // form submit
  const handlePdfFileSubmit=()=>{  
    handlePdfFileChange()      
      setViewPdf(pdfFile);    
  }

  useEffect(() =>{
    handlePdfFileSubmit()
  })

    return (
        <div className='container'>                                  
      <div className='pdf-container'>
        {/* show pdf conditionally (if we have one)  */}
        {viewPdf&&<><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> 
         <Viewer fileUrl={viewPdf}
            plugins={[defaultLayoutPluginInstance]} />
      </Worker></>}

      {/* if we dont have pdf or viewPdf state is null */}
      {!viewPdf&&<>No pdf file selected</>}
      </div>

    </div>
    )
}

export default Visualizador