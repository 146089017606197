import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import * as selectors from '../../logic/reducers';
import * as actions from '../../logic/actions/questions.airtable';
import Navbar from '../resources/Navbar';
import { Fragment } from 'react';
import ResultCualification from '../view/qualification.submission';
import NoExist from '../view/questions.noexist'
import Result from './result';
import Progress from '../resources/progressbar';
import { Grid,makeStyles,Paper,Typography} from '@material-ui/core';
import './index.css'

const useStyles = makeStyles(theme => ({
    gridMargin:{
        minHeight: '100vh'
    },
    modelClass :{
        marginTop: '20vh'
    },
    responseQuestions:{
        marginTop: '41px'
    }
}));


const QualifiInterview = ({fetchAirtable,questions,loadingQuestion,completed,fetchCollection}) => {
    const {id} = useParams()
    const classes = useStyles()

    useEffect(()=>{
        fetchAirtable(id)
        fetchCollection(id)
    },[id])
    
    return(
        <Fragment>
            <div>
                {
                    loadingQuestion !=false ?
                        <Grid
                            container
                            direction = 'column'
                            alignContent = 'center'
                            alignItems = 'center'
                            justify='center'
                            className = { classes.gridMargin }
                        >
                            <Grid 
                                item
                                lg = { 8 }
                                md = { 8 }
                                xs = { 8 }
                            >
                                <Progress/>
                            </Grid>
                    </Grid> 

                :
                    questions != null && (
                        <div>
                            {
                            completed?.statusEntrevista != "APROBO" && completed?.statusEntrevista != "NO APROBO" ? (
                                <div>
                                    <Navbar/>
                                   
                                        <div className={classes.modelClass}>
                                            {
                                               
                                                questions?.data != undefined ? (
                                                    <div>
                                                        <Paper
                                                            elevation = {3}
                                                            className = 'paper2 paper3'
                                                            style = {{ paddingTop: '10px' }}
                                                        >   
                                                            <Result params={questions?.data.fields} idResult={id}/>
                                                        </Paper>
                                                    </div>
                                                ):(
                                                    <div>
                                                        <NoExist/>
                                                        <center style={{bottom: 0, paddingTop: '10px'}}>
                                                            <Typography variant="body2" component="p" style={{color: '#ABB2B9'}}>
                                                              Copyright © LoginSolutions 2022
                                                            </Typography>
                                                        </center>
                                                    </div> 
                                                )
                                            }
                                        </div>
                                </div>
                            ):(
                                <div>
                                    <Navbar/>
                                    <div className = {classes.responseQuestions}>
                                        <ResultCualification/>
                                    </div>
                                    <center style={{bottom: 0, paddingTop: '10px'}}>
                                        <Typography variant="body2" component="p" style={{color: '#ABB2B9'}}>
                                            Copyright © LoginSolutions 2022
                                        </Typography>
                                    </center>
                                </div>
                            )

                            }
                        </div>

                    )    

                }
            </div>
        </Fragment>
    )
}


export default connect(
    state=>({   
        loadingQuestion:selectors.getIsFetchingQuestion(state),
        questions: selectors.getIsData(state),
        error: selectors.getIsError(state),
        completed: selectors.getIsFetchingQuestionFirebaseCompleted(state)
    }),
    dispatch=>({
        fetchAirtable(id){
            dispatch(actions.startedQuestionsAirtable(id))
        },
        fetchCollection(id){
            dispatch(actions.startedGetFirebaseQuestions(id))
        },
    })
)(QualifiInterview)
