import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, Grid } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Paper, Snackbar } from "@material-ui/core";
import Draggable from "react-draggable";
import { makeStyles } from "@material-ui/core/styles";
import { Send } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { PatchPapeleria, SendMail } from "../../../helpers/candidato.data.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../Spinner/loader.css";
import { Button as Buttons } from "react-bootstrap";
import FinalizaProceso from "../ArchivoFinal/finalizaProceso";
import "./Estilos.css";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#2577DF",
    color: "white",
    fontFamily: "Roboto sans-serif",
    fontWeight: "800",
    marginRight: "auto",
    marginTop: "20px",
    width: "100%",
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props) {
  const {
    DataPapeleria,
    TiposPapeleria,
    ID_Usuario,
    Email,
    Commentarios,
    Estados,
    Porcentaje,
    Reclutador,
    INFORNET,
    PapeleriaTipos,
    INFORNATEDATA,
    DPI,
    FotoPerfil,
    Nombre,
    HUELLAS,
    MUESTRAS,
    Mensajes,
    datos,
    ID_Candidato
  } = props;
  const [open, setOpen] = React.useState(false);
  const [opensnack, setOpensnack] = React.useState(false);
  const classes = useStyles();
  const [banderaFile, setbanderaFile] = useState(null);
  const [banderaFileFalse, setbanderaFileFalse] = useState(null);
  const [Mensaje, setMensaje] = useState("");
  const [Loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function DelayMessage() {
    setTimeout(function () {
      window.location.href = "/RevisionPapeleria";
    }, 2000);
  }

  const handleCloseP = () => {
    setLoading(false);
  };

  const handleLoading = () => {
    setLoading(!Loading);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CuerpoEmail = (tmp) => {
    let Comentarios = "";
    if (Mensajes !== null) {
      if (Mensajes !== undefined) {
        if (Mensajes.length > 0) {
          let tmp = JSON.parse(Mensajes);
          Object.keys(tmp).forEach((obj) => {
            let tipo = tmp[obj].Tipo;
            let bandera = ValidarEstados(datos, tipo);
            if (bandera) {
              let tmp2 = MensajeRechazado(tmp[obj].Mensajes, Comentarios, tipo);
              if (tmp2.length > 0) {
                if (!tmp2.endsWith(".")) {
                  Comentarios += tmp2 + ".\n";
                } else {
                  Comentarios += tmp2 + "\n";
                }
              }
            }
          });
        }
      }
    }

    let Data = {
      reclutador: tmp.reclutador,
      to: tmp.to,
      candidato: tmp.candidato,
      plaza: tmp.plaza,
      Comentarios: Comentarios.substring(0, Comentarios.length - 1),
    };
    console.log(Data);
    return Data;
  };

  const MensajeRechazado = (myArray, cadena, tipo) => {
    if (myArray !== null) {
      if (myArray !== undefined) {
        if (myArray.length > 0) {
          let Tamaño = myArray.length - 1;
          cadena += tipo + ": " + myArray[Tamaño].Mensaje;
        }
      }
    }

    return cadena;
  };

  const ValidarEstados = (data, tipo) => {
    let bandera = false;
    Object.keys(data).forEach((obj) => {
      let tmp = data[obj];
      if (tmp.Status === "Rechazado" && tipo === tmp.Documento) {
        bandera = true;
      }
    });
    return bandera;
  };

  const handleSend = () => {
    setOpensnack(true);
    setOpen(false);
    handleLoading();

    SendMail.post(CuerpoEmail(Reclutador))
      .then((response) => {
        if (response.status === 200) {
          setbanderaFile(true);
          handleCloseP();
        } else {
          setbanderaFileFalse(true);
          handleCloseP();
          setMensaje("");
        }
      })
      .catch((err) => {
        console.error(err);
        setbanderaFileFalse(true);
        handleCloseP();
        setMensaje("");
      });
  };

  const handleClose1 = () => {
    setOpen(false);
    setbanderaFile(false);
  };

  const handleClosedd = () => {
    setOpen(false);
    setbanderaFileFalse(false);
  };

  return (
    <div>
      <style>
        {/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */}
      </style>

      {Loading ? (
        <div className="loading-backdrop">
          <CircularProgress color="primary" />
        </div>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Snackbar
          open={banderaFile}
          autoHideDuration={2000}
          onClose={handleClose1}
        >
          <Alert variant="filled" severity="success" onClose={handleClose1}>
            Correo electronico enviado correctamente.
          </Alert>
        </Snackbar>

        <Snackbar
          open={banderaFileFalse}
          autoHideDuration={2000}
          onClose={handleClosedd}
        >
          <Alert variant="filled" severity="error" onClose={handleClosedd}>
            Error al enviar el correo electronico, pruebe más tarde.
          </Alert>
        </Snackbar>

        <Grid xs={7} sm={6} md={2} style={{ marginRight: "auto" }}>
          {Porcentaje === 100 ? (
            <FinalizaProceso
              data={DataPapeleria}
              ID_Usuario={ID_Usuario}
              TiposPapeleria={TiposPapeleria}
              INFORNET={INFORNET}
              PapeleriaTipos={PapeleriaTipos}
              INFORNATEDATA={INFORNATEDATA}
              DPI={DPI}
              FotoPerfil={FotoPerfil}
              Nombre={Nombre}
              HUELLAS={HUELLAS}
              MUESTRAS={MUESTRAS}
              ID_Candidato={ID_Candidato}
            />
          ) : (
            <Buttons
              variant="contained"
              className={classes.button}
              onClick={handleClickOpen}
              style={{ fontFamily: "Roboto", fontWeigth: "800 !important" }}
            >
              Notificar Candidato
            </Buttons>
          )}
        </Grid>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move", fontFamily: "Roboto", fontWeigth: "600" }}
          id="draggable-dialog-title"
        >
          ¿Desea notificar al candidato?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontFamily: "Poppins", fontWeigth: "600" }}
          >
            Se notificará al candidato las revisiones que se han realizado.
            ¿Desea enviar correo electrónico?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            style={{ fontFamily: "Poppins", fontWeigth: "600" }}
          >
            Cancelar
          </Button>

          <Button
            onClick={handleSend}
            style={{ fontFamily: "Poppins", fontWeigth: "600" }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
