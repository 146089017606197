  export const ValidarArchivo = (Archivo) => {
    const filesArray = [].slice.call(Archivo);
    var PesoBytes = 0;    
    var TipoArchivo = ""

    filesArray.forEach(e => {       
      PesoBytes = e.size;                 
      TipoArchivo = e.type;           
    });
    
    //Retorna false si el archivo es menor o igual a 2 mgb
    const cond1 = ValidarPeso(PesoBytes)    
    //Valida el tipo de archivo
    const cond2 = ValidarTipo(TipoArchivo)    

    if(cond1.bandera)
    {
      let tmp = {bandera: true,mensaje:cond1.mensaje}
      return tmp; 
    }    
    else if(cond2.bandera)
    {
      let tmp = {bandera: true,mensaje:cond2.mensaje}
      return tmp;  
    }    
    else{
      let tmp = {bandera: false,mensaje:"ok"}
      return tmp; 
    }        
}

export const GetTipo = (Archivo) =>
    {
      const filesArray = [].slice.call(Archivo);
      var TipoArchivo = ""
      filesArray.forEach(e => {                             
        TipoArchivo = e.type;           
      });
      
      if(TipoArchivo.includes("image"))
      {     
        let bandera = true;   
        return bandera;
      }
      else{
        let bandera = false;   
        return bandera;
      }

    }

const ValidarTipo = (tipo) =>{
  if(tipo.includes("image"))
  {
    let tmp = {bandera: false,mensaje:"ok"}
    return tmp;
  }
  else if(tipo === "application/pdf"){
    let tmp = {bandera: false,mensaje:"ok"}
    return tmp;
  }
  else{
    let tmp = {bandera: true,mensaje:"Tipo de archivo invalido, pruebe nuevamente con un archivo PDF o Imagen."}
    return tmp;
  }
}


const ValidarPeso = (peso) =>{
  const PesoMegaBytes = peso * 0.000001; 
  if(PesoMegaBytes <= 2)
  { 
    let tmp = {bandera: false,mensaje:"ok"}   
    return tmp;
  }
  else
  {
    let tmp = {bandera: true,mensaje:"Archivo excede el peso maximo permitido, pruebe nuevamente con un archivo que pese menos de 2 megabytes."}
    return tmp;
  }

}


  
  