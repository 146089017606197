
import axios from 'axios'
import Axios from 'axios'

export const filePost = async(file)=>{
    console.log(file)
    try{
        let FileDatasSend = await Axios({
            url: 'https://mrb-services.herokuapp.com/fileImage',
            method: 'POST',
            data: file
        })
    return{
        file: FileDatasSend.data,
        error: null,
    }
    }catch(error){
        console.log('ERROR EN HELPERS',error.message)
        return{
            error,
            file: null, 
        }
    }
} 

export const idMarketStell = async () =>{
    var config = {
        method: 'get',
        url: 'https://api.airtable.com/v0/app2tSIDsOpffig85/PLAZA?filterByFormula=AND({EMPLEOS EN FERIA})',
        headers: { 
          'Authorization': 'Bearer keycnxGOFUAfvAOcc'
        }
      };
    return (await axios(config));
}


