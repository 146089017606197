import React, {useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Snackbar} from '@material-ui/core';
import {Alert,AlertTitle} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import {ValidarArchivo,GetTipo} from '../../GeneralCandidato/ValidarArchivos/Metodos'
import IconButton from '@material-ui/core/IconButton';
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import PublishSharpIcon from '@material-ui/icons/PublishSharp';
import {ArrayUrls} from '../../GeneralCandidato/Prompts/Metodos_Patch_Papeleria'
import {PatchPapeleria} from '../../../helpers/candidato.data.js'
import postPapeleriaCandidato from "../../../helpers/Papeleria"
import { jsPDF } from "jspdf";
import {RegistroRevision} from '../../Registro_Data/metodos'

const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(10),
      flexBasis: '33.33%',
      flexShrink: 0,
      fontWeight: "700",
      
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',    
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    Texto: {
      fontSize: theme.typography.pxToRem(11),  
      fontWeight: "700"
    },
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      }
}));

const Subir = (props) => {
    const {DataPapeleria, ID_Usuario,TiposPapeleria, SetDataPapeleria, Documento, tipo,
        SetDataSetUsuario,datos,setEstados,Estados,
        Registros,
        setRegistros,CalcularPorcentajeAprobado
    } = props 
    const [banderaFile, setbanderaFile] = useState(null)
    const [banderaFileFalse, setbanderaFileFalse] = useState(null)
    const [openP, setOpenP] = React.useState(false);
    const [open, setOpen] = React.useState(false);        
    const classes = useStyles();  

    const handleClose = () => {
        setOpen(false);
        setbanderaFile(false);
        };

        const handleClosed = () => {
            setOpen(false);
            setbanderaFileFalse(false);
            };

        const handleCloseP = () => {
        setOpenP(false);
        };
        
        const ValidarPesoImagen = (width,height) => {
            let bandera = false;
            const total = height - width;
       
            if( (total <= 1200) && (height >= width) && (width > 300 && height > 350)){
                bandera = true;
            }
            return bandera;
        }  

        const endcodeImage = (img,tipo,Documento) =>
        {              
            var file = new FileReader()              
            file.onloadend = function()
            {        
                var data = file.result            
                pdfconvert(data,tipo,Documento)                                                 
            }       
            file.readAsDataURL(img)                
        }
        const pdfconvert = (data,tipo,Documento) =>
        {                                                                              
                var doc = new jsPDF()            
                doc.setFontSize(40);        
                doc.addImage(data,15, 30, 180, 250);            
                let out = doc.output();
                let url = 'data:application/pdf;base64,' + btoa(out);                                
                let file = dataURLtoFile(url,'hello.txt');                                 
                let m = postPapeleriaCandidato(file,tipo,Documento)  
                m.then(val => Message(val))                                                          
        }
        
        const dataURLtoFile = (dataurl, filename) => {    
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }   
    //prueba
    const Message = (val) =>
    {
    handleCloseP()       
    if(val === "Si")
    {
        let data = JSON.parse(localStorage.getItem(`url${tipo}`)) 
        let Actualizar_Estados_ = Actualizar_Estados(Estados,tipo);
        setEstados(Actualizar_Estados_)   
        let urls = ArrayUrls(DataPapeleria,TiposPapeleria)         
        let t; 
        let Registro = RegistroRevision(Registros,tipo);
        setRegistros(Registro)        
        t = { "PAPELERIA": urls,
            "ESTADO_GOLDEN_PAPELERÍA": Actualizar_Estados_,
            "REGISTRO_REVISION_PAPELERIA" : Registro
        };
        let tmp2 = [{id:ID_Usuario,fields : t}]
        let tmp3 = {records : tmp2}
        let tmp4 = JSON.stringify(tmp3)  
        console.log(tmp4);
        PatchPapeleria.patch(tmp4).then( response => {        
            if(response.status === 200)
            {
                setbanderaFile(true)                     
                localStorage.removeItem(`url${tipo}`)  
                let Papeleria = (response.data.records[0].fields.PAPELERIA) 
                ActualizarURL(SetDataPapeleria,Papeleria)                   
                ActualizarEstado(datos,SetDataSetUsuario,tipo)
                CalcularPorcentajeAprobado(datos)                
            }
            else{
                setbanderaFileFalse(true)   
            }
            }).catch(err => 
            {                    
            console.error(err)
            setbanderaFileFalse(true)    
            })                             
    }
    else
    {            
        setbanderaFileFalse(true)                                                 
    }
    }

    const ActualizarURL = (SetDataPapeleria,DataPapeleria) => { 
       SetDataPapeleria(DataPapeleria)
    }

    const ActualizarEstado = (datos,SetDataSetUsuario,tipo) =>{                
        if(tipo !== null){                        
            let index= 0;
            let newData = undefined;
            
        for(let i = 0; i < datos.length;i++){          
            if(tipo === datos[i].Documento){ 
                index = i;               
                break;             
            }
        }  
        
        newData = datos[index]   
        newData["Status"] = "Aceptado"
        datos[index] = newData         
        SetDataSetUsuario([...datos])
        }
    }
    
     const onChanges = async(e) => {                 
        setOpenP(!open)
        const f = e.target.files[0]   
        const f2 = e.target.files   
        let tipo = JSON.parse(localStorage.getItem('tipo'))   
        var banderaArchivo = ValidarArchivo(f2)
   
        if(!banderaArchivo.bandera)
        {        
        var banderatmp = GetTipo(f2)   
        //si es verdadero es imagen y convertira la imagen a pdf, para que en el visualizador de pdf se pueda ver estos archivos.  
        if(banderatmp)
        {
        //endcodeImage(f,tipo.tipo,Documento)             
        var reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = function (e) {
            //Initiate the JavaScript Image object.
            var image = new Image();
            image.src = e.target.result;
                        
            //Validate the File Height and Width.
            image.onload = function () {
                var height = this.height;
                var width = this.width;
            if(ValidarPesoImagen(width,height)){
                endcodeImage(f,tipo.tipo,Documento)  
            }
            else
            {
                alert("Se intento subir una imagen con un tamaño no valido.")
                handleCloseP() 
            }
            
            };}
            
        }
        //si es falso es pdf, se sube con el proceso normal.
        else{                       
        var m = postPapeleriaCandidato(f,tipo.tipo,Documento)         
        m.then(val => Message(val))   
        }            
        }
        else
        {      
        alert(banderaArchivo.mensaje)
        handleCloseP() 
        }      
    }  

    const Actualizar_Estados = (Estado,tipo) => {
        let nuevo = ""
        var nuevo_array = []

        let cambiarEstado = []
        cambiarEstado.push({tipo: tipo, status: "Aceptado"})

        if(Estado !== undefined)
        {
        let s = Estado.split("],");
        Object.keys(s).forEach( (obj) => 
        {
            let tmp = s[obj].replace("[","")
            let tmp2 =tmp.split(":")
            if(tmp2[1] !== undefined)
            {
            nuevo_array.push({tipo: tmp2[0], status: tmp2[1]}) 
          }
        })
        
        Object.keys(nuevo_array).forEach( (obj) => {
     
            Object.keys(cambiarEstado).forEach( (obj2) => {
         
                let tmp3 = "[" + cambiarEstado[obj2].tipo + ":" + cambiarEstado[obj2].status +"],"
                if(!nuevo.includes(tmp3))
                {
                 if(nuevo_array[obj].tipo === cambiarEstado[obj2].tipo){
                     nuevo = nuevo + tmp3            
                 }
                 else{
                      nuevo = nuevo + tmp3            
                 }
                    
                }
            })                                         
        })
  
        Object.keys(nuevo_array).forEach( (obj) => {          
          if(!nuevo.includes(nuevo_array[obj].tipo)){
              nuevo = nuevo +  "[" + nuevo_array[obj].tipo + ":" + nuevo_array[obj].status +"],"
          }
      })
          
      }
      else{
        Object.keys(cambiarEstado).forEach( (obj2) => {   
          let tmp3 = "[" + cambiarEstado[obj2].tipo + ":" + cambiarEstado[obj2].status +"],"
          nuevo = nuevo + tmp3
      })
      }    
        return nuevo;   
    }


          
    return (
        <div>
            <Backdrop className={classes.backdrop} open={openP}>
            <CircularProgress color="inherit" />
            </Backdrop>  
                    
            <Snackbar open={banderaFile} autoHideDuration={2000} onClose={handleClose}>
            <Alert variant="filled" severity="success" onClose={handleClose} >
            Archivo subido correctamente.
            </Alert>
            </Snackbar>          
            
            <Snackbar open={banderaFileFalse} autoHideDuration={2000} onClose={handleClosed}>
            <Alert variant="filled" severity="error" onClose={handleClosed} >
            Error al subir archivo, pruebe de nuevo por favor.
            </Alert>
            </Snackbar>
 
            <input className={classes.input} id={`icon-button-file${tipo}`} type="file"
                onChange={onChanges} />
                <label htmlFor={`icon-button-file${tipo}`} style={{cursor:"pointer"}}>
                <IconButton aria-label="upload file" component="span">                
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Subir Archivo</Tooltip>}>                        
                    <PublishSharpIcon 
                    style={{color:"black"}}
                    />
                </OverlayTrigger>  
                </IconButton>                
                </label>       
        </div>
    )
}

export default Subir;