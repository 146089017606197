import React from 'react'
import {
    Grid,
    Typography
} from '@material-ui/core'
import {CheckCircle} from '@material-ui/icons'


export default function CompletedQuestionary() {
    return (
        <div>
            <Grid
                container
                direction="column"
                alignItems="center"
                alignContent = "center"
                justifyContent="center"
                style ={{
                    minHeight: '70vh'
                }}
            >
                <Grid item xs={10} md={10} lg={8}>
                    <div style = {{textAlign: 'center', position: 'relative'}}>
                        <CheckCircle 
                            style={{
                                color: 'green',
                                width: '15%',
                                height: 'auto',
                                paddingBottom: '10px'
                            }}
                        />
                    </div>
                    <Typography 
                        variant='subtitle1' 
                        style={{textAlign: 'center', fontSize: '20px', fontWeight: 700, fontFamily: 'Oswald, sans-serif' }}
                    >
                       HAS COMPLETADO LA ENCUESTA, AGRADECEMOS TU CALIFICACIÓN, TEN UN EXCELENTE DÍA.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
