import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import './estilos.css'
import {ValidarToken} from './Plazas'
import {Alert,AlertTitle} from '@material-ui/lab';
import {Snackbar} from '@material-ui/core';
import {renovarSesion} from '../Sesion/index'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ShowZeroBadge(props) {
  const classes = useStyles();
  const {mensaje,tipo,Mensajes,status} = props

  const [open, setOpen] = React.useState(false);  
  const [OpenAlert, setOpenAlert] = React.useState(false);         
  const [imprimir, setImprimir] = React.useState([]); 
  const [Validar, setValidar] = React.useState(0);

  const handleOpen = async() => {    
    let cond = await ValidarToken()  
    let bandera = renovarSesion()
    if(!cond.bandera && !bandera)
    {

    setOpen(true);           
  }
  else{
    setOpenAlert(true)
    localStorage.clear()
    window.location.href = "/Papeleria"
  }
  };

  const handleClose = () => {
    setOpen(false);
  };
 

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }    
    setOpenAlert(false);
    localStorage.clear()
    window.location.href = "/Papeleria"
  };

  const renderMensaje = () => {
    return(
      <div>  
          {imprimir.map(item => {
            return <li>{item}</li>
          })}        
      </div>
    )
  }

  const CargarMensajes = () =>{
    let ArrayTmp = []
            
    let Entrada = null;

    if(Mensajes !== undefined)
    {
        Entrada = JSON.parse(Mensajes)
    }

    if(Entrada !== null)
    {
        Object.keys(Entrada).forEach( obj => {
            let tmp = Entrada[obj]
            if(tmp.Tipo === tipo)
            {
                Object.keys(tmp.Mensajes).forEach( obj2 => {
                    let tmp2 = tmp.Mensajes[obj2]
                    ArrayTmp.push("Fecha: " + tmp2.Registro + " - Mensaje: " + tmp2.Mensaje)
                })
                             
            }
            
        })

        
    if(ArrayTmp.length === 0)
    {
      ArrayTmp.push("Sin mensajes.")
    }
   
    if(status === "Rechazado" && ArrayTmp.length > 0)
    {
      setValidar(1)
    }   

    }
    
    else
    {
       //no hay mensajes.
       ArrayTmp.push("Sin mensajes.")
       setValidar(0)
    }

    setImprimir(ArrayTmp) 
  }
  useEffect( () => {
    CargarMensajes()
  },[Validar])

  return (
    <div className={classes.root}>                        
        {
        Validar > 0 ?
        <div>
          <label style={{cursor:"pointer"}} onClick={() => handleOpen()} className="AlinearMensaje">
              <IconButton  aria-label="upload file" component="span" className="BotonMensaje">
              <Badge color="secondary" badgeContent={1} showZero className="TipoMensaje">
              <FontAwesomeIcon className="IconoMensaje" icon={faCommentDots}/>
              </Badge>
              <span className="Titulo_Alerta">Alertas</span>
              </IconButton>
         </label>
        
        </div>
         :
         <label onClick={handleOpen} style={{cursor:"pointer"} }className="Alinear" > 
              <IconButton  aria-label="upload file" component="span">
              <FontAwesomeIcon className="IconoMensaje" icon={faCommentDots}/>
              </IconButton>
             <span className="Titulo">Alertas</span>
         </label> 
      }   
     
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h5 id="transition-modal-title">{tipo} </h5>
            {/* <h6 id="transition-modal-title">Comentarios:</h6> */}
            {renderMensaje()}
          </div>
        </Fade>
      </Modal>

           
      <Snackbar open={OpenAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
            <Alert severity="error">
            <AlertTitle>Tu sesión ha expirado</AlertTitle>
            <strong>Por favor, vuelve a iniciar sesión.</strong>
            </Alert> 
            </Snackbar> 

    </div>
  );
}
