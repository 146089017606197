import axios from 'axios'
import { componentsToColor } from 'pdf-lib'
import {firebaseStorage} from '../config/config'


//firebase
const postPapeleriaCandidato = async(file,tipo,documento) => {
      var storageRef = firebaseStorage.ref()
      await storageRef.child(`Papeleria_MRB/${tipo}|${documento}.pdf`).put(file)
      //var base64file = await toBase64(file)
      var startRefCV = await storageRef.child(`Papeleria_MRB/${tipo}|${documento}.pdf`)
      var getUrl = await startRefCV.getDownloadURL().then(async(url)=>{
       return url
      }).catch((error)=>{
            console.log(error)
      return "No"
      })      
      localStorage.setItem(`url${tipo}`,JSON.stringify({
            url : getUrl            
        }))
              
      if(getUrl !== undefined)
      {
            return "Si"
      }
      else
      {
            return "No"
      }
}
      
export default   postPapeleriaCandidato    

