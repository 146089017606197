import React from 'react'
import './Estilos.css'
import {Grid} from '@material-ui/core'

const NavBar = () => {
    return (
        <div>
                <hr/>
            <img className="LogoTipo"src="https://firebasestorage.googleapis.com/v0/b/empleosmrb.appspot.com/o/logotipos%2Fpapersboxgt-logo-1.PNG?alt=media&token=dfafd01c-df27-4dbd-bed5-7a273b590f0b"/>                                   

        </div>
    )
}

export default NavBar

