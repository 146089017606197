import * as types from '../types/questions.airtable'

export const startedQuestionsAirtable = (id) =>({
    type: types.FETCHING_DATA_AIRTABLE_STARTED,
    payload:{
        id
    }
})

export const completedQuestionsAirtable = data =>({
    type: types.FETCHING_DATA_AIRTABLE_COMPLETED,
    payload:{
        data
    }
})

export const failedQuestionsAirtable = error =>({
    type: types.FETCHING_DATA_AIRTABLE_FAILED,
    payload:{
        error
    }
})



// SEND DATA 

export const startedCreateData = (request,id) => ({
    type: types.CREATE_DATA_AIRTABLE_STARTED,
    payload: {
        request,
        id
    }
})

export const completedCreateData = (request,id) => ({
    type: types.CREATE_DATA_AIRTABLE_COMPLETED,
    payload: {
        request,id
    }
})

export const errorCreateData = error => ({
    type: types.CREATE_DATA_AIRTABLE_FAILED,
    payload: {
        error
    }
})

export const startedGetFirebaseQuestions = idFirebase => ({
    type: types.FETCHING_DATA_FIREBASE_STARTED,
    payload: {
        idFirebase
    }
})


export const completedGetFirebaseQuestions = dataFirebase => ({
    type: types.FETCHING_DATA_FIREBASE_COMPLETED,
    payload: {
        dataFirebase
    }
})

export const errorGetFirebaseQuestions = errorFirebase => ({
    type: types.FETCHING_DATA_FIREBASE_FAILED,
    payload: {
        errorFirebase
    }
})

export const startedUpdateFirebaseQuestions = (id,questions)=>({
    type: types.UPDATA_DATA_FIREBASE_STARTED,
    payload:{
        id,
        questions
    }
});

export const completedUpdateFirebaseQuestions = questions =>({
    type: types.UPDATA_DATA_FIREBASE_COMPLETED,
    payload:{
        questions
    }
});

export const errorUpdateFirebaseQuestions = error => ({
    type: types.UPDATA_DATA_FIREBASE_FAILED,
    payload:{
        error
    }
});

export const startedUpdateAirtableQuestions = (id,resul) =>({
    type: types.UPDATE_AIRTABLE_QUESTION_STARTED,
    payload:{
        id,
        resul
    }
})

export const completedUpdateAirtableQuestions = resul => ({
    type: types.UPDATE_AIRTABLE_QUESTION_COMPLETED,
    payload:{
        resul
    }
})

export const errorUpdateAirtableQuestions = error => ({
    type: types.UPDATE_AIRTABLE_QUESTION_FAILED, 
    payload:{
        error
    }
})

export const startedUpdatedPoll = (id,request) => ({
    type: types.UPDATE_AIRTABLE_POLL_STARTED,
    payload:{
        id,
        request
    }
});

export const completedUpdatePoll = (response) => ({
    type: types.UPDATE_AIRTABLE_POLL_COMPLETED,
    payload:{
        response
    }
});

export const failedUpdatePoll = (error) => ({
    type: types.UPDATE_AIRTABLE_POLL_FAILED,
    payload:{
        error
    }
});

export const startedGetPoll = (id) => ({
    type: types.GET_AIRTABLE_POLL_STARTED,
    payload:{
        id
    }
});

export const completedGetPoll = (res) => ({
    type: types.GET_AIRTABLE_POLL_COMPLETED,
    payload:{
        res
    }
});

export const failedGetPoll = (error) => ({
    type: types.GET_AIRTABLE_POLL_FAILED,
    payload:{
        error
    }
});

export const startedFetchingCandidatePoll = (id) => ({
  type: types.FETCH_CANDIDATE_POLL_STARTED,
  payload: {
    id,
  },
});

export const completedFetchingCandidatePoll = (request) => ({
  type: types.FETCH_CANDIDATE_POLL_COMPLETED,
  payload: {
    request,
  },
});

export const failedFetchingCandidateCandidatePoll = (error) => ({
  type: types.FETCH_CANDIDATE_POLL_FAILED,
  payload: {
    error,
  },
});