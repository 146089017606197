import firebase from 'firebase';
import 'firebase/storage';
//..
var firebaseConfig = {
  apiKey: "AIzaSyDOtKh1OZbuh7s0-sRBCpobG5F4zBHkBhc",
  authDomain: "empleosmrb.firebaseapp.com",
  projectId: "empleosmrb",
  storageBucket: "empleosmrb.appspot.com",
  messagingSenderId: "281301297124",
  appId: "1:281301297124:web:76bef435643f3b886c49f1",
  measurementId: "G-8TZ4SEHGQ8"
};
const firebaseFirestore = firebase.initializeApp(firebaseConfig);
const firebaseStorage = firebase.storage();
const firebaseFirestoreApp = firebase.firestore();
const firebaseAuth = firebase.auth();

export {
    firebaseFirestore,
    firebaseStorage, 
    firebaseFirestoreApp,
    firebaseAuth,
};

